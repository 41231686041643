/**
 * Created by bbang on 2017-04-11.
 */
import {Injectable, ErrorHandler} from "@angular/core";
import {AlertController, Platform} from '@ionic/angular';
import * as stacktrace from 'stacktrace-js';
// import {GoogleAnalyticsUtil} from "./GoogleAnalytics";

@Injectable()
export class CustomErrorHandlerWithFabric extends ErrorHandler {
  constructor(public alertCtrl: AlertController, public platform: Platform){
    super();
  }

  handleError(error){
    // if(!(<any>window).fabric){
    //   console.log('can not use fabric');
    //   stacktrace.get().then(
    //     trace => {
    //       console.log("CustomErrorHandlerWithFabric");
    //       console.log(error.message);
    //       console.log(trace.toString());
    //       //this.gaUtil.gaTrackExceptionError(error.message+' / '+trace);
    //     }
    //   );
    // }else{
    //   console.log('can use fabric');
    //   //(<any>window).fabric.Crashlytics.addLog('ionic error handler');
    //   stacktrace.get().then(
    //     trace => {
    //       console.log(error.message);
    //       console.log(trace.toString());
    //
    //       //this.gaUtil.gaTrackExceptionError(error.message+' / '+trace);
    //
    //       //(<any>window).fabric.Crashlytics.sendNonFatalCrash(error.message, trace);
    //     }
    //   );
    // }

    this.alertCtrlPresent();
    console.log("handleError : "+error);
    super.handleError(error);
  }

  async alertCtrlPresent() {
    const alert = await this.alertCtrl.create({
      header: '알 림'
      , message: '오류가 발생하였습니다. 앱 종료 후 다시 앱을 실행시켜주시기 바랍니다.'
      , buttons: [
        {
          text: '확인'
          , handler:() => {
          //this.platform.exitApp();
          }
        }
      ]
      , backdropDismiss: false
    });
  
    await alert.present();
  }
}
