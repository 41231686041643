import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'trim'})
export class TrimPipe implements PipeTransform {
  transform(inValue: any): string {
    let value = '';
    try {
      value = inValue.toString();
      return value.replace(/\s/g,'');
    } catch (err) {
      console.log(err);
      return value;
    }
  }
}
