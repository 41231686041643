/**
 * Created by bbang on 2017-04-18.
 */

import {Pipe, PipeTransform} from "@angular/core";
import {ApiConfig} from "../services/config";
@Pipe(
  {
    name: 'httpDomain'
  }
)
export class HttpDomainPipe implements PipeTransform{
  apiConfig:ApiConfig = new ApiConfig();

  transform(input: string): string {
    const fullImageUrlPath: string = this.apiConfig.apiObject.pcWeb + input;

    return fullImageUrlPath;
  }
}
