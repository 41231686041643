import { Device } from '@ionic-native/device/ngx';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';

import { ApiConfig } from './config';
import {DatePipe} from '@angular/common';
import {LoadingController, Platform, IonApp, IonicModule, AlertController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {timeout, map} from 'rxjs/operators';
import {CommonMessageModel} from './model/CommonMessageModel';
import {UserService} from './user-service';
import {AuthService} from './auth-service';
// import {GoogleAnalyticsUtil} from '../utils/GoogleAnalytics';
import {EndPopupInfo} from './model/EndPopupInfo';
import {Function} from '../services/function';


/*
  Generated class for the CommonService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class CommonService {
  apiConfig: ApiConfig = new ApiConfig();
  isCheckApi = false;
  isCheckUuid = false;
  isCheckOpenApp = true;
  isCheckPlatform = '';
  commonData = {
    holidayList : []
    , areaCodeList : []
    , areaCodeListTour : []
    , appVersion : ''
    , appUrl : ''
    , appUpdateYn : ''
    , notiYn: 1
  };

  eventHistoryParam = {
    eventMainType: ''
    , eventDetailType: ''
    , osType: ''
    , osVersion: ''
    , mobileId: ''
    , deviceLatitude: ''
    , deviceLongitude: ''
    , deviceAccuracy: ''
    , boardUniqId: ''
    , userId: ''
    , deviceModel: ''
  };

  // 지역 및 골프장 리스트 가져오기 param
  golfListParam = {
    searchState: ''
    , searchUserId: ''
  };

  appUseClickHistoryParam = {
    platform : ''
  };

  commonCodeList: any;

  option = { maximumAge: 3000, timeout: 5000, enableHighAccuracy: true };

  constructor(private device: Device, public http: Http, public datepipe: DatePipe, public loadingCtrl: LoadingController
            , public userService: UserService, public authService: AuthService
            , public platform: Platform, public alertCtrl: AlertController, public functionService: Function) {

    this.init();
  }

  init() {
    this.platform.ready().then(() => {
      this.isCheckPlatform = this.device.platform;
    });
  }

  handleError(error) {
    console.error(error);
    return Observable.throw(error.json().error || 'Server error');
  }

  /**
   * HTTP NETWORK
   * @returns {Observable<R>}
   */

  getHolidayList() {
    const body = '';
    /*body.append('email', this.username);
    body.append('password', this.password);*/

    const url = this.apiConfig.apiObject.common + '/list.holiday';
    const response = this.http.post(url, body, this.apiConfig.options).pipe(map((res: Response) => res.json()));

    return response;
  }

  getCurrentAppVersion(deviceType) {
    const params = {
      appDeviceType: deviceType
    };

    const url = this.apiConfig.apiObject.device + '/version';
    const response = this.http.post(url, params, this.apiConfig.options).pipe(map((res: Response) => res.json()));

    return response;
  }

  /**
   * 마감처리요청하기
   */
  sendClickEventHitory(): Observable<CommonMessageModel> {
    const param_data = this.apiConfig.makeParameter(this.eventHistoryParam);
    const url = this.apiConfig.apiObject.common+'/createEventHistroy';

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json() as CommonMessageModel));
    //.catch(this.handleError);

    this.eventHistoryParam = {
      eventMainType: ''
      , eventDetailType: ''
      , osType: ''
      , osVersion: ''
      , mobileId: ''
      , deviceLatitude: ''
      , deviceLongitude: ''
      , deviceAccuracy: ''
      , boardUniqId: ''
      , userId: ''
      , deviceModel: ''
    };

    return response;
  }

  /**
   * 골프장 리스트 가져오기 (조인플라자)
   */
  getGolfList() {
    const url = this.apiConfig.apiObject.common+'/getGolfList';

    const response = this.http.post(url, this.golfListParam, this.apiConfig.optionsJson).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
      //.catch(this.handleError);

    return response;
  }

  getGolfListCommon() {
    const url = this.apiConfig.apiObject.common+'/getGolfListCommon';

    const response = this.http.post(url, this.golfListParam, this.apiConfig.optionsJson).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    //.catch(this.handleError);

    return response;
  }

  /**
   * Function
   */
  makeDateArrayForSearch(type) {
    const result = {
      weekTimeListLoad: []
      , dateArray: []
    };
    //let resultArray:Array<any> = new Array<any>();
    //let week = new Array('SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT');
    const week = new Array('일', '월', '화', '수', '목', '금', '토');
    const curDate = new Date();
    //console.log(curDate);

    for(let i = 0 ; i < 10 ; i++) {
      let sd = i*7;
      //console.log(sd);
      const tmpArray:Array<any> = new Array<any>();

      if(type === 'booking') {
        for(let j = sd ; j < (sd+7) ; j++) {
          const tmpDate = new Date(Date.parse(curDate.toDateString()) + j*24*60*60*1000);
          let holiday = false;

          if(tmpDate.getDay() === 0 || tmpDate.getDay() === 6) {
            holiday = true;
          }

          tmpArray.push({
            oriDate: tmpDate
            , formattedDate: this.datepipe.transform(tmpDate, 'yyyy-MM-dd')
            , holiday: holiday
            , weekName: week[tmpDate.getDay()]
            , displayDate: this.datepipe.transform(tmpDate, 'yyyy-MM-dd')
          });
        }
      } else {
        for(let j = sd ; j < (sd+7) ; j++) {
          const tmpDate = new Date(Date.parse(curDate.toDateString()) + j*24*60*60*1000);

          let holiday = false;

          if(tmpDate.getDay() === 0 || tmpDate.getDay() === 6) {
            holiday = true;
          }

          tmpArray.push({
            oriDate: tmpDate
            , formattedDate: this.datepipe.transform(tmpDate, 'yyyy-MM-dd')
            , holiday: holiday
            , weekName: week[tmpDate.getDay()]
            , displayDate: this.datepipe.transform(tmpDate, 'yyyy-MM-dd')
          });
        }
      }

      //resultArray.push(tmpArray);
      result.dateArray.push(tmpArray);
      result.weekTimeListLoad.push({
        idx: 'week_'+i
        , condition: false
      });
    }
    return result;
  }

  storeEventHistory(mType, dType, seq) {
    /**
     * CRJH (문의하기 category) => SMS(문자), CALL(전화)
     * CSH (공유하기 category) => JDS(조인플라자 상세페이지 공유)
     * UAS (회원 category) => UJS(회원가입)
     */

    //param set
    this.eventHistoryParam.eventMainType = mType;
    this.eventHistoryParam.eventDetailType = dType;

    //device info
    this.eventHistoryParam.osType = this.userService.loginNappDataParam.deviceOsType;
    this.eventHistoryParam.osVersion = this.userService.loginNappDataParam.deviceVersion;
    this.eventHistoryParam.mobileId = this.userService.loginNappDataParam.uuid;
    this.eventHistoryParam.deviceModel = this.userService.loginNappDataParam.deviceModel;

    this.eventHistoryParam.boardUniqId = seq;
    this.eventHistoryParam.userId = this.authService.userData.user_id;

    setTimeout(() => {
      //console.log(this.eventHistoryParam);
      //console.log(this.eventHistoryParam.deviceLongitude);
      //console.log(this.eventHistoryParam.deviceLatitude);
      this.sendClickEventHitory().subscribe(
        data => {
          //console.log(data);
        }
        , error => {
          //console.log(error);
          const errorMsg = 'category:nativeStorage/id:'+ this.userService.loginNappDataParam.loginId+'/appVersion:'+this.userService.loginNappDataParam.appVersion
            +'/deviceModel:'+this.userService.loginNappDataParam.deviceModel+'/deviceOsType:'+this.userService.loginNappDataParam.deviceOsType+'/deviceVersion'+this.userService.loginNappDataParam.deviceVersion
            +'/errorType:cordovaException'+'/errorMsg:loginUserPw, '+error.toString();
          // this.gaUtil.gaTrackExceptionError(errorMsg);
        }
      );
    }, 500);
  }

  // 2019.01.21 공사중페이지 리스트
  getMobileActiveList() {
    const url = this.apiConfig.apiObject.login+'/getMobileActiveList';
    let jsonData ;
    this.http.get(encodeURI(url)).subscribe(
      data => {
      if(data.status !== 200) {
        // 서버에러
        console.log('공사중 페이지 서버 에러. code:' + data.status);
      } else {
        jsonData = data.text();
        jsonData = JSON.parse(jsonData);
        console.log(JSON.stringify(jsonData));
        if (jsonData.length > 0) {
          this.isCheckApi = true;
          console.log('공사중 페이지 활성화시간');
          const shownoti = this.functionService.showInfoPopup('공사중 ',
          '<p class="tourContact"> 현재 공사중입니다.' +
          '<br/><br/>고객센터<br/><span class="orgtxt"><b>1588-4656</b> (내선 3번)</span><br/><br/><br/></p>' +
          '<img src="./assets/img/logo.png" width=120 />')
          .then((data)=>{
            navigator['app'].exitApp();
          });
        } else {
          console.log('공사중 페이지 활성화시간아님');
          this.isCheckApi = false;
        }
      }
    });
  }

  getMobileActiveUuidPopupList() {
    const url = this.apiConfig.apiObject.login+'/getMobileActiveUuidPopupList';
    let jsonData ;
    this.http.get(encodeURI(url)).subscribe(
      data => {
      console.log('UUID 리스트 체크');
      if(data.status !== 200) {
        // 서버에러
        console.log('UUID 리스트 서버 에러. code:' + data.status);
        this.isCheckUuid = false;
      } else {
        jsonData = data.text();
        jsonData = JSON.parse(jsonData);
        // console.log("UUID 리스트 : " + JSON.stringify(jsonData));
        if (jsonData.length > 0) {
          console.log('jsonData.length > 0');
          console.log('getMobileActiveUuidPopupList() > this.device.uuid : ' + this.device.uuid);
          for (let i=0; i<jsonData.length; i++) {
            // console.log("i : " + i);
            if (jsonData[i].its_uuid === this.device.uuid) {
              console.log('jsonData[i].its_uuid : ' + jsonData[i].its_uuid);
              console.log('this.device.uuid :' + this.device.uuid);
              this.isCheckUuid = true;
              break;
            }
          }
        }
        console.log("getMobileActiveUuidPopupList() > this.isCheckUuid : " + this.isCheckUuid);
      }
    });
  }

  openApp() {
    const _this = this;
    console.log("openApp()");
    setTimeout(function() {
      if (this.device.platform === "browser") {
        const userAgent = navigator.userAgent;
        console.log("userAgent : " + userAgent);
        if (userAgent.match(/iPhone|iPad|iPod/)) {
          console.log("iOS");
          _this.postAppUseClickHistory('iOS').subscribe(
            data => {
            }, err => {
            }
          );

          setTimeout(
              function() {
                location.href = "https://itunes.apple.com/kr/app/icheugolpeu-hal-ingolpeubuking/id1137814166?mt=8";
              }, 1000);

          setTimeout(
            function() {
                location.href = "itsgolf://";
            }, 500);

        }

        if (userAgent.match(/Android/)) {
          _this.postAppUseClickHistory('Android').subscribe(
            data => {
            }, err => {
            }
          );
          if (userAgent.match(/Chrome/)) {
            console.log("안드로이드 > 크롬");
            const index = navigator.userAgent.indexOf('Chrome');
            const chromeVersion = navigator.userAgent.substring(index + 7, index + 9);
            console.log('chromeVersion : ' + chromeVersion);
            if (Number(chromeVersion) > 40) {
              console.log('크롬 40+');
              // 안드로이드의 크롬 40+에서는 intent만 동작하기 때문에 intent로 호출해야함
              setTimeout(
                  function() {
                    location.href = 'https://play.google.com/store/apps/details?id=com.kgrid.itsgolf.app';
                  }, 500);
              setTimeout(function() {
                    location.href = 'intent://itsgolf/#Intent;scheme=itsgolf;package=com.kgrid.itsgolf.app;end';
                }, 100);
            } else {
              console.log('크롬 40-');
              if (userAgent.match(/NAVER/)) {
                console.log('네이버 인앱 브라우져');
                // 안드로이드의 크롬 40+에서는 intent만 동작하기 때문에 intent로 호출해야함
                setTimeout(
                    function() {
                      location.href = 'https://play.google.com/store/apps/details?id=com.kgrid.itsgolf.app';
                    }, 500);
                setTimeout(function() {
                      location.href = 'itsgolf://';
                  }, 100);
              } else {
                const url = 'itsgolf://';
                this.appFrame = document.getElementById('appframe');
                this.appFrame.src = url;

                setTimeout(function() {
                  try {
                    this.appFrame = document.getElementById('appframe');
                    this.appFrame.contentWindow.document.getElementById('check');
                  } catch (e) {
                    location.href = 'https://play.google.com/store/apps/details?id=com.kgrid.itsgolf.app';
                  }
                }, 500);
              }
            }
          } else {
            console.log('안드로이드 > ELSE');
            const url = 'itsgolf://';
            this.appFrame = document.getElementById('appframe');
            this.appFrame.src = url;

            setTimeout(function() {
              try {
                this.appFrame = document.getElementById('appframe');
                this.appFrame.contentWindow.document.getElementById('check');
              } catch (e) {
                location.href = 'https://play.google.com/store/apps/details?id=com.kgrid.itsgolf.app';
              }
            }, 500);
          }
        }
      }
    }, 500);
  }

  closeAppOpenBtn() {
    this.isCheckOpenApp = false;
  }

  getCommonCode() {
    const url = this.apiConfig.apiObject.common + '/getCommonCode';
    let jsonData ;
    this.http.get(encodeURI(url)).subscribe(
      data => {
        jsonData = data.text();
        jsonData = JSON.parse(jsonData);
        this.commonCodeList = jsonData;
        console.log(this.commonCodeList);
      if (data.status !== 200) {

      } else {

      }
    });
  }

  postAppUseClickHistory(platform) {
    this.appUseClickHistoryParam.platform = platform;
    const url = this.apiConfig.apiObject.common + '/postAppUseClickHistory';
    const response = this.http.post(url, this.appUseClickHistoryParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    return response;
  }

}
