import { Device } from '@ionic-native/device/ngx';
import { Component, Optional, ChangeDetectorRef, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { Platform, MenuController, ModalController, NavController, AlertController, LoadingController, IonRouterOutlet, IonContent
} from '@ionic/angular';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Http } from '@angular/http';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

// itsgolf page (menu)
import {HomePage} from './home/home';
import {Mypage} from './mypage/mypage';
import {TourPage} from './tour/tour';

// service
import { CommonService } from './services/common-service';
import { UserService } from './services/user-service';
import { AuthService} from './services/auth-service';
import { Function} from './services/function';
import { Fabric} from './utils/fabric';
import { AuthModel} from './services/model/user/AuthModel';
import { PushNotification} from './utils/PushNotificationUtil';
import { Keyboard} from '@ionic-native/keyboard/ngx';
import { Toast} from '@ionic-native/toast/ngx';
import { Market} from '@ionic-native/market/ngx';
import { EndPopupInfo} from './services/model/EndPopupInfo';
import { ApiConfig} from './services/config';
//import { FCM } from '@ionic-native/fcm/ngx';
import { Sim} from "@ionic-native/sim/ngx";
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router'; 
import { UpdPhoneNoModal } from './user/updPhoneNoModal';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

import "hammerjs";
//import {FirebaseX} from "@awesome-cordova-plugins/firebase-x/ngx";
// Util Group
// import {GoogleAnalyticsUtil} from './utils/GoogleAnalytics';

declare var naver_id_login:any;

@Component({
  templateUrl: 'app.component.html'
  , selector: 'app-root'
  , providers: [ CommonService ]
  , styleUrls: ['app.scss']
})
export class MyApp {
  apiConfig: ApiConfig = new ApiConfig();
  appFrame: HTMLElement;
  appBody: HTMLElement;
  rootPage: any;
  rootMenuId = 'home';
  
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList < IonRouterOutlet > ;
  
  loginNappDataParam = {
    loginId: ''
    , loginPwd: ''
    , uuid: ''
    , deviceModel: ''
    , deviceVersion: ''
    , deviceOsType: ''
    , deviceInfoYn: true
    , loginYn: false
    , appVersion: ''
    , referrer: ''
    , token: ''
  };
  currentMenuId: string = this.rootMenuId;

  pages: Array<{title: string, component: any, menuId: string}>;
  pages_onlogin_all: Array<{title: string, component: any, menuId: string}>;
  pages_onlogin_join: Array<{title: string, component: any, menuId: string}>;
  pages_onlogin_normal: Array<{title: string, component: any, menuId: string}>;

  /**
   * REST Response Data
   * */
  holidayList: Array<any>;

  authData = new AuthModel();

  // push
  deviceUUID: string;
  deviceType: string;
  pushToken: string;

  pushReceiveData = {
    title: ''
    , message: ''
    , image: ''
    , seq: ''
    , pushType: ''
    , pushSeq: ''
  };

  pauseDate: Date;
  resumeDate: Date;
  private diff: number;

  endPopupInfo: EndPopupInfo;

  isInitData = true;
  platformName:string;

  private loaderActive = false;

  constructor(private device: Device, public platform: Platform, public menuCtrl: MenuController
            , public alertCtrl: AlertController, public commonService: CommonService
            , public userService: UserService, public authService: AuthService, public functionService: Function
            , private appVersion: AppVersion, private splashScreen: SplashScreen
            , private statusBar: StatusBar, private nativeStorage: NativeStorage, private deeplinks: Deeplinks
            , public fabric: Fabric, public toast: Toast, private pushService: PushNotification//, private firebaseX: FirebaseX
            , private market: Market, private keyboard: Keyboard, public http: Http, public navController: NavController
            , private router: ActivatedRoute, private vRoute: Router, public loadingCtrl: LoadingController
            , public modalCtrl: ModalController, private sim: Sim, public mobileAccessibility:MobileAccessibility
            , @Optional() private ref: ChangeDetectorRef  ) {

    this.splashScreen.show();

    this.functionService.currentMenuId = this.currentMenuId;

    const filter = 'win16|win32|win64|mac|macIntel';
    let isMobile = false;

    if (navigator.platform ) {
      if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
        isMobile = true;
      } else {
        isMobile = false;
      }
    }
    
    // 앱 활성화 관리 체크 로직 시작
    this.platform.ready().then((readySource) => {
      this.commonService.getMobileActiveUuidPopupList();
      setTimeout(() => {
        if (!this.commonService.isCheckUuid) {
          console.log('this.commonService.isCheckUuid :' + this.commonService.isCheckUuid);
          console.log('유효한 UUID가 아닙니다. : ' + this.device.uuid);
          if ( (this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== 'browser') {
            console.log('앱이므로 공사중 페이지 체크');
            this.commonService.getMobileActiveList();
            setTimeout(() => {
              if (this.commonService.isCheckApi) {
                this.splashScreen.hide();
              } else {
                this.initializeApp();
              }
            }, 1000);
          } else {
            console.log('모바일웹 이므로 공사중 페이지 패스');
            this.initializeApp();
          }
        } else {
          console.log('this.commonService.isCheckUuid :' + this.commonService.isCheckUuid);
          console.log('유효한 UUID입니다. : ' + this.device.uuid);
          this.initializeApp();
        }
     }, 1000);
    });
    
    this.pages = [
      {title: '홈', component: '/home', menuId: 'main'}
      , { title: '부킹센터', component: '/booking', menuId: 'booking'}
      , { title: '제주골프', component: '/tour', menuId: 'tour3'}
      , { title: '국내투어', component: '/tour', menuId: 'tour1'}
      , { title: '해외골프', component: '/tour', menuId: 'tour2'}
      , { title: '쇼핑', component: '/shop/0', menuId: 'shop'}
      , { title: '이벤트', component: '/event', menuId: 'event'}
      //, { title: '공지사항', component: '/board', menuId: 'board' }
      , { title: '환경설정', component: '/config', menuId: 'config' }
    ];

    this.pages_onlogin_all = [
      {title: '홈', component: '/home', menuId: 'main'}
      , { title: '부킹센터', component: '/booking', menuId: 'booking'}
      , { title: '양도 글 등록하기', component: '/joincreate', menuId: 'joinplaza_write_trans'}
      , { title: '조인 글 등록하기', component: '/joincreate', menuId: 'joinplaza_write_join'}
      , { title: '제주골프', component: '/tour', menuId: 'tour3'}
      , { title: '국내투어', component: '/tour', menuId: 'tour1'}
      , { title: '해외골프', component: '/tour', menuId: 'tour2'}
      , { title: '쇼핑', component: '/shop/0', menuId: 'shop'}
      , { title: 'My 골프장리스트', component: '/custom', menuId: 'custom' }
      , { title: '이벤트', component: '/event', menuId: 'event'}
      //, { title: '공지사항', component: '/board', menuId: 'board' }
      , { title: '환경설정', component: '/config', menuId: 'config' }
    ];

    this.pages_onlogin_join = [
      {title: '홈', component: '/home', menuId: 'main'}
      , { title: '부킹센터', component: '/booking', menuId: 'booking'}
      , { title: '조인 글 등록하기', component: '/joincreate', menuId: 'joinplaza_write_join'}
      , { title: '제주골프', component: '/tour', menuId: 'tour3'}
      , { title: '국내투어', component: '/tour', menuId: 'tour1'}
      , { title: '해외골프', component: '/tour', menuId: 'tour2'}
      , { title: '쇼핑', component: '/shop/0', menuId: 'shop'}
      , { title: 'My 골프장리스트', component: '/custom', menuId: 'custom' }
      , { title: '이벤트', component: '/event', menuId: 'event'}
      // , { title: '공지사항', component: '/board', menuId: 'board' }
      , { title: '환경설정', component: '/config', menuId: 'config' }
    ];

    // 일반유저 글등록 권한 삭제
    this.pages_onlogin_normal = [
      {title: '홈', component: '/home', menuId: 'main'}
      , { title: '부킹센터', component: '/booking', menuId: 'booking'}
      , { title: '제주골프', component: '/tour', menuId: 'tour3'}
      , { title: '해외골프', component: '/tour', menuId: 'tour2'}
      , { title: '국내투어', component: '/tour', menuId: 'tour1'}
      , { title: '쇼핑', component: '/shop/0', menuId: 'shop'}
      , { title: 'My 골프장리스트', component: '/custom', menuId: 'custom' }
      , { title: '이벤트', component: '/event', menuId: 'event'}
      // , { title: '공지사항', component: '/board', menuId: 'board' }
      , { title: '환경설정', component: '/config', menuId: 'config' }
    ];
  } // constructor 끝.

  /** --------------------------------------------- 앱 초기화  --------------------------------------------- */
  initializeApp() {
    this.platform.ready().then(() => {

      /*** 딥링크 셋팅 */
      this.deeplinks.routeWithNavController(this.navController, {
        'home': '/home',
        'tour/:state': '/tour',
        'tourdetail/:tempState/:parameter/:pCate': '/tourdetail',
        'shop/:gs_id': '/shop',
        'booking/:joinType' : '/booking'
      },  {'root' : false}
      ).subscribe(match => {
        console.log('Successfully matched route', match);
      }, nomatch => {
        console.error('Got a deeplink that didn\'t match', nomatch);
      });
      
      // 안드로이드 시스템폰트(크기) 설정 무시
      this.mobileAccessibility.setTextZoom(100);
      this.mobileAccessibility.usePreferredTextZoom(false);

      this.platformName = this.functionService.getPlatformName(this.platform);    
      console.log("플랫폼 : " + this.platformName);
      
      // 모바일웹일때 뒤로가기버튼 정책 셋팅
      if (this.device.platform === 'browser') { 
        this.setupBackButtonBehavior();
      }

      this.fabric.setup();                  // fabric setup 실행.
      this.statusBar.overlaysWebView(false);  
      this.statusBar.show();       
      this.statusBar.backgroundColorByHexString('#ffffff');
      this.statusBar.styleDefault();
      this.keyboard.hideFormAccessoryBar(false);

      // 앱이 백그라운드 상태가 될 때
      this.platform.pause.subscribe(() => {
        console.log('itsgolf app pause');
        this.pauseDate = new Date();
      });

      // 앱이 재 실행 될 때
      this.platform.resume.subscribe(() => {
        console.log('itsgolf app resume');
        this.resumeDate = new Date();
        this.diff = Math.floor((this.resumeDate.getTime() - this.pauseDate.getTime()) / 1000);
        const intervalCalculateArray = this.counterSecond(this.diff);
        
        // 앱이 pause된 상태로부터 다시 재실행될때까지의 시간이 지정해놓은 시간 이상이면 데이터를 다시 초기화 한다.
        if (intervalCalculateArray[2] >= 1) {
          this.userService.loginNappDataParam.deviceInfoYn = true;
          setTimeout(() => {
            this.getInitDataResume();
          }, 500);
        }
      });

      /**
       * device 정보 얻어오기 (UUID, platform info, app version, network info, network status)
       * UUID 얻어오기 및 deviceModel, deviceVersion, deviceOsType
       */
       let deviceUUID;
      if ((this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== 'browser') {
        // 모바일기기(아이폰, 안드로이드폰) 이면서 웹이 아닐경우 == 앱 실행시
        deviceUUID = this.device.uuid;
        this.userService.loginNappDataParam.uuid = deviceUUID;
        this.userService.loginNappDataParam.deviceModel = this.device.model;
        this.userService.loginNappDataParam.deviceVersion = this.device.version;
        this.userService.loginNappDataParam.deviceOsType = this.device.platform;        

      } else {
        // 모바일 웹이거나 코도바(ionic serve) 인 경우

        if (this.device.uuid == null) {
          deviceUUID = 'mobileweb';
        } else {
          deviceUUID = this.device.uuid;
        }
        this.userService.loginNappDataParam.uuid = deviceUUID;
        this.userService.loginNappDataParam.deviceModel = this.device.model;
        this.userService.loginNappDataParam.deviceVersion = this.device.version;
        this.userService.loginNappDataParam.deviceOsType = this.device.platform;
      }

      // 안드로이드 앱 이면 퍼미션을 체크한다.
      if ((this.platform.is('android')) && this.device.platform !== 'browser') {
        this.functionService.androidPermissionCheck();
      }
      
      /**
       * 구글 어낼리틱스 -> 사용중지
       */
      if ((this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== 'browser') {
        // 개발
        // this.gaUtil.init(deviceUUID, 'UA-65628461-1');
        // 운영
        //this.gaUtil.init(deviceUUID, 'UA-66412763-1');
      } else {
        //this.gaUtil.init(deviceUUID, 'UA-131529237-1');
      }
      
      // network info
      if ((this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== 'browser') {
        // let netWorkType:string = this.networkUtil.getNetworkType();
        // console.log(netWorkType);
      }

      /**
       * 푸쉬(FCM) 관련 정보 등록
       */
      if ((this.platform.is('ios') || this.platform.is('android')) && this.platform.is('cordova') && this.device.platform !== 'browser') {
        console.log('FCM 정보등록을 시작합니다.');
        console.log('your mobile platform is ==> ' + ((this.platform.is('android'))?'android':'') 
                                                   + ((this.platform.is('ios'))?'ios':''));
        //this.pushServiceInit('649181006437', deviceUUID);
        this.pushServiceInit(deviceUUID);
      }

      /**
       * 로그인 및 앱 기본 데이터 정보 받아오기.
       */
      if ((this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== 'browser') {
        /** sharedPreference error code
         * NATIVE_WRITE_FAILED = 1
           ITEM_NOT_FOUND = 2
           NULL_REFERENCE = 3
           UNDEFINED_TYPE = 4
           JSON_ERROR = 5
           WRONG_PARAMETER = 6
         */

        this.nativeStorage.getItem('autoLogin').then(
          data => {
            this.userService.loginNappDataParam.loginYn = data;
          }
          , error => console.error('sharedPreference autoLogin error : ' + error.code + ' / ' + error.exception)
        );
        this.nativeStorage.getItem('loginUserId').then(
          data => {
            this.userService.loginNappDataParam.loginId = data;
          }
          , error => console.error('sharedPreference loginUserId error : ' + error.code + ' / ' + error.exception)
        );
        this.nativeStorage.getItem('loginUserPw').then(
          data => {
            this.userService.loginNappDataParam.loginPwd = data;
          }
          , error => console.error('sharedPreference loginUserPw error : ' + error.code + ' / ' + error.exception)
        );

        this.nativeStorage.getItem('referrer').then(
          data => {
            const referrerData = data;
            this.nativeStorage.getItem('referrerYn').then(
              data2 => {
                if (data !== 'Y') {
                  this.userService.loginNappDataParam.referrer = referrerData;
                } else {
                  console.log('sharedPreference referrerYn : already regist referrer code');
                }
              }
              , error => {
                // console.log('sharedPreference referrerYn : '+error.code+' / '+error.exception);
                if (error.code === 2 || error.code === 3) {
                  // this.loginNappDataParam.referrer = data;
                  // this.userService.loginNappDataParam.referrer = data;
                }
              }
            );
          }
          , error => {
            // console.log('sharedPreference referrer : '+error.code+' / '+error.exception);
          }
        );

        setTimeout(() => {
          this.getInitData();
        }, 300);
      } else {
        // 웹 테스트용
        // this.userService.loginNappDataParam.uuid = "mobileweb";
        // this.userService.loginNappDataParam.deviceModel = "mobileweb";
        // this.userService.loginNappDataParam.deviceVersion = "6.0.1";

        this.getInitData();
      }
    });

    /**
     * hardware back button management 
     * 하드웨어 백버튼 설정.
     */
    // this.platform.registerBackButtonAction(() => {
    this.platform.backButton.subscribeWithPriority(0, async () => {
    //this.platform.backButton.subscribe(async () => {
      console.log("Hardware back-button of device is pressed.");

      // 골프쇼핑 페이지면 뒤로가기버튼 이벤트 발생시 웹페이지 history.back() 실행.
      if(this.vRoute.url.indexOf('shop') > -1 ) {
        window.history.back();
        return;
      }

      // 모달
      try {
        const modalEl = await this.modalCtrl.getTop();
        if(modalEl) {
          modalEl.dismiss();
          return;
        }
      } catch(error){}

      // alert창
      try {
        const alertEl = await this.alertCtrl.getTop();
        
        // 업데이트창이면은 닫고 종료.
        if(alertEl && alertEl.header === '업데이트 안내') {
          alertEl.dismiss();
          navigator['app'].exitApp();
        } else if(alertEl) {
          alertEl.dismiss();
          return;
        }        
      } catch(error){}

      // menu
      try {
        const menuEl = await this.menuCtrl.getOpen();
        if(menuEl) {
          this.menuCtrl.close();
          return;
        }
      } catch(error){}
      
      this.routerOutlets.forEach(async(outlet: IonRouterOutlet) => {
        console.log("url ==> " + this.vRoute.url);
        
        if (outlet && outlet.canGoBack()) {
          outlet.pop();
        } else {
          if(this.vRoute.url === '/home') {
            this.confirmExitApp();
          } else {
            this.navController.navigateRoot("/home");                      
          }          
        }
      });
    });    
  } // 앱 초기화 끝.

  // 사이드 메뉴 페이지 이동
  openPage(page) {
    this.menuCtrl.close();

    // this.gaUtil.gaTrackEvent('menu', 'bottonTab', page.title, 0);
    if(page.menuId === "tour1") {
      this.navController.navigateRoot(page.component + '/do');      
    } else if(page.menuId === "tour2") {
      this.navController.navigateRoot(page.component + '/ov');      
    } else if(page.menuId === "tour3") {
      this.navController.navigateRoot(page.component + '/do_08');        
    }else if (page.menuId === 'joinplaza_write_trans') {
      this.navController.navigateRoot(page.component+'/trans/new/app');
    } else if (page.menuId === 'joinplaza_write_join') {
      this.navController.navigateRoot(page.component+'/join/new/app');
    } else if (page.menuId === "joinplaza") {
      this.navController.navigateRoot(page.component + '/01');
    } else if (page.menuId === "board") {
      this.navController.navigateForward(page.component);
    } else {
      this.navController.navigateRoot(page.component);  
    }
    this.functionService.currentMenuId = page.menuId;  
  }

  goHome() {
    // this.nav.setRoot(this.rootPage);
    this.navController.navigateRoot(this.rootPage);
    this.functionService.currentMenuId = this.rootMenuId;

    this.menuCtrl.close();    
  }

  hideMenu() {
    this.menuCtrl.close();
  }

  /**
   * 회원가입 하러 가기
   */
  goToUserNewForm() {
    this.menuCtrl.close();
    this.navController.navigateForward('/termcert');
  }

  /**
   * 로그인 하러 가기
   */
  goToLogin() {
    this.menuCtrl.close();
    this.navController.navigateForward(['/login'], {queryParams:{menuId: 'main'}});
  }

  /**
   * 마이페이지 가기
   */
  goMyPage(menuId) {
    this.menuCtrl.close();
    this.navController.navigateForward('/mypage/'+menuId);
  }

    /**
     * hardware back button management
     * for ionic platform browser
     */
  setupBackButtonBehavior() {

    // If on web version (browser)
    if (window.location.protocol !== 'file:') {

      // Register browser back button action(s)
      window.onpopstate = (evt) => {

        // Close menu if open
        if (this.menuCtrl.isOpen()) {
          this.menuCtrl.close ();
          return;
        }

        // Close any active modals or overlays
        // const activePortal = this.ionicApp._loadingPortal.getActive() ||
        //   this.ionicApp._modalPortal.getActive() ||
        //   this.ionicApp._toastPortal.getActive() ||
        //   this.ionicApp._overlayPortal.getActive();

        // if (activePortal) {
        //   activePortal.dismiss();
        //   return;
        // }
        
        // 비동기방식으로 바뀌었기에 의미없음.
        
        // Navigate back
        // 일단 묻어두고 테스트 해보자.
        // if (this.app.getRootNav().canGoBack()) {
        //   this.app.getRootNav().pop();
        // }
      };

      // Fake browser history on each view enter

      // 일단 묻어두고 테스트 해보자.
      // this.navController.viewDidEnter.subscribe((app) => {
      //   history.pushState (null, null, '');
      // });
    }
  }

  /**
   *  getAppInitDataLogin api를 호출하여 앱에서 공통적으로 사용하는 코드, 로그인등을 처리한다.
   */
  getInitData() {
    if ((this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== 'browser') {
      console.log("android or ios");
      this.appVersion.getVersionNumber().then((s) => {
        console.log("app version : "+s);
        this.userService.loginNappDataParam.appVersion = s;
        this.userService.appInit().subscribe(
          data => {
            this.arrangeAppInitData(data);
            this.rootPage = HomePage;
            console.log("TEST!!!!");
            setTimeout(() => {
              console.log("TEST!!!! Splash hide!!!");
              this.splashScreen.hide();
            }, 100);
          }
          , error => {
            console.error('this.userService.appInit().subscribe error');
            let exceptionsMsg = '';
            if (typeof error.status === 'undefined' || error.status === 0) {
              exceptionsMsg = 'statusCode : ' + error.status + ' / statusMsg : error / url : getAppInitDataLogin';
              console.error('exceptionsMsg => ' + exceptionsMsg);
            } else {
              exceptionsMsg = 'statusCode : ' + error.status + ' / statusMsg : ' + error.statusText + ' / url : ' + error.url;
              console.error('exceptionsMsg => ' + exceptionsMsg);
            }

            //this.gaUtil.gaTrackExceptionError(exceptionsMsg);
            this.rootPage = HomePage;
            setTimeout(() => {
              console.log("TEST!!!! Splash hide!!!");
              this.splashScreen.hide();
            }, 100);
          }
        );
      }).catch(e => console.log(e));
    } else {
      console.log("WEB!!!!");
      // 웹에서 테스트용
      if (typeof naver_id_login === undefined) {
        naver_id_login = new naver_id_login('W2bbDYkm9UVaZeUvXC87', 'http://m.itsgolf.co.kr/#/login');
        // naver_id_login = new naver_id_login('JXopPKl9qBOLAn2ikV9V', 'http://121.78.32.30:8000/#/login');
        // naver_id_login = new naver_id_login("JXopPKl9qBOLAn2ikV9V", "http://121.78.32.37:8000/#/login"); // kgu local naver login setting
      }
      if (!naver_id_login.is_callback) { // 최초진입시
      } else { // 콜백
      }
      this.userService.loginNappDataParam.appVersion = '3.0.0';
      this.userService.appInit().subscribe(
        data => {
          console.log(data);
          this.arrangeAppInitData(data);
          const queryString = window.location.search.substring(1);
          const urlParameter = queryString.split('=');
          let paramValue;
          if (urlParameter.length > 1) {
            paramValue = urlParameter[1].split('&');
          } else if (urlParameter[0] !== '' && urlParameter.length === 1) {
            paramValue = '';
          }
          if (urlParameter[0] === '') { // 할인부킹 메인
            this.rootPage = HomePage;
          } else if (urlParameter[0] === 'home') {
            this.navController.navigateRoot(['/home', {
              stateCode: paramValue[0],
              selectDate : paramValue[1]
            }]);
          } else if (urlParameter[0] === 'booking') {
            this.navController.navigateRoot(['/booking', {
              'callMenu': 'main',
              'currentDate': paramValue[1],
              'currentSlideIdx': 0,
              'golfId': paramValue[0],
              'searchTimeRangeType': 'all',
              'type': 'select'
              }]);
          } else if (urlParameter[0] === 'join') {
            this.navController.navigateRoot(['/join', {
              searchType : paramValue[0],
              state : paramValue[1],
              selectDate : paramValue[2]
            }]);
          } else if (urlParameter[0] === 'join_detail') {
            this.navController.navigateRoot(['/joindetail', {
              seq: paramValue[0]
            }]);
          } else if (urlParameter[0] === 'tour') {
            this.navController.navigateRoot(['/tour', {
              state: paramValue[0]
            }]);
          } else if (urlParameter[0] === 'tour_detail') {
            this.navController.navigateRoot(['/tourdetail', {
              parameter: paramValue[0]
            }]);
          } else if (urlParameter[0] === 'event') {
            this.navController.navigateRoot(['/event', {
              searchType: paramValue[0]
            }]);
          } else if (urlParameter[0] === 'event_detail') {
            this.navController.navigateRoot(['/eventdetail', {
              seq: paramValue[0]
            }]);
          } else if (urlParameter[0] === 'login') {
            this.navController.navigateRoot('/login');
          } else {
            this.rootPage = HomePage;
          }
        }
        , error => {
          this.rootPage = HomePage;
        }
      );
    }
  }

  getInitDataResume() {
    this.appVersion.getVersionNumber().then((s) => {
      this.userService.loginNappDataParam.appVersion = s;

      this.userService.appInit().subscribe(
        data => {
          this.arrangeAppInitData(data);
        }
        , error => {
          let exceptionsMsg = '';
          if (typeof error.status === 'undefined' || error.status === 0) {
            exceptionsMsg = 'statusCode : ' + error.status + ' / statusMsg : error / url : getAppInitDataLogin';
          } else {
            exceptionsMsg = 'statusCode : ' + error.status + ' / statusMsg : ' + error.statusText + ' / url : ' + error.url;
          }
          //this.gaUtil.gaTrackExceptionError(exceptionsMsg);
        }
      );
    }).catch(e => console.log(e));
  }

  /**
   * /getAppInitDataLogin api를 통해서 얻은 holiday list, area code list, login data를 정리한다.
   */
  arrangeAppInitData(data) {
    // commonData setting
    this.commonService.commonData.holidayList = data.deviceInfoResultData.holidayList;
    this.commonService.commonData.areaCodeList = data.deviceInfoResultData.areaCodeList;
    this.commonService.commonData.areaCodeListTour = data.deviceInfoResultData.areaCodeListTour;
    this.commonService.commonData.appUpdateYn = data.deviceInfoResultData.appVersionData.app_update;
    this.commonService.commonData.appVersion = data.deviceInfoResultData.appVersionData.app_version;
    this.commonService.commonData.appUrl = data.deviceInfoResultData.appVersionData.app_url;
    this.commonService.commonData.notiYn = data.deviceInfoResultData.notiYn;
    this.endPopupInfo = data.deviceInfoResultData.endPopupInfo as EndPopupInfo;

    // userData setting
    if (data.loginResultData.resultCode === 'SU') {
      this.authService.userData = data.loginResultData.data as AuthModel;
      if (this.authService.userData.user_id === '') {
        this.authService.loginYn = false;
      } else {
        this.authService.loginYn = true;
      }
    } else if (data.loginResultData.resultCode === 'EL') {
      this.authService.loginYn = false;
    } else {
      if (this.authService.userInfo === '') {
        this.authService.loginYn = false;
      } else {
        this.authService.loginYn = true;
      }
    }

    let toastMsg = '';
    if (this.authService.loginYn) {
      toastMsg = this.authService.userData.user_name + '님 로그인 되었습니다.';
    } else {
      toastMsg = '로그인을 하시면 다양한 서비스 이용이 가능합니다.';
    }
    if ((this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== 'browser') {
      this.toast.show(toastMsg, 'short', 'bottom').subscribe(
        toast => {
        }
      );

      // 강제업데이트 여부확인
      if (this.commonService.commonData.appUpdateYn) this.modalForUpdate();

      // 전화번호 변경 확인 _ 로그인시 확인 - login.ts에도 있음, 아이폰은 불가
      if (this.authService.loginYn && this.platform.is('android')) {   
        // 권한확인 (없으면 요청함)
        this.sim.requestReadPermission().then(
          () => {
            // 획득
            console.log('Permission granted');
            // this.takeNumAndUpdate();
          },
          () => { 
            // 거부
            console.log('Permission denied');                
          }
        );
      }
    }    
  }

  /**
   * 전화번호 변경시 업데이트 유도
   */
  takeNumAndUpdate() {
    this.sim.getSimInfo().then(
      (info) => {
        console.log("모바일 디바이스 전화번호 : " + info.phoneNumber.replace(/-/g, ""));
        console.log("DB 기 저장 전화번호 : " + this.authService.userData.userHandphone.replace(/-/g, ""));
  
        // 전화번호가 다를경우 업데이트 유도 팝업창을 띄운다.
        if(info.phoneNumber.replace(/-/g, "") !== this.authService.userData.userHandphone.replace(/-/g, "")) {
          this.functionService.showConfirmAlert('기존에 등록되어있는 전화번호와 이용하시는 디바이스의 전화번호가 서로 상이합니다.<br>'
          + '연락처인증을 통하여 최신전화번호로 업데이트 하신 후 사용하십시오.').then((data) => {
            if(data) {
              if (data.res = 'yes') {
                this.updPhoneModal().then((data) => {
                  if (data['result'] === 'confirmed') {
                    this.toast.show('전화번호가 업데이트 되었습니다.', 'short', 'bottom').subscribe();
                  }
                });
              }
            }          
          });
        }
      }
      , (error) => {
        console.log('Sim info Error' + error);
        const errorAlert = this.functionService.showErrorAlert2('디바이스 핸드폰 번호를 가져오는데 실패 하였습니다.');
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    );
  }
  
  async modalForUpdate() {
    // 업데이트를 해야함.
    const appUpdateConfirm = await this.alertCtrl.create({
      header : '업데이트 안내'
      , message : '새 버전이 출시되었습니다!! 개선된 기능을 사용하기 위해 업데이트를 받아주시기 바랍니다.\n 하단 업데이트 버튼을 누르시면 마켓으로 이동됩니다.'
      , backdropDismiss : false
      , buttons: [
        {
          text: '종료'
          , handler: () => {
          // this.platform.exitApp();
          navigator['app'].exitApp();
        }
        }
        , {
          text: '업데이트'
          , handler: () => {
            this.market.open('com.kgrid.itsgolf.app');
            // this.platform.exitApp();
            navigator['app'].exitApp();
          }
        }
      ]
    });
    return await appUpdateConfirm.present();
  }

  confirmExitApp() {
    this.functionService.showAppEndPopup('종료하시겠습니까?', '', this.apiConfig.apiObject.pcWeb + this.endPopupInfo.image).then((data)=>{
      // if ('undefined' !== data || 'undefined' !== data.res) {
      if (data) {
        if (data.res === 'yes') {
          //this.platform.exitApp();
          navigator['app'].exitApp();
        } else if (data.res === 'clickImage') {
          this.showLoader('잠시만 기다려주세요');
          
          const landingModel = {
            target: ''
            , golfId: ''
            , teeupDate: ''
            , joinSeq: ''
            , tourSeq: ''
            , tourState: ''
            , eventSeq: ''
          };
 
          const landingPageTmp = this.endPopupInfo.landingPage.split(', ');
 
          for (let i = 0; i < landingPageTmp.length; i++) {
            const landingPageTmp2 = landingPageTmp[i].split('=');
 
            if ('eventSeq' === landingPageTmp2[0]) {
              landingModel.eventSeq = landingPageTmp2[1];
            }
          }
          setTimeout(() => {
            this.navController.navigateForward('/eventdetail/'+landingModel.eventSeq);
            this.dismissLoader();            
          }, 200);
        }
      } else {}
    });
  }

  counterSecond(time) {
    let intervalCalculateArray = [];
    let days, hours, minutes, seconds;
    days = Math.floor(time / 86400);
    time += days * 86400;
    hours = Math.floor(time / 3600) % 24;
    time += hours * 3600;
    minutes = Math.floor(time / 60) % 60;
    time += minutes * 60;
    seconds = time % 60;

    intervalCalculateArray = [days, hours, minutes, seconds];

    return intervalCalculateArray;
  }

  /**
   * push notification
   */
  //pushServiceInit(senderId, uuid) {
  pushServiceInit(uuid) {
    this.deviceUUID = uuid;
    // const options: any = {
    //   android: {
    //     senderID: senderId
    //     , icon: 'ic_launcher'
    //   },
    //   ios: {
    //     alert: 'true',
    //     badge: true,
    //     sound: 'false',
    //     clearBadge: true
    //   },
    //   windows: {}
    // };

    //this.pushEventSet();
  }

  /*pushEventSet() {

    this.firebaseX.getToken().then(token => {
      console.log('The Token is '+ token);
      this.pushRegistration(token);
    });

    this.firebaseX.onTokenRefresh().subscribe((token) => {
      console.log('The Token is '+ token);
      this.pushRegistration(token);
    }, error => {
      console.log(error);
    });

    this.firebaseX.onMessageReceived().subscribe((data) => {
      // console.log(data);
      // console.log("Firebase Message type: " + data.messageType);
      // if(data.messageType === 'notification'){
      //
      // }

      //console.log("Firebase push received json data ==> " + JSON.stringify(data));
      this.pushReceiveData.image = data.img;
      this.pushReceiveData.seq = data.seq;
      this.pushReceiveData.pushType = data.joinType;
      this.pushReceiveData.title = data.title;
      this.pushReceiveData.message = data.body;
      this.pushReceiveData.pushSeq = data.pushSeq;

      //   /**
      //   * pushType
      //   * 1. event -> 기타 이벤트. 이벤트 메뉴로 보냄.
      //   * 2. trans -> 조인플라자 문자문의하기.
      //   * 3. tour -> 투어 상품용.
      //   * 4. landing -> 실시간 타임용으로 자주 사용됨.
      //   */
      //   // background 일때
      //console.log("Firebase data_tap : "+data.tap);
      /*setTimeout(() => {
        //console.log("백그라운드 상태에서 push 수신");
        //console.log("type = > " + this.pushReceiveData.pushType);

        const receivedTime = new Date();
        let setTime = "";

        if(this.pushReceiveData.pushType === "join" || this.pushReceiveData.pushType === "trans") {
          // 발송시간 개발되면 발송시간 data객체에서 받아서 대입해줄것.
          setTime = data.sendTime;
        }

        this.functionService.showPushPopupWithTime(this.pushReceiveData.title, this.pushReceiveData.message
            , this.pushReceiveData.image, setTime).then((data) => {

          if(data) {
            if (data.res === 'yes') {
              if (this.pushReceiveData.pushType === 'landing') {
                // landing page
                this.landingPagePush(this.pushReceiveData, data);
              } else if (this.pushReceiveData.pushType === 'trans' || this.pushReceiveData.pushType === 'join') {
                // 조인플라자 문자문의하기
                this.joinPush(this.pushReceiveData, data);
              } else if (this.pushReceiveData.pushType === 'tour' || this.pushReceiveData.pushType === 'tourMain') {
                // 투어 상세페이지
                this.tourPush(this.pushReceiveData, data);
              } else if (this.pushReceiveData.pushType === 'accuse') {
                // 신고글 수정.
                const NavigationExtras: NavigationExtras = {
                  queryParams: {
                    accuse: 'true',
                    pushSeq: this.pushReceiveData.pushSeq
                  }
                };

                this.navController.navigateForward(['/joindetail/edit/my/' + this.pushReceiveData.seq], NavigationExtras);
              } else {
                // 기타 이벤트
                this.navController.navigateForward('/eventdetail/' + this.pushReceiveData.seq);
              }
            } else { }
          }
        });
      }, 500);
    }, error => {
      console.log("onMessageReceived get error");
      console.log(error);
    });
  }*/

  // 푸시 발송시간 정제용 펑션
  leftpad(val, resultLength = 2, leftpadChar = '0') {
    return (String(leftpadChar).repeat(resultLength)
        + String(val)).slice(String(val).length);
  }

  // 토큰 등록 및 재등록
  pushRegistration(token) {
    this.pushToken = token;
    this.userService.loginNappDataParam.token = this.pushToken;

    if ((this.platform.is('android')) && this.device.platform !== 'browser') {
      this.deviceType = 'android';
    } else {
      this.deviceType = 'ios';
    }
    console.log('this.pushToken : ' + this.pushToken);
    const params = {
      uuid: this.deviceUUID
      , type: this.deviceType
      , token: this.pushToken
    };

    this.pushService.registPushToken(params);
  }

  checkLogin() {
    if (this.authService.loginYn) {
      this.navController.navigateForward('/mypage/notification');      
    } else {
      this.functionService.showConfirmAlert('로그인을 하셔야 마이페이지 - 알림 화면으로 넘어 갈 수있습니다. <br><br> 로그인 하러 가시겠습니까?').then((data) => {
        if(data) {
          if (data.res = 'yes') {
            setTimeout(() => {
              this.navController.navigateForward(['/login', 
                { type: 'requireN', 
                  nextPage: Mypage, 
                  parameter: 'notification', 
                  menuId: 'notification'}
              ]);
            });
          }
        }        
      });
    }
  }

  /**
   *
   */
  landingPagePush(pushReceiveData, notification) {
    if ('' !== notification.additionalData.landingPage && 'undefined' !== typeof notification.additionalData.landingPage) {
      const landingPageTmp = notification.additionalData.landingPage.split(', ');
      let target;
      let golfId;
      let state;
      let teeupDate;

      for (let i = 0; i < landingPageTmp.length; i++) {
        const landingPageTmp2 = landingPageTmp[i].split('=');
        if ('target' === landingPageTmp2[0]) {
          target = landingPageTmp2[1];
        }
        if ('golfId' === landingPageTmp2[0]) {
          golfId = landingPageTmp2[1];
        }
        if ('state' === landingPageTmp2[0]) {
          state = landingPageTmp2[1];
        }
        if ('teeupDate' === landingPageTmp2[0]) {
          teeupDate = landingPageTmp2[1];
        }
      }

      if (target === 'booking') {
        const dateListInfo = this.commonService.makeDateArrayForSearch('booking');
        const dateListForSearch = dateListInfo.dateArray;

        let currentSlideIdx = 0;

        for (let i = 0 ; i < dateListForSearch.length ; i++) {
          for (let j = 0 ; j < dateListForSearch[i].length ; j++) {
            if (teeupDate === dateListForSearch[i][j].formattedDate) {
              currentSlideIdx = i;
              break;
            }
          }
        }
        this.navController.navigateForward('/booking', {queryParams:{
          golfId: golfId
          , golfName: ''
          , callMenu: 'main'
          , type: 'select'
          , currentDate: teeupDate
          , currentSlideIdx: currentSlideIdx}});
      }
    }
  }

  joinPush(pushReceiveData, notification) {
    this.navController.navigateForward('/joindetail/detail/my/' + pushReceiveData.seq);
  }

  tourPush(pushReceiveData, notification) {
    if (this.pushReceiveData.pushType === 'tour') {
      // 로그인이 안되어 있는 경우에는 로그인하라는 안내 팝업을 띄우자
      this.showLoader('잠시만 기다려주세요');
      setTimeout(() => {
        // this.nav.push(TourDetailPage, {parameter : pushReceiveData.seq});
        this.navController.navigateForward('/tourdetail', {queryParams:{parameter : pushReceiveData.seq}});

        setTimeout(() => {
          this.dismissLoader();
        });
      }, 500);
    }
    if (this.pushReceiveData.pushType === 'tourMain') {
      if ('' !== notification.additionalData.landingPage && 'undefined' !== typeof notification.additionalData.landingPage) {
        const landingPageTmp = notification.additionalData.landingPage.split(', ');
        let target;
        let state;

        for (let i = 0; i < landingPageTmp.length; i++) {
          const landingPageTmp2 = landingPageTmp[i].split('=');

          if ('target' === landingPageTmp2[0]) {
            target = landingPageTmp2[1];
          }
          if ('state' === landingPageTmp2[0]) {
            state = landingPageTmp2[1];
          }
        }

        //this.nav.push(TourPage, {state: state});
        this.navController.navigateForward(['/tour', {state: state}]);
      }
    }
  }

  // toggleMenu
  toggleMenu() {
    this.menuCtrl.toggle();
  }  

  async updPhoneModal() {
    const pushAlert = await this.modalCtrl.create(
      {
        component: UpdPhoneNoModal
        , componentProps: {
          userId : this.userService.loginNappDataParam.loginId
        }
      }
    );

    pushAlert.present();
    const {data} = await pushAlert.onWillDismiss();
    return data;
  }

  // 쇼로딩
  async showLoader(message) {
    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  } 
}
