import { NavParams, Platform, ModalController, LoadingController } from "@ionic/angular";
import { UserService } from "../services/user-service";
import { Function } from "../services/function";
import { Component } from "@angular/core";
import { debounceTime } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
/**
 * Created by bbang on 2017-05-19.
 */

@Component({
  selector: 'page-login',
  templateUrl: 'newPasswordModal.html',
  providers: [ UserService ],
  styleUrls: [ 'login.scss' ]
})

export class NewPasswordModal{
  resetPassWord ={
    loginUserId: ''
    , newPasswd: ''
  };

  newPasswdCheck = '';

  userNewForm:FormGroup;

  formErrors = {
    'loginPw': []
    , 'loginPwConfirm': []
  };

  validationMessages = {
    'loginPw': {
      'required': '비밀번호를 입력해주세요'
      , 'minlength': '최소 4자 이상 입력하세요.'
      , 'maxlength': '최대 15자 까지만 입력하세요.'
      , 'pattern': '영문, 숫자가 포함되어야 합니다.'
    }
    , 'loginPwConfirm': {
      'required': '입력하신 비밀번호와 같은 비밀번호를 입력해주세요.'
      , 'minlength': '최소 4자 이상 입력하세요.'
      , 'maxlength': '최대 15자 까지만 입력하세요.'
      , 'pattern': '영문 및 숫자 조합만 입력 가능합니다.'
      , 'validateEqual': '비밀번호가 다릅니다.'
    }
  };

  private loaderActive = false;

  constructor(public params: NavParams, public platform: Platform, private functionService: Function, private userService: UserService
    , public formBuilder: FormBuilder, public modalCtrl: ModalController, private loadingCtrl: LoadingController){
    this.resetPassWord.loginUserId = this.params.get('loginId');

    this.userNewForm = this.formBuilder.group({
      loginPw: ['', Validators.compose([Validators.minLength(4), Validators.maxLength(15), Validators.pattern('^(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]+$'), Validators.required])]
      , loginPwConfirm: ['', Validators.compose([Validators.minLength(4), Validators.maxLength(15), Validators.pattern('^(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]+$'), Validators.required])]
    });

    this.userNewForm.valueChanges.pipe(debounceTime(100)).subscribe(data => this.onValueChanged(data));
  }

  onValueChanged(data?: any) {
    if (!this.userNewForm) { return; }
    const form = this.userNewForm;
    for (const field in this.formErrors) {
      // clear previous error message
      this.formErrors[field] = [];
      this.userNewForm[field] = '';
      const control = form.get(field);
      //console.log(control);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          //console.log(key);
          //console.log(messages[key]);
          this.formErrors[field].push(messages[key]);
        }
      }
    }
  }
  
  dismiss(){
    this.modalCtrl.dismiss();
  }

  requestNewPw(){
    this.showLoader('비밀번호 변경중 입니다');

    if(!this.userNewForm.valid){
      const notiAlert = this.functionService.showNotiAlert('각 항목을 제대로 입력하셨는지 확인해주시기 바랍니다.');
      
      setTimeout(() => {
        this.dismissLoader();
      }, 200);
    }else{
      this.resetPassWord.newPasswd = this.userNewForm.value.loginPw;

      this.userService.updatePasswd(this.resetPassWord).subscribe(
        data => {
          console.log(data);
          if('undefined' === typeof data.error){
            this.functionService.showNotiAlert(data.message).then((data)=> {
              this.dismissLoader();
              setTimeout(() => {
                this.modalCtrl.dismiss({'result':'confirmed'});                
              }, 300);              
            });            
          }else{
            const errorAlert = this.functionService.showErrorAlert2(data.error);
          }
        }, error => {
          console.log(error);
          setTimeout(() => {
            this.dismissLoader();
          });
        }, () => {
          setTimeout(() => {
            this.dismissLoader();
          });
        }
      );
    }
  }

  // 쇼로딩
  async showLoader(message) {
    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  }
}
