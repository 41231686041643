import {
    Component,
    Input,
    ElementRef,
    OnInit,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'vice-cover-item',
    template: '<ng-content></ng-content>'
})
export class ViceCoverItem implements OnInit, OnChanges {
    private element:HTMLElement;
    @Input() imageUrl;

    constructor(element:ElementRef) {
        this.element = element.nativeElement;
    }

    ngOnInit() {
        this.setBackgroungImage(this.imageUrl);
    }

    ngOnChanges(changes:SimpleChanges) {
        for (let propName in changes) {
            propName === 'imageUrl' && this.setBackgroungImage(this.imageUrl);
        }
    }

    private setBackgroungImage(imageUrl) {
        if (imageUrl) {
            this.element.style.backgroundImage = `url(${imageUrl})`;
            return;
        }

        this.element.style.backgroundImage = '';
    }
}
