import {NavController, NavParams, ModalController, Platform} from '@ionic/angular';
import {Component} from '@angular/core';
import {BookingServeice} from '../services/booking-serveice';
import {BookingListModel} from '../services/model/booking/BookingListModel';

@Component({
  selector: 'page-booking'
  , templateUrl: 'booking_cancel_info_modal.html'
  , providers: [BookingServeice]
  , styleUrls: ['booking.scss']
})
export class BookingCancelInfoMoal {
  detailModel: BookingListModel ;

  popup_order_type_name: string;

  constructor(public navCtrl: NavController, public navParams: NavParams, public modalCtrl: ModalController
              , public bookingServ: BookingServeice, private platform:Platform) {
    this.detailModel = this.navParams.get('detailModel');
    if (this.detailModel.order_type === '01') {
      this.popup_order_type_name = '결제';
    } else {
      this.popup_order_type_name = '예약';
    }    
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad');
  }

  dismiss() {
    console.log('dismiss');
    // const data = { 'result': 'cancel' };
    this.modalCtrl.dismiss({result: 'cancel'});
  }

  goToReservation() {
    console.log('goToReseration');
    // const data = { 'result': 'agree' };
    this.modalCtrl.dismiss({result: 'agree'});
  }
}
