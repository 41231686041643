/**
 * Created by kgrid-dev-bbk on 2017-02-07.
 */
import {Platform} from '@ionic/angular';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { Injectable } from '@angular/core';

@Injectable()
export class NetworkUtil {
  disconnectCheck: any;

  constructor(private network: Network, private toast: Toast, private dialogs: Dialogs, public platform: Platform) {
    this.initDisconnectChecker();
  }

  initDisconnectChecker() {
    this.disconnectCheck = this.network.onDisconnect().subscribe(() => {
      console.log('disconnectCheck');
      this.toast.show('test', 'long', 'bottom');
      console.log(this.getNetworkType());
      this.dialogs.alert('LTE/3G 혹은 WIFI의 ON/OFF 상태를 확인하시고 앱을 재실행하시기 바랍니다. 확인 버튼을 누르시면 앱이 종료됩니다.'
                         , '네트워크 오류', '확인').then(() => {
        navigator['app'].exitApp();
      });
    });
  }

  getNetworkType() {
    return this.network.type;
  }
}
