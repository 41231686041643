import {
  ModalController, NavController, LoadingController, NavParams, Platform, AlertController
} from '@ionic/angular';
import { Component} from '@angular/core';
import { UserService} from '../services/user-service';
import { Function} from '../services/function';
import { ApiConfig} from '../services/config';
import { AuthService} from '../services/auth-service';
import { AnalyticsFirebase} from '@ionic-native/analytics-firebase/ngx';
import { debounceTime } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Device } from '@ionic-native/device/ngx';
import { Sim} from "@ionic-native/sim/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";

@Component({
  selector: 'page-login'
  , templateUrl: 'updPhoneNoModal.html'
  , providers: [UserService]
  , styleUrls: [ 'user.scss' ]
})
export class UpdPhoneNoModal {

  apiConfig: ApiConfig = new ApiConfig();
  phoneUpdForm:FormGroup;
  deviceHandphoneNumber:any;
  certificationNumber:string;
  certificationNumberYn:boolean;
  requestCertiNumberYn:boolean;
  userId:string;
  
  formErrors = {
    'loginPw': []
    , 'loginPwConfirm': []
  };

  validationMessages = {
    'loginPw': {
      'required': '비밀번호를 입력해주세요'
      , 'minlength': '최소 4자 이상 입력하세요.'
      , 'maxlength': '최대 15자 까지만 입력하세요.'
      , 'pattern': '영문, 숫자가 포함되어야 합니다.'
    }
    , 'loginPwConfirm': {
      'required': '입력하신 비밀번호와 같은 비밀번호를 입력해주세요.'
      , 'minlength': '최소 4자 이상 입력하세요.'
      , 'maxlength': '최대 15자 까지만 입력하세요.'
      , 'pattern': '영문 및 숫자 조합만 입력 가능합니다.'
      , 'validateEqual': '비밀번호가 다릅니다.'
    }
  };


  private loaderActive = false;

  constructor(public platform: Platform, public navCtrl: NavController, public navParams: NavParams
    , public userService: UserService, public functionService: Function, public modalCtrl: ModalController
    , public authService: AuthService, public formBuilder: FormBuilder, public alertCtrl: AlertController
    , private loadingCtrl: LoadingController, private analyticsFirebase: AnalyticsFirebase
    , private device: Device, public sim: Sim, public diagnostic: Diagnostic) 
  {

    this.phoneUpdForm = this.formBuilder.group({
      loginPw: ['', Validators.compose([Validators.minLength(4), Validators.maxLength(15), Validators.pattern('^(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]+$'), Validators.required])]
      , loginPwConfirm: ['', Validators.compose([Validators.minLength(4), Validators.maxLength(15), Validators.pattern('^(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]+$'), Validators.required])]
    });
    this.phoneUpdForm.valueChanges.pipe(debounceTime(100)).subscribe(data => this.onValueChanged(data));

    this.userId = this.navParams.get('userId');
      
    this.init();    
  }


  init() {
    this.platform.ready().then(() => {
      this.analyticsFirebase.setCurrentScreen('updPhoneNoModal')
        .then(() => console.log('View successfully tracked'))
        .catch(err => console.log('Error tracking view:', err));
    });
  }

  onValueChanged(data?: any) {
    if (!this.phoneUpdForm) { return; }
    const form = this.phoneUpdForm;
    for (const field in this.formErrors) {
      // clear previous error message
      this.formErrors[field] = [];
      this.phoneUpdForm[field] = '';
      const control = form.get(field);
      //console.log(control);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          //console.log(key);
          //console.log(messages[key]);
          this.formErrors[field].push(messages[key]);
        }
      }
    }
  }

  /**
   * 모달창 닫기
   * @param action 
   */
  dismissModal() {
    this.modalCtrl.dismiss({result: 'cancel'});
  }

  getPhoneNo() {
    // 권한확인 (없으면 요청함)
    // this.sim.requestReadPermission().then(
    //   () => {
    //     // 획득
    //     console.log('Permission granted');     
    //     this.sim.getSimInfo().then(
    //       (info) => {
      // 기존 this.updPhoneNo(info.phoneNumber);
      this.updPhoneNo("");
    //       }
    //     );   
    //   },
    //   () => { 
    //     // 거부
    //     console.log('Permission denied');                
    //   }
    // );
  }

  /**
   * 전화번호 업데이트
   */
  async updPhoneNo(phoneno) {
    const phoneNumberPrompt:any = await this.alertCtrl.create({
      header: '알림'
      , message: "핸드폰 번호를 '-' 없이 숫자만 입력하시고 확인을 누르시면 인증번호가 발송됩니다."
      , inputs: [
        {
          name: 'phoneNumberInput'
          , type: 'tel'
          , value: phoneno
        }
      ]
      , buttons: [
        {
          text: '취소'
          , role: 'cancel'
        }, {
          text: '확인'
          , handler: data => {
            if(data.phoneNumberInput !== ''){
              this.deviceHandphoneNumber = data.phoneNumberInput;

              this.requestCertificaionNumber();
            }
          }
        }
      ]
    });

    await phoneNumberPrompt.present();
  }

  requestCertificaionNumber(){
    this.showLoader('인증번호 요청중');
    //인증번호 받기
    let permissionCheck = true;

    // if(this.platform.is('android') && this.device.platform !== "browser") {
    //   this.sim.hasReadPermission().then(
    //     (info) =>{
    //       permissionCheck = info;
    //     }
    //   );

    //   if(permissionCheck){
    //     this.sim.getSimInfo().then(
    //       (info) => {
    //         //this.toast.showShortBottom('phoneNumber : '+info.phoneNumber).subscribe();
    //         this.deviceHandphoneNumber = info.phoneNumber;
    //       }
    //       , (error) => {
    //         console.log('Sim info Error' + error);
    //         const errorAlert = this.functionService.showErrorAlert2('디바이스 핸드폰 번호를 가져오는데 실패 하였습니다.');

    //         setTimeout(() => {
    //           this.dismissLoader();
    //         }, 100);
    //       }
    //     );
    //   }
    // }else if(this.platform.is('ios') && this.device.platform !== "browser"){
    //   this.deviceHandphoneNumber = this.deviceHandphoneNumber;

    // }else{
      this.deviceHandphoneNumber = this.deviceHandphoneNumber;
    //}

    setTimeout(() => {
      if(permissionCheck){
        const params = {
          handphone: this.deviceHandphoneNumber
          , type: ''
        };

        this.userService.getConfirmNo(params).subscribe(
          data => {
            if(typeof data.error === 'undefined'){
              const notiAlert = this.functionService.showNotiAlert(data.message + '<br>문자를 받으시면 인증번호를 정확하게 입력해주시기 바랍니다.<br>(통신사 사정에 따라 문자 발송이 지연 될 수 있습니다.)');
            }else{
              const errorAlert = this.functionService.showErrorAlert2(data.error);
            }

            setTimeout(() => {
              this.dismissLoader();
            }, 100);
          }, error => {
            console.log(error);
            const errorMessageForGA = 'category:termCert/function:getConfirmNo'+'/errorType:exception'+'/message:'+error.toString();
            // this.gaUtil.gaTrackExceptionError(errorMessageForGA);
            setTimeout(() => {
              this.dismissLoader();
            }, 100);
          }
        );
      }else{
        setTimeout(() => {
          this.dismissLoader();
        }, 100);

        const notiAlert = this.functionService.showNotiAlert('핸드폰 번호를 가져오기 위해서는 권한 허용을 해주셔야 합니다. 권한 허용 후 다시 인증번호 요청을 해주시기 바랍니다.' +
          '<br><br>(회원 중복등록 방지 등을 위해 기기에 등록되어 있는 핸드폰 번호를 가져오게 되어 있으며 기타 디바이스 정보는 수집하지 않습니다.)')
        .then((data)=>{
          const androidPermissions = [
            this.diagnostic.permission.READ_PHONE_STATE
          ];
          this.diagnostic.requestRuntimePermissions(androidPermissions).then(
            (statuses) =>{
              console.log(statuses);
            }, (error) =>{
              console.log(error);
            }
          );
        });
      }
    }, 500);
  }

  confirm(){
    //인증번호 확인
    this.showLoader('확인중입니다.');
    
    if(typeof this.certificationNumber === 'undefined' || this.certificationNumber === ''){
      const errorAlert = this.functionService.showNotiAlert('받으신 문자에 있는 인증번호를 입력해주시기 바랍니다.');

      setTimeout(() => {
        this.dismissLoader();
      }, 100);
    }else{
      //this.subscription.unsubscribe();
      this.requestCertiNumberYn = false;
      const params = {
        randomNum: this.certificationNumber
        , handphone: this.deviceHandphoneNumber
        , findType: 'handphone'
      };

      this.userService.confirmNumber(params).subscribe(
        data => {
          if(typeof data.error === 'undefined'){
            this.certificationNumberYn = true;
            this.authService.userData.userHandphone = this.deviceHandphoneNumber;
            
            this.functionService.showNotiAlert('인증이 완료되었습니다.').then((data) => {
              if(data) {
                if (data.res === 'yes') {
                  const params = {
                    loginId: this.userId,
                    handphone: this.deviceHandphoneNumber,
                    findType: 'handphone'
                  };
                  this.userService.updatePhoneNumber(params).subscribe(
                    data => {
                      if(typeof data.error === 'undefined'){
                        console.log(data.message);
                        // 디스미스 로딩.
                        setTimeout(() => {
                          this.modalCtrl.dismiss({result: 'confirmed'});  
                        }, 500);                      
                      } else {
                        this.functionService.showNotiAlert('에러가 발생하였습니다. 다시 시도해 주시기 바랍니다.');      
                      }
                    }, error => {
                      console.log(error);
                    } 
                  );
                }
              }
              
            });
              
          }else if(typeof data.error !== 'undefined' && data.code === 'TO'){
            this.certificationNumberYn = false;
            this.certificationNumber = '';
            const notiAlert = this.functionService.showNotiAlert('인증번호 입력 유효 시간이 지났습니다. 인증번호 받기를 다시 시도해주시기 바랍니다.<br>(인증번호는 5분간만 유효합니다.)');
          }else{
            this.certificationNumberYn = false;

            const errorAlert = this.functionService.showErrorAlert2(data.error);
          }

          setTimeout(() => {
            this.dismissLoader();
          }, 100);
        }, error => {
          this.dismissLoader();
          console.log(error);
        }
      );
    }
  }

  // 쇼로딩
  async showLoader(message) {
    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  }
}
