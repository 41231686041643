import {TourService} from "../services/tour-service";
import {Component} from "@angular/core";
import {NavController, NavParams, PopoverController} from "@ionic/angular";

@Component({
  selector: 'page-tour'
  , templateUrl: 'tour_region_pop_over.html'
  , providers: [TourService]
  , styleUrls: ['tour.scss']
})
export class TourRegionPopOver {
  regionList:any;

  constructor(public navCtrl: NavController, public navParams: NavParams, public popoverController: PopoverController) {
    this.regionList = this.navParams.get("regionList");
    this.init();
  }

  dismiss(){
    this.popoverController.dismiss();
  }

  init(){
  }

  setRegionCode(stateCode, stateName) {
    let data:any; 
    
    if(typeof stateCode === "undefined") {
      data = {"code":"", "name":stateName}; 
    } else {
      data = {"code":stateCode, "name":stateName};       
    }    
    this.popoverController.dismiss(data); // dismiss with data
  }
}
