import { IonicModule, IonApp, IonicRouteStrategy} from '@ionic/angular';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, Location} from '@angular/common'

import { MyApp } from './app.component';
import { Function } from './services/function'; 

/* ------------------------- 프리로딩 페이지들 ----------------------------- */
import { BookingCompleteModal } from './booking/booking_complete_modal';
import { GolfSelectModal} from './join/golf_list_modal';
import { MypageDetail } from './mypage/mypage_detail';

/* 회원 관련 페이지 */
import { UserNewComplete} from './user/userNewComplete';
// itsgolf common provider
import { AuthService} from './services/auth-service';
import { UserService} from './services/user-service';
// itsgolf custom util
import { NetworkUtil} from './utils/NetworkUtil';
import { PushNotification} from './utils/PushNotificationUtil';
// Native Library
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { Market } from '@ionic-native/market/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Sim } from '@ionic-native/sim/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { SMS} from '@ionic-native/sms/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SocialSharing} from '@ionic-native/social-sharing/ngx';
import { ActionSheet} from '@ionic-native/action-sheet/ngx';
import { DatePicker} from '@ionic-native/date-picker/ngx';
import { Deeplinks} from '@ionic-native/deeplinks/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// Third-Party Library
import { Notifying } from './utils/notifying/notifying';
// ETC Library
import { DatePipe, registerLocaleData } from '@angular/common';
import { CommonService} from './services/common-service';
import { Fabric} from './utils/fabric';
import { CustomErrorHandlerWithFabric} from './utils/CustomErrorHandlerWithFabric';
import { ViceCoverItem} from './utils/vice-cover-item.component';
import { TrimPipe} from './CustomPipe/TrimPipe';
import { TourDetailImageModal} from './tour/tour_detail_image_modal';
import {TourRegionPopOver} from './tour/tour_region_popover';
import { HttpModule} from '@angular/http';
import { BrowserModule} from '@angular/platform-browser';
import { MypageArticleFilterModal} from './mypage/mypage_article_filter_modal';
import { BookingCancelInfoMoal} from './booking/booking_cancel_info_modal';       //ok
import { EqualValidator} from './utils/directive/MatchPassword';
import { NewPasswordModal} from './user/newPasswordModal';
import { Naver } from 'ionic-plugin-naver';
import { KakaoCordovaSDK } from 'kakao-sdk';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { AppRoutingModule} from './app-routing.module';
import { PipesModule } from './CustomPipe/pipes.modules';
import { AnalyticsFirebase } from '@ionic-native/analytics-firebase/ngx';
import { MyTransitionAnimation } from './utils/transition/myTransition';
//import { FCM } from '@ionic-native/fcm/ngx';
import { UpdPhoneNoModal } from './user/updPhoneNoModal';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';

// import { GoogleAnalyticsUtil} from './utils/GoogleAnalytics';

// locale
import localeKo from '@angular/common/locales/ko';
//import {FirebaseX} from "@awesome-cordova-plugins/firebase-x/ngx";
registerLocaleData(localeKo, 'ko');

@NgModule({
  declarations: [
    MyApp
    , UserNewComplete
    , BookingCompleteModal    
    , GolfSelectModal
    , MypageDetail
    , TrimPipe
    , ViceCoverItem
    , TourDetailImageModal
    , TourRegionPopOver
    , MypageArticleFilterModal
    , BookingCancelInfoMoal
    , EqualValidator
    , NewPasswordModal
    , Notifying
    , UpdPhoneNoModal
  ],
  imports: [
    BrowserModule
    , IonicModule.forRoot({  
      //navAnimation: MyTransitionAnimation
      mode: 'md'
      , scrollAssist: false
    })
    , AppRoutingModule
    , HttpModule 
    , FormsModule
    , ReactiveFormsModule 
    , PipesModule 
  ],
  bootstrap: [MyApp],
  entryComponents: [
    MyApp
    , Notifying
    , GolfSelectModal
    , MypageDetail
    , BookingCompleteModal
    , BookingCancelInfoMoal
    , TourDetailImageModal
    , TourRegionPopOver
    , UpdPhoneNoModal
    , NewPasswordModal
    , MypageArticleFilterModal
  ],
  exports: [RouterModule], 
  providers: [            
            {provide: ErrorHandler, useClass: CustomErrorHandlerWithFabric}
            , {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
            , { provide: LocationStrategy, useClass: HashLocationStrategy }
            , {provide: LOCALE_ID, useValue: "ko"}
            , KakaoCordovaSDK
            , Naver
            , DatePipe, Function
            , AuthService, UserService, CommonService, NetworkUtil
            , Fabric, AppVersion, Device, SplashScreen, StatusBar
            , NativeStorage, Dialogs, Network, Toast, Market
            , Diagnostic, Sim, Keyboard, SMS, Geolocation, SocialSharing, ActionSheet
            , DatePicker, Deeplinks, InAppBrowser, SMS
            //, DatePicker, InAppBrowser, SMS
            , IonApp, AnalyticsFirebase, PushNotification, FirebaseAnalytics
            , MobileAccessibility
            , SignInWithApple
            //, FirebaseX
           ]
})
export class AppModule {
  
}


