import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

// pipes
import { CustomCurrency} from './CurrencyPipe';
import { HttpDomainPipe } from './HttpDomainPipe';
import { SafeHtmlPipe } from './safe-html';
import { TimeSplitePipe } from './TimeSplitePipe';

@NgModule({
  declarations: [
    CustomCurrency
    , SafeHtmlPipe
    , HttpDomainPipe
    , TimeSplitePipe
  ],
  imports: [IonicModule],
  exports: [CustomCurrency, SafeHtmlPipe, HttpDomainPipe, TimeSplitePipe]
})
export class PipesModule {}
