import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingPreloaderService } from './services/preloadingStrategyProvider';

const routes: Routes = [
  { path: '', redirectTo:'home', pathMatch:'full'},
  { path: 'home', loadChildren: './home/home.module#HomePageModule'},
  { path: 'tour/:state', loadChildren: './tour/tour.module#TourPageModule'},
  { path: 'tourdetail/:tempState/:parameter/:pCate', loadChildren: './tour/tour_detail.module#TourDetailPageModule'},
  { path: 'tourdetail', loadChildren: './tour/tour_detail.module#TourDetailPageModule', data: {preload: true}},
  { path: 'tourreservformmodal', loadChildren: './tour/tour_reservation.module#TourReservationPageModule'},
  { path: 'join/:searchType', loadChildren: './join/join.module#JoinPageModule'},    
  { path: 'join/:searchType', loadChildren: './join/join.module#JoinPageModule'},    
  { path: 'joincreate/:writeType/:regType/:reqMenu', loadChildren: './join/join_create.module#JoinCreateModule'},
  { path: 'joindetail/:reqType/:reqMenu/:seq', loadChildren: './join/join_detail.module#JoinDetailModule'},
  { path: 'event', loadChildren: './event/event.module#EventPageModule'},
  { path: 'eventdetail/:seq', loadChildren: './event/event_detail.module#EventDetailPageModule'},
  { path: 'booking', loadChildren: './booking/booking.module#BookingPageModule'},
  { path: 'booking/:joinType', loadChildren: './booking/booking.module#BookingPageModule'},
  { path: 'bookingreserv', loadChildren: './booking/booking_reservation.module#BookingReservationPageModule'},
  { path: 'termcert', loadChildren: './user/termCert.module#TermCertModule'},
  { path: 'login', loadChildren: './user/login.module#LoginPageModule'},
  { path: 'userinfomodal', loadChildren: './user/userInfoModal.module#UserInfoModalModule'},
  { path: 'usernewform', loadChildren: './user/userNewForm.module#UserNewFormModule'},
  { path: 'mypage/:menuId', loadChildren: './mypage/mypage.module#MyPageModule'},
  { path: 'config', loadChildren: './config/config.module#ConfigPageModule'},
  { path: 'custom', loadChildren: './custom/custom.module#CustomPageModule'},
  { path: 'board', loadChildren: './board/board.module#BoardModule'},
  { path: 'boarddetail/:seq', loadChildren: './board/board_detail.module#BoardDetailModule'},
  { path: 'shop/:gs_id', loadChildren: './shop/shop.module#ShopModule'},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, 
    { preloadingStrategy: AppRoutingPreloaderService}
  ) ],  
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
