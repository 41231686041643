import { ApiConfig} from "../services/config";
import { Http, Response} from "@angular/http";
import { map } from 'rxjs/operators';
import { Platform} from "@ionic/angular";
import { Injectable} from '@angular/core';
import { Function} from "../services/function";

@Injectable()
export class PushNotification{

  constructor(public http: Http, public platform: Platform, public functionService: Function){

  }

  registPushToken(params){
    const apiConfig:ApiConfig = new ApiConfig();
    const param_data = apiConfig.makeParameter(params);

    const url = apiConfig.apiObject.device+'/regist';
    const response = this.http.post(url, param_data, apiConfig.options).pipe(map((res: Response) => res.json()));    
  }
}
