import { Device } from '@ionic-native/device/ngx';
import { Component, Renderer, ElementRef, Injectable, ViewEncapsulation } from '@angular/core';
import { NavController, NavParams, Platform, ModalController } from '@ionic/angular';

@Injectable()
@Component({
  selector: 'notifying'
  , templateUrl: 'notifying.html'
  , styleUrls: ['notifying.scss']
  , encapsulation: ViewEncapsulation.None,
})
export class Notifying {

  // Variables
  type: string;
  image: string;
  title: string;
  message: string;
  textOk = 'Ok';
  textCancel = 'Cancel';
  buttons = '1';
  popupMsg: string;
  dtSend: string;

  constructor(public device: Device, public navCtrl: NavController, public navParams: NavParams, public renderer: Renderer, public platform:Platform
            , private modalCtrl: ModalController, private elementRef: ElementRef) {
    this.renderer.setElementClass(this.elementRef.nativeElement, 'my-popup', true);
    this.type = navParams.get('type');
    this.image = navParams.get('image');
    this.title = navParams.get('title');
    this.message = navParams.get('message');
    this.popupMsg = navParams.get('popupMsg');
    this.dtSend = navParams.get('dtSend');

    if (navParams.get('textOk') && navParams.get('textOk') !== '') {
      this.textOk = navParams.get('textOk');
    }
    if (navParams.get('textCancel') && navParams.get('textCancel') !== '') {
      this.textCancel = navParams.get('textCancel');
    }
    if (navParams.get('buttons') && navParams.get('buttons') !== '') {
      this.buttons = navParams.get('buttons');
    }
  }

  ionViewDidLoad() {
    this.platform.ready().then(() => {
      console.log("this.device.platform : " + this.device.platform);
    });
  }

  isConfirm() {
    const data = { 'res': 'yes' }; // param for yes
    //this.navParams.get('isConfirm')(); // exec function
    this.modalCtrl.dismiss(data); // dismiss with data
  }

  isCancel() {
    const data = { 'res': 'no' }; // param for No
    //this.navParams.get('isCancel')(); // exec function
    this.modalCtrl.dismiss(data); // dismiss with data
  }

  showDetail(){
    const data = { 'res': 'clickImage' }; // param for No
    //this.navParams.get('isCancel')(); // exec function
    this.modalCtrl.dismiss(data); // dismiss with data
  }
  
  smsSend() {
    console.log("smsSend");
    const data = {
      'res': 'yes',
      'message': this.message
      }; // param for yes
    console.log("data : " + JSON.stringify(data));
    this.modalCtrl.dismiss(data);
  }

  smsSendCancle() {
    console.log("smsSendCancle");
    const data = { 'res': 'no' }; // param for No
    this.modalCtrl.dismiss(data);
  }
}
