import { Injectable } from '@angular/core';
import {Http, Response} from '@angular/http';
import { Observable } from 'rxjs';
import { timeout, map} from 'rxjs/operators';

import {ApiConfig} from "./config";
import {CommonDataResultModel} from "./model/CommonDataResultModel";

/*
  Generated class for the BookingServeice provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class BookingServeice {
  apiConfig:ApiConfig = new ApiConfig();

  headers:any;
  options:any;

  getBookingTimeListParam = {
    searchTeeupDate: ''
    , searchStateCode: '01' // 기본 한강이남
    , searchTeeupDateEnd: ''
    , searchTimeRangeType: ''
    , searchGolfId: ''
    , sortingType: 'fastT' // 낮은 가격순(minA), 빠른 시간순(fastT), 늦은 시간순(lateT)
    , searchGolfIdList: []
    , searchTeeupTime: ''
    , searchJoinType: '02'
    , deviceType: 'else'
  };

  getBookingTimeListTop5Param = {
    currentDate: ''
    , searchGolfIdList: ''
    , searchJoinType: ''
    , searchStateCode: ''
    , searchTimeRangeType: ''
    , deviceType: 'else'
  };

  constructor(public http: Http) { }

  /**
   * 골프장별/타임순 리스트(골프장별 group by)
   */
  getBookingTimeListForClub(){
    const url = this.apiConfig.apiObject.time+'/getBookingTimeListForClub';
    const response = this.http.post(url, this.getBookingTimeListParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  /**
   * 타임리스트 2뎁스(골프장별/타임순 선택시)
   */
  getBookingTimeList(){
    console.log(this.getBookingTimeListParam);
    const url = this.apiConfig.apiObject.time+'/getRealTimeListV2'; 
    const response = this.http.post(url, this.getBookingTimeListParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    console.log(response);
    return response;
  }

  /**
   * 상단고정 top5
   * @param currentDate 
   */
  getBookingTimeListTop5(currentDate){
    const url = this.apiConfig.apiObject.time+'/getRealTimeListTop5';
    this.getBookingTimeListTop5Param.currentDate = currentDate;
    console.log(this.getBookingTimeListTop5Param);
    const response = this.http.post(url, this.getBookingTimeListTop5Param, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    console.log(response);
    return response;
  }

  getBookingTimeListPaging(getMoreBookingParam){
    const url = this.apiConfig.apiObject.time+'/getRealTimeListV2Paging'; 
    const response = this.http.post(url, getMoreBookingParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  getSearchGolfNSido(params){
    const url = this.apiConfig.apiObject.time+'/getSearchGolfNSido';
    const response = this.http.post(url, params, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;
  }

  getReservationDetailInfo(params){
    const url = this.apiConfig.apiObject.order+'/getReservationDetailInfo';
    const response = this.http.post(url, params, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));

    return response;
  }

  updateUserEmail(params){
    const url = this.apiConfig.apiObject.user+'/updateUserEmail';
    const response = this.http.post(url, params, this.apiConfig.optionsJson).pipe(timeout(5000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));

    return response;
  }

  createReservation(params){
    const url = this.apiConfig.apiObject.order+'/createReservationV2';
    const response = this.http.post(url, params, this.apiConfig.optionsJson).pipe(timeout(5000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));

    return response;
  }

  cancelPayment(params){
    const param_data = this.apiConfig.makeParameter(params);

    const url = this.apiConfig.apiObject.order+'/cancelPaymentV2';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));

    return response;
  }

  createOrder(params){
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.order+'/createOrder';

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));

    return response;
  }

  resultNew(params){
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.order+'/resultNew';

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));

    return response;
  }
}
