/**
 * Created by bbang on 2017-04-12.
 */

import {Pipe, PipeTransform} from "@angular/core";
@Pipe(
  {
    name: 'customCurrency'
  }
)
export class CustomCurrency implements PipeTransform{
  transform(input: number, fractionNumber: number): string {
    const integer:string = input.toFixed(0).replace(/./g, function (c, i, a) {
      return i && c !== "." && ((a.length - i) % 3 === 0) ? ','+c : c;
    });

    return integer;
  }
}
