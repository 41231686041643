/**
 * Created by bbang on 2017-04-13.
 */

import {Pipe, PipeTransform} from "@angular/core";
@Pipe(
  {
    name: 'timeSplitePipe'
  }
)
export class TimeSplitePipe implements PipeTransform{
  transform(input: string): string {
    const time: string = input.slice(0, 2)+' : '+input.slice(2, 4);

    return time;
  }
}
