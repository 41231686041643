import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import {AuthModel} from "./model/user/AuthModel";

@Injectable()
export class AuthService {
  userId = '';
  id = '';
  userInfo:any = '';
  loginYn = false;
  userData:any;
  
  constructor(public http: Http) {
    console.log('Hello AuthService Provider');
    this.userData = new AuthModel();
  }

  setUserId(userIdParam){
    this.userId = userIdParam;
  }

  getUserId(){
    return this.userId;
  }

  clearUserData(){
    this.loginYn = false;
    this.userData = new AuthModel();
  }
}
