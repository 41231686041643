import { Injectable } from '@angular/core';
import {Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { timeout, map } from 'rxjs/operators';
import {ApiConfig} from "./config";

import {CommonDataModel} from "./model/CommonDataModel";
import {CommonMessageModel} from "./model/CommonMessageModel";
import {JoinCreateEditModel} from "./model/join/JoinCreateEditModel";
import {AuthModel} from "./model/user/AuthModel";
import {JoinCommonResultModel} from "./model/join/JoinCommonResultModel";
import {CommonDataResultModel} from "./model/CommonDataResultModel";

/*
  Generated class for the JoinService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class JoinService {
  apiConfig:ApiConfig = new ApiConfig();

  getJoinListParam = {
    searchTeeupDate: '' //검색 첫 날짜
    , searchTeeupDateEnd: '' //검색 끝 날짜
    , state: '' //지역
    , searchType: '' // 전체(00), 조인(01), 양도(02)
    , sortingType: '' // 낮은 가격순(minA), 빠른 시간순(fastT), 늦은 시간순(lateT)
    , searchTimeRangeType: '' // 전체(all), 오전(am), 오후&야간(pm)
  };

  joinDetailParam ={
    seq:0
    , userId:''
    , accuse:false
    , pushSeq:0
  };

  constructor(public http: Http) { }

  handleError(error) {
    console.error(error);
    return Observable.throw(error.json().error || 'Server error');
  }

  /**
   * 조인 메인리스트 가져오기
   */
  getJoinList(){
    const url = this.apiConfig.apiObject.plaza+'/joinList';
    const response = this.http.post(url, this.getJoinListParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  /**
   * 조인 플라자 메인 리스트
   * @returns {Observable<R>}
   */
  getJoinListPaging(getJoinListParam){
    //let param_data = this.apiConfig.makeParameter(this.getJoinListParam);

    const url = this.apiConfig.apiObject.plaza+'/joinListPaging';
    const response = this.http.post(url, getJoinListParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;
  }

  /**
   * 조인 플라자 상세
   * Observable<T> -> data를 받아올 경우에는 CommonDataModel로 mapping을 시켜주고 component단에서 다시 각각 맞는 model로 mapping
   */
  // getJoinDetail(param): Observable<CommonDataModel>{
  getJoinDetail() {
    const param_data = this.apiConfig.makeParameter(this.joinDetailParam);
    const url = this.apiConfig.apiObject.plaza+'/detailNew';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000))
    .pipe(map((res: Response) => 
      res.json() as CommonDataModel)
    );
    
    return response;
  }

  /**
   * 마감처리요청하기
   */
  acceseArticle(param): Observable<CommonMessageModel>{
    const param_data = this.apiConfig.makeParameter(param);
    const url = this.apiConfig.apiObject.plaza+'/getAccuse';

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000))
    .pipe(map((res: Response) => res.json() as CommonMessageModel));
      //.catch(this.handleError);

    return response;
  }

  /**
   * 글 등록 가능 여부 체크
   */
  getAvailableCreateJoinArticle(userData, commonData, writeType){
    const users:AuthModel = userData;
    const param_data = {
      userId: users.user_id
      , user_status: users.user_status
      , jmOriSeq: users.jmOriSeq
      , canJmAddCount: users.canJmAddCount
      , deviceUuid: commonData.uuid
      , writeType: writeType
    };
    console.log(param_data);
    const url = this.apiConfig.apiObject.plaza+'/getAvailableCreateJoinArticle';

    const response = this.http.post(url, param_data, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json() as JoinCommonResultModel));
    //.catch(this.handleError);

    return response;
  }

  /**
   * 글 등록
   */
  createArticle(param, userData){
    const params:JoinCreateEditModel = param;
    const users:AuthModel = userData;

    const _params = {
      pGolfId: params.golf_id+''
      , pTeeupDate: params.teeup_date
      , pTeeupHour: params.teeup_time.substring(0,2)
      , pTeeupMin: params.teeup_time.substring(2,4)
      , pGreenFeeAmount: params.green_fee+''
      , pJoinNumber: params.join_number+''
      , joinType: params.join_type
      , state: params.state
      , userId: params.regUserId
      , content: params.content
      , deviceType: params.deviceType
      , deviceUuid: params.deviceUuid
      , userStatusCode: users.user_status
      , jmOriSeq: users.jmOriSeq
      , jmRegType: users.jmRegType
      , canJmAddCount: users.canJmAddCount
      , genderType: param.genderType+''
      , itsPlazaOptions: param.itsPlazaOptions
    };

    console.log("params.join_number =====> " + _params.pJoinNumber);
    console.log("params.genderType =====> " + _params.genderType);

    //let param_data = this.apiConfig.makeParameter(_params);
    const url = this.apiConfig.apiObject.plaza+'/createJoinArticle';
    const response = this.http.post(url, _params, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json() as JoinCommonResultModel));
    //.catch(this.handleError);

    return response;
  }

  /**
   * 글 수정
   */
  updateArticle(param, userData){
    const params:JoinCreateEditModel = param;
    const users:AuthModel = userData;

    const _params = {
      joinPlazaSeq: params.seq
      , pGolfId: params.golf_id+''
      , pOriTeeupDate: params.ori_teeup_date      
      , pTeeupDate: params.teeup_date
      , pTeeupHour: params.teeup_time.substring(0,2)
      , pTeeupMin: params.teeup_time.substring(2,4)
      , pGreenFeeAmount: params.green_fee+''
      , pJoinNumber: params.join_number+''
      , joinType: params.join_type
      , state: params.state
      , userId: users.user_id
      , content: params.content
      , userStatusCode: users.user_status
      , jmOriSeq: users.jmOriSeq
      , accuse: param.accuse
      , pushSeq: param.pushSeq
      , genderType: param.genderType+''
      , itsPlazaOptions: param.itsPlazaOptions
    };

    //let param_data = this.apiConfig.makeParameter(_params);
    //console.log(param_data);
    const url = this.apiConfig.apiObject.plaza+'/updateV2';
    const response = this.http.post(url, _params, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json() as JoinCommonResultModel));
    //.catch(this.handleError);po0-

    return response;
  }

  /**
   * 등록건수/가능건수 가져오기
   */
   getMyJmInfo(getMyJmInfoParam) {
    const url = this.apiConfig.apiObject.plaza+'/myJmInfo';
    const response = this.http.post(url, getMyJmInfoParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;
   }

  /**
   * 내 글 리스트 가져오기
   */
  getMyArticleList(getMyArticleListParam){
    //let param_data = this.apiConfig.makeParameter(_params);
    const url = this.apiConfig.apiObject.plaza+'/myArticleList';
    const response = this.http.post(url, getMyArticleListParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;
  }

  getMyArticleGolfList(getMyArticleGolfListParam){
    //let param_data = this.apiConfig.makeParameter(_params);
    const url = this.apiConfig.apiObject.plaza+'/getMyArticleGolfList';
    const response = this.http.post(url, getMyArticleGolfListParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));

    return response;
  }

  sendRequestPush(param){
    const param_data = this.apiConfig.makeParameter(param);
    const url = this.apiConfig.apiObject.plaza+'/sendPush';

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));

    return response;
  }

  /**
   * 게시글 클릭시 인덱스 저장(통계성)
   * 응답처리 하지 않음.
   * @param param 
   */
  setClickStat(param) {
    const param_data = this.apiConfig.makeParameter(param);
    const url = this.apiConfig.apiObject.plaza+'/setClickStat';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }
  
  // 내글 전체 on off상태 가져오기
  getMyArticleShowYn(param) {
    const param_data = {
      userId: param
    };
    const url = this.apiConfig.apiObject.mypage+'/getMyArticleShowYn';    
    const response = this.http.post(url, param_data, this.apiConfig.optionsJson).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }
}
