import { Injectable } from '@angular/core';
import {Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { timeout, map } from 'rxjs/operators';
import {ApiConfig} from "./config";
import {CommonDataModel} from "./model/CommonDataModel";
import {CommonDataResultModel} from "./model/CommonDataResultModel";

/*
  Generated class for the TourService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class TourService {
  apiConfig:ApiConfig = new ApiConfig();

  tourMainListParam = {
    state: 'ov_01'//중국이 기본으로 설정됨.
    , province: ''
    , currentPage: 1
    , pCate: ''    
  };

  tourDetailParam ={
    tourProductId: 0
  };

  tourReservationParam ={
    tourProductId : 0
    , orderUserId : ''
    , userName : ''
    , departureDate : new Date()
    , arrivalDate : new Date()
    , contractPerson : 0
    , orderTarget : ''
    , orderMemo : ''
    , phone : ''              // 사용자 핸드폰
    , email : ''
    , userStatusCode : ''
    , payment : ''
    , goodname : ''
    , orderName : ''    
    , state : ''
    , handphone : ''          // 상품등록자 핸드폰
    , regUserId : ''          // 상품등록자 아이디 
    , levelGroupCode : ''
  };

  constructor(public http: Http) {
    console.log('Hello TourService Provider');
  }

  getTourList(){
    console.log(this.tourMainListParam);
    const param_data = this.apiConfig.makeParameter(this.tourMainListParam);
    const url = this.apiConfig.apiObject.tour+'/list';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;
  }

  getTourDetail(){
    const param_data = this.apiConfig.makeParameter(this.tourDetailParam);
    const url = this.apiConfig.apiObject.tour+'/detailV2';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000)).pipe(map((res: Response) => res.json() as CommonDataModel));

    return response;
  }

  /**
   * 투어상품 금액정보 가져오기
   * @param tourProductIdParam 
   */
  getTourPriceList(tourProductIdParam){
    const param_data = this.apiConfig.makeParameter({"tourProductId":tourProductIdParam});
    const url = this.apiConfig.apiObject.tour + '/priceList';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  requestTourReservation(){
    const param_data = this.apiConfig.makeParameter(this.tourReservationParam);
    const url = this.apiConfig.apiObject.tour+'/reserveTourV2';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));

    return response;
  }

  /* 투어 문의 푸시 보내기 */
  sendRequestPush(param){
    const param_data = this.apiConfig.makeParameter(param);
    const url = this.apiConfig.apiObject.tour+'/sendPush';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));

    return response;
  }
}
