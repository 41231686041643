/**
 * Created by bbang on 2017-04-13.
 */
/**
 *  유저 정보 model
 */

export class AuthModel{
  bookingResrvCount:number;
  canJmAddCount:boolean;
  jmAddMaxCount:number;

  jmRegCount:number;
  jmCwRegCount:number;
  jmTranRegCount:number;
  jmTranCwRegCount:number;
  jmUpdRegCount:number;
  jmUpdCwRegCount:number;
  jmUpdTranRegCount:number;
  jmUpdTranCwRegCount:number;
  jmAddRegCount:number;
  jmAddTrRegCount:number;
  jmAddUpdRegCount:number;
  jmAddUpdTrRegCount:number;

  jmOriSeq:number;
  jmRegType:string;
  tourResrvCount:number;
  userEmail:string;
  userHandphone:string;
  user_id:string;
  user_name:string;
  user_status:string;
  user_type:string;
  tourAuthJoin:String;
  tourAuthTran:String;
  tourAuthOversea:String;
  tourmanageryn:String;
  level_group_code:String;
  notificationCount:number;
  jmRegStartDate:String;
  jmRegEndDate:String;
  socialId:String;
  jmArticleShowYn:number;
  loginUserId:String;

  constructor(){
    this.bookingResrvCount = 0;
    this.canJmAddCount= false;
    this.jmAddMaxCount= 0;
    this.jmRegCount = 0;
    this.jmCwRegCount = 0;
    this.jmTranRegCount = 0;
    this.jmTranCwRegCount = 0;
    this.jmUpdRegCount = 0;
    this.jmUpdCwRegCount = 0;
    this.jmUpdTranRegCount = 0;
    this.jmUpdTranCwRegCount = 0;
    this.jmAddRegCount = 0;
    this.jmAddTrRegCount = 0;
    this.jmAddUpdRegCount = 0;
    this.jmAddUpdTrRegCount = 0;
    this.jmOriSeq= 0;
    this.jmRegCount= 0;
    this.jmRegType= "";
    this.tourResrvCount= 0;
    this.userEmail= "";
    this.userHandphone= "";
    this.user_id= "";
    this.user_name= "";
    this.user_status= "";
    this.user_type= "";
    this.notificationCount = 0;
    this.jmRegStartDate= "";
    this.jmRegEndDate= "";
    this.socialId = "";
    this.jmArticleShowYn=0;
    this.loginUserId = "";
  }
}
