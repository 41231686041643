/**
 * Created by kgrid-dev-bbk on 2017-03-20.
 */
import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController} from '@ionic/angular';
import { Router} from '@angular/router';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Notifying} from '../utils/notifying/notifying';
import { AuthService} from "../services/auth-service";

@Injectable()
export class Function {
  androidPermissions:any = [];
  currentMenuId = '';
  loader:any;

  // Platform 모듈로부터 추출할 수 있는 디바이스정보
  platformsArray:string[] = [ 'android', 'cordova', 'ios', 'ipad', 'iphone', 'phablet', 'tablet'
                                  , 'electron', 'pwa', 'mobile', 'mobileweb', 'desktop', 'hybrid'];

  constructor(public alertCtrl: AlertController, public loadingCtrl: LoadingController, public modalCtrl: ModalController
            , public diagnostic: Diagnostic, private router: Router, public authService:AuthService) {

  }
  menuControlFunction(page) {
    this.currentMenuId = page.menuId;
  }

  androidPermissionCheck() {
    this.androidPermissions = [
      // this.diagnostic.permission.READ_SMS
      // , this.diagnostic.permission.SEND_SMS
      // , this.diagnostic.permission.RECEIVE_SMS
      , this.diagnostic.permission.READ_PHONE_STATE
      , this.diagnostic.permission.ACCESS_FINE_LOCATION
      , this.diagnostic.permission.RECEIVE_WAP_PUSH
      , this.diagnostic.permission.READ_CONTACTS
      , this.diagnostic.permission.WRITE_CONTACTS      
      //, this.diagnostic.permission.CALL_PHONE     
    ];
    this.diagnostic.requestRuntimePermissions(this.androidPermissions).then(
      (statuses) => {
        console.log(statuses);
      }, (error) => {
        console.log(error);
      }
    );

    this.androidGPSPermission();
  }
  androidGPSPermission() {
    this.diagnostic.isGpsLocationAvailable().then(
      data => {
        console.log(data);
      }
    );
    this.diagnostic.isGpsLocationEnabled().then(
      data => {
        console.log(data);
      }
    );
    this.diagnostic.isNetworkLocationAvailable().then(
     data => {
        console.log(data);
     }
    );
    this.diagnostic.isNetworkLocationEnabled().then(
      data => {
        console.log(data);
      }
    );
    console.log(this.diagnostic.getLocationAuthorizationStatus());
    console.log(this.diagnostic.getLocationMode());

    //설정화면 열기
    //this.diagnostic.switchToLocationSettings();
  }

   async showLoading(message) {
    //$ionicLoading.show({ template: '<ion-spinner icon="lines" class="spinner-assertive"></ion-spinner><div>처리중</div>' });
    const loader = await this.loadingCtrl.create({
      message: message
    });

    return loader.present();
  }

  async loaderDismiss(){
    return await this.loadingCtrl.dismiss();
  }

  async showAlert(message) {
    const alert = await this.alertCtrl.create({
      header: '알 림'
      , message: message
      , buttons: [
        {
          text: '확인'
          , cssClass: 'btn-type2 flex-item btn-confirm'
        }
      ]
      , cssClass: 'flex-group button-group'
    });

    alert.present();
    const {data} = await alert.onWillDismiss();
    return data;
  }

  showConfirm(message) {
    const confirm = this.alertCtrl.create({
      header : '알 림'
      , message : message
      , backdropDismiss : false
      , buttons: [
        {
          text: '취소'
          , handler:() => {

        }
        }
        , {
          text: '확인'
          , handler:() => {

          }
        }
      ]
    });
    //appUpdateConfirm.present();

    return confirm;
  }

  showShouldLoginConfirm() {
    const confirm = this.alertCtrl.create({
      header : '로그인 안내'
      , message : '해당 기능은 로그인을 하셔야 이용 가능합니다. 로그인 하시겠습니까?'
      , backdropDismiss : false
      , buttons: [
        {
          text: '취소'
          , handler:() => {
          //activeView.instance.confirmCancel();
        }
        }
        , {
          text: '확인'
          , handler:() => {
            //activeView.instance.confirmOkGoLogin();
          }
        }
      ]
    });
    //appUpdateConfirm.present();

    return confirm;
  }

  async showErrorAlert(message) {
    const alert = await this.alertCtrl.create({
      header: '죄송합니다'
      , message: message
      , buttons: ['확인']
    });

    return await alert.present();
  }

  showErrorConfirm() {  }

  /**
   * open Third-party popup library
   */
  async showNotiAlert(message) {
    const notiAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type: 'noti'
          , title: '알 림'
          , message: message
          , textOk: '확인'
        }
      }
    );

    notiAlert.present();
    const {data} = await notiAlert.onWillDismiss();
    return data;
  }

  async showConfirmAlert(message) {
    const confirmAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type: 'confirm'
          , title: '알 림'
          , message: message
          , textOk: '확인'
          , textCancel: '취소'
        }
      }
    );

    confirmAlert.present();
    const {data} = await confirmAlert.onWillDismiss();
    return data;
  }

  async showErrorAlert2(message) {
    const notiAlert = await this.modalCtrl.create({
        component: Notifying
        , componentProps: {
          type: 'noti'
          , title: '알려드립니다'
          , message: message
          , textOk: '확인'
        }
      }
    );

    notiAlert.present();
    const {data} = await notiAlert.onWillDismiss();
    return data;
  }

  async showShouldLoginConfirmAlert() {
    const confirmAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
            type:'confirm'
            , title: '로그인 안내'
            ,  message: '해당 기능은 로그인을 하셔야 이용 가능합니다.<br>로그인 하시겠습니까?'
            , textOk: '확인'
            , textCancel: '취소'
        }
      }
    );

    confirmAlert.present();
    const {data} = await confirmAlert.onWillDismiss();
    return data;
  }

  async showShouldLoginConfirmAlert2(message) {
    const confirmAlert = await this.modalCtrl.create(      
      {
        component: Notifying
        , componentProps: {
          type: 'confirm'
          , title: '로그인 안내'
          , message: message
          , textOk: '확인'
          , textCancel: '취소'
        }
      }
    );

    confirmAlert.present();
    const {data} = await confirmAlert.onWillDismiss();
    return data;
  }

  async showPushPopup(title, message, image) {
    const pushAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type: 'custom'
          , title: title
          , message: message
          , image: image
          , buttons: 2
          , textOk: '보러가기'
          , textCancel: '닫기'
        }
      }
    );

    pushAlert.present();
    const {data} = await pushAlert.onWillDismiss();
    return data;
  }

  async showPushPopupNoLanding(title, message, image) {
    const pushAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type: 'info'
          , title: title
          , message: message
          , image: image
          , buttons: 1
          , textCancel: '닫기'
        }
      }
    );

    pushAlert.present();
    const {data} = await pushAlert.onWillDismiss();
    return data;
  }

  async showPushPopupWithTime(title, message, image, dtSend) {
    const pushAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type: 'custom'
          , title: title
          , message: message
          , image: image
          , buttons: 2
          , textOk: '보러가기'
          , textCancel: '닫기'
          , dtSend: dtSend
        }
      }
    );

    pushAlert.present();
    const {data} = await pushAlert.onWillDismiss();
    return data;
  }

  async showConfirmAlertReservation(title, message) {
    const confirmAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type: 'confirm'
          , title: title
          , message: message
          , textOk: '확인'
          , textCancel: '취소'
        }
      }
    );

    confirmAlert.present();
    const {data} = await confirmAlert.onWillDismiss();
    return data;
  }

  async showAppEndPopup(title, message, image) {
    const pushAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type: 'custom'
          , title: title
          , message: message
          , image: image
          , buttons: 2
          , textOk: '종료할래요'
          , textCancel: '아니요'
        }
      }
    );

    pushAlert.present();
    const {data} = await pushAlert.onWillDismiss();
    return data;
  }

  async showInfoPopup(title, message) {
    const pushAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type: 'info'
          , buttons: 1
          , title:title
          , message: message
          , textCancel: '닫기'
        }
      }
    );

    return await pushAlert.present();
  }

  async showSmsPopup(message) {
    console.log("showSmsPopup");
    console.log("message : " + message);
    const pushAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type:'sms'
          , title: 'SMS 보내기'
          , popupMsg: message
          , buttons: 2
          , textOk: '확인'
          , textCancel: '아니요'
        }        
      }
    );

    pushAlert.present();
    const {data} = await pushAlert.onWillDismiss();
    return data;
  }

  /**
   * 조인/양도 요청용 sms   
   */
  async showSmsPopupForPlaza(message, joinType, popupMsg) {
    console.log("showSmsPopup");
    console.log("message : " + message);
    const pushAlert = await this.modalCtrl.create(
      {
        component: Notifying
        , componentProps: {
          type:'sms'
          , title: '[이츠골프 '+joinType+'요청]'
          , message: message
          , popupMsg: popupMsg
          , buttons: 2
          , textOk: '확인'
          , textCancel: '아니요'
        }        
      }
    );

    pushAlert.present();
    const {data} = await pushAlert.onWillDismiss();
    return data;
  }

  /**
   * 천단위 콤마
   * @param num
   */
  addComma(num) {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
  }

  /**
   * Platform 모듈로부터 플랫폼 이름 가져오기
   * @param platform 
   */
  getPlatformName(platform) {
    let platformName = '';

    for(let i=0; i<this.platformsArray.length; i++) {
      if(platform.is(this.platformsArray[i])) {
        platformName = this.platformsArray[i];
      }
    }

    return platformName;
  }

  // 넘어온 파라미터가 json형태인지 확인.
  jsonTypeCheck(str) {
    try {
      JSON.parse(str);
    } catch(e) {
      return false;
    }
    return true;
  }

  /**
   * 문자열 자르기
   * @param str 
   * @param len 
   */
  cutStrLen(str, len) {
    let s = 0;
    for (let i=0; i<str.length; i++) {
      // s += (str.charCodeAt(i) > 128) ? 2 : 1;
      s += this.returnByte(str.charCodeAt(i));
      if (s > len) return str.substring(0,i) + "...";
    } 

    // console.log(str + " ==> " + s);
    return str;
  } 

  /**
   * char code에 따른 바이트 계산
   * @param charCode 
   */
  returnByte(charCode) {
    // 한글은 3byte.
    if( 
      (charCode >= 0x1100 && charCode <= 0x11FF) || 
      (charCode >= 0x3130 && charCode <= 0x318F) || 
      (charCode >= 0xAC00 && charCode <= 0xD7A3)
    ) {
      return 3;
    } else {
      return 1;
    }
  }  
}
