import {TourService} from "../services/tour-service";
import {Component} from "@angular/core";
import {ModalController, NavController, NavParams} from "@ionic/angular";
/**
 * Created by bbang on 2017-04-19.
 */

@Component({
  selector: 'page-tour'
  , templateUrl: 'tour_detail_image_modal.html'
  , providers: [TourService]
  , styleUrls: ['tour.scss']
})
export class TourDetailImageModal {
  imageDetailPath:string;

  constructor(public navCtrl: NavController, public navParams: NavParams, public modalCtrl: ModalController) {
    this.init();
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

  init(){
    this.imageDetailPath = this.navParams.get('imageDetailPath');
  }
}
