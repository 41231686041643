import {Headers, RequestOptions} from '@angular/http';

export class ApiConfig { 

  // ################## mobile API ##################
  baseUrl = 'https://mapp.itsgolf.co.kr'; // prd
  //baseUrl = 'https://mtest.itsgolf.co.kr:8444'; // dev
  //baseUrl = 'http://121.78.32.30:8085'; // lwj 
  //baseUrl = 'http://121.78.32.37:8085'; // kgu

  // ################## User Web ##################
  pcWebUrl = 'https://www.itsgolf.co.kr'; // prd
  //pcWebUrl = 'https://test.itsgolf.co.kr'; // dev
  //pcWebUrl = 'http://121.78.32.30:8180';  // lwj
  //pcWebUrl = 'http://121.78.32.37:8280';  // kgu

  apiObject:any;
  headers:any;
  options:any;
  headersJson:any;
  optionsJson:any; 

  constructor() {
    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.options = new RequestOptions({headers: this.headers});

    this.headersJson = new Headers();
    this.headersJson.append('Accept', 'application/json');
    this.headersJson.append('Content-Type', 'application/json');
    this.optionsJson = new RequestOptions({headers: this.headersJson});

    this.apiObject = {
      base: this.baseUrl
      , pcWeb: this.pcWebUrl
      , device: this.baseUrl + '/device'
      //// 검색 팝업 항목 (공통코드)
      , comm: this.baseUrl + '/comm/list'
      //// 사용자
      , login: this.baseUrl + '/login'
      , user: this.baseUrl + '/member'
      //// 부킹 티업타임
      , time: this.baseUrl + '/time'
      //// 부킹 상세
      , order: this.baseUrl + '/order'
      //// 조인플라자
      , plaza: this.baseUrl + '/plaza'
      //// 공지/FAQ
      , board: this.baseUrl + '/board'
      //// 이벤트
      , events: this.baseUrl + '/event'
      //// 마이페이지
      , mypage: this.baseUrl + '/mypage'
      //// 이니패이 결제
      /*, showPG: this.baseUrl.replace('http', 'intent') + '/order/order.showPG'*/
      , showPG: this.baseUrl + '/order/order.showPG'
      , inipay: 'https://mobile.inicis.com/smart'
      //// 투어
      , tour: this.baseUrl + '/tour'
      ////공통 사용 url
      , common: this.baseUrl + '/comm'
      ////공통 사용 url
      , home: this.baseUrl + '/home'      
    };
  }

  makeParameter(paramData) {
    return Object.keys(paramData).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]);
    }).join('&');
  }
}
