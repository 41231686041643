import {
  ModalController, NavController, LoadingController, NavParams, Platform
} from '@ionic/angular';
import {Component} from '@angular/core';
import {BookingServeice} from '../services/booking-serveice';
import {UserService} from '../services/user-service';
import {Function} from '../services/function';
import {ApiConfig} from '../services/config';
import {AuthService} from '../services/auth-service';
import {OrderResultModel} from '../services/model/booking/OrderResultModel';
import {OrderDetailModel} from '../services/model/booking/OrderDetailModel';
import {CommonService} from '../services/common-service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AnalyticsFirebase} from '@ionic-native/analytics-firebase/ngx';

@Component({
  selector: 'page-booking'
  , templateUrl: 'booking_complete_modal.html'
  , providers: [BookingServeice]
  , styleUrls: [ 'booking.scss' ]
})
export class BookingCompleteModal {
  resultData: OrderResultModel;
  orderDetailModel: OrderDetailModel;

  apiConfig: ApiConfig = new ApiConfig();

  // 상단 탭 변수
  tabType: string;
  option = { maximumAge: 3000, timeout: 5000, enableHighAccuracy: true };
  private loaderActive = false;

  constructor(public platform: Platform, public navCtrl: NavController, public navParams: NavParams
    , public userService: UserService, public bookingServ: BookingServeice
    , public functionService: Function, public modalCtrl: ModalController, public authService: AuthService
    , public commonService: CommonService, private geolocation: Geolocation, private socialSharing: SocialSharing
    , private device: Device, private loadingCtrl: LoadingController, private analyticsFirebase: AnalyticsFirebase) {
    this.init();
    this.tabType = 'userInfo';
    this.resultData = this.navParams.get('resultData');
    this.orderDetailModel = this.navParams.get('orderDetailModel');
  }

  init() {
    this.platform.ready().then(() => {
      this.analyticsFirebase.setCurrentScreen('BookingCompleteModal')
        .then(() => console.log('View successfully tracked'))
        .catch(err => console.log('Error tracking view:', err));
    });
  }

  changeTab(type) {
    this.tabType = type;
  }

  policyMessage() {
    if (this.resultData.order_type === '01') {
      return '% 반환';
    } else {
      return ' 포인트 차감';
    }
  }

  goToHome() {
    this.modalCtrl.dismiss();
  }

  reserv_cancel() {
    if (this.orderDetailModel.cancel_yn === 'N') {
      this.functionService.showNotiAlert('해당 타임은 임박 타임이기 때문에 취소가 불가능합니다.');      
    } else {
      this.functionService.showConfirmAlertReservation('예약 취소 전 꼭 확인해 주세요',
                            '취소완료와 동시에<br>해당골프장 예약이 자동 취소됩니다.<br>예약을 취소하시겠습니까?')
      .then((data) =>{
        if(data) {
          if (data.res === 'yes') {
            this.showLoader('예약 취소중...');
            
            const params = {
              orderId: this.resultData.order_id
              , loginUserId: this.userService.loginNappDataParam.loginId
              , userStatusCode: this.authService.userData.user_status
              , userId: this.authService.userData.user_id
            };

            this.bookingServ.cancelPayment(params).subscribe(
              data2 => {
                if (typeof data2.message !== 'undefined') {
                  this.authService.userData.bookingResrvCount = data2.data;
                  this.functionService.showNotiAlert(data2.message).then((rd) => {
                    if(rd.res === 'yes') {
                      setTimeout(() => {
                        this.modalCtrl.dismiss();
                      }, 300);
                    }
                  });
                } else {
                  this.functionService.showNotiAlert(data2.error).then((rd) => {
                    if(rd.res === 'yes') {
                      setTimeout(() => {
                        this.modalCtrl.dismiss();
                      }, 300);
                    }
                  });                
                }

                setTimeout(() => {
                  this.dismissLoader();
                }, 100);
              }, error => {
                console.log(error);
                setTimeout(() => {
                  this.dismissLoader();
                }, 100);
              }
            );
          }
        }
      });
    }
  }

  /**
   * 공유하기
   * @param joinDetailModel
   */
  share() {
    let sharingMsg = '[이츠골프] [' + this.orderDetailModel.state_name + ' ' + this.orderDetailModel.sido_name + '] ' + this.orderDetailModel.golf_name
                     + '\n날짜 : ' + this.orderDetailModel.teeup_date+ '\n티타임 : ' + this.orderDetailModel.teeup_time;
    sharingMsg += '\n그린피 : ' + this.orderDetailModel.discount_amount+'원 X ' + this.orderDetailModel.teeup_number + '인';
    sharingMsg += '\n\n<이츠골프 앱 다운>\n구글마켓 : http://me2.do/xmGPMJYh\n앱스토어(아이폰) : http://me2.do/FC1B28dv';

    if ( (this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== 'browser') {
      this.socialSharing.share(sharingMsg, '', '', '').then(() => {
        this.shareReservation();
      }).catch(() => {
        console.log('social sharing error');
      });
    } else {
      this.shareReservation();
    }
  }

  shareReservation() {
    // this.functionServ.androidGPSPermission();
    // device gps
    this.geolocation.getCurrentPosition(this.option).then((result) => {
      this.commonService.eventHistoryParam.deviceLatitude = result.coords.latitude + '';
      this.commonService.eventHistoryParam.deviceLongitude = result.coords.longitude + '';
      this.commonService.eventHistoryParam.deviceAccuracy = result.coords.accuracy + '';
    }).catch((error) => {
      const errorMsg = 'category:shareReservation/id:'+ this.userService.loginNappDataParam.loginId+'/appVersion:'+this.userService.loginNappDataParam.appVersion
        +'/deviceModel:'+this.userService.loginNappDataParam.deviceModel+'/deviceOsType:'+this.userService.loginNappDataParam.deviceOsType+'/deviceVersion'+this.userService.loginNappDataParam.deviceVersion
        +'/errorType:'+error.code+'/errorMsg:'+error.message;
      // this.gaUtil.gaTrackExceptionError(errorMsg);
    });

    this.commonService.storeEventHistory('CSH', 'BOS', this.resultData.order_id);
  }

  // 쇼로딩
  async showLoader(message) {
    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  }
}
