import {Component} from "@angular/core";
import {JoinService} from "../services/join-service";
import {LoadingController, ModalController, NavController, NavParams, Platform} from "@ionic/angular";
import {CommonService} from "../services/common-service";
import {Function} from "../services/function";
import {AuthService} from "../services/auth-service";
import {MyArticleGolfListModel} from "../services/model/mypage/MyArticleGolfListModel";
import { DatePicker} from "@ionic-native/date-picker/ngx";
import { DatePipe} from "@angular/common";
import { Device } from '@ionic-native/device/ngx';

/**
 * Created by bbang on 2017-04-25.
 */

@Component({
  selector: 'page-mypage',
  templateUrl: 'mypage_article_filter_modal.html',
  providers: [JoinService]
  , styleUrls: [ 'mypage.scss' ]
})
export class MypageArticleFilterModal {
  areaCodeList:any;
  regedGolfList:MyArticleGolfListModel[];
  isPlatformMobile:any = '';

  golfListOpt:{ title: string, subTitle: string };

  filterModel = {
    userId: ''
    , searchJoinType: ''
    , searchState: '00'
    , searchGolfIdList: []
    , userStatusCode: ''
    , searchTeeupDate: ''
  };

  // Initialize loader variable
  private loader: any;
  private loaderActive = false;

  constructor(public platform: Platform, public navCtrl: NavController, public navParams: NavParams
            , public loadingCtrl: LoadingController, public modalCtrl: ModalController
            , public joinService: JoinService, public commService: CommonService, public functionService: Function
            , public authService: AuthService, private datePicker: DatePicker, private datepipe:DatePipe
            , public device:Device)
  {
    this.areaCodeList = this.navParams.get('areaCodeList');
    this.filterModel = this.navParams.get('filterModel');

    console.log(this.filterModel);

    this.golfListOpt = {
      title: '등록한 골프장'
      , subTitle: ''
    };
    this.getUserPlatform();
    this.getMyArticleGolfList();
  }

  dismiss(){
    this.modalCtrl.dismiss({resetYn: 'close'});
  }

  getUserPlatform() {
    this.isPlatformMobile = ( (this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== "browser") ;
  }

  completeFilter(type){
    switch (type){
      case 'close':{
        this.modalCtrl.dismiss({resetYn: 'close'});
        break;
      }
      case 'reset':{
        this.filterModel.searchJoinType = '';
        this.filterModel.searchState = '00';
        this.filterModel.searchGolfIdList = [];
        this.filterModel.searchTeeupDate = '';
        break;
      }
      case 'search':{
        //console.log(this.filterModel);
        setTimeout(() => {
          this.modalCtrl.dismiss({resetYn: 'reset', filterModel: this.filterModel});
        }, 100);
        break;
      }
    }
  }

  getMyArticleGolfList(){
    this.showLoader('검색중...');
    
    this.filterModel.userId = this.authService.userData.user_id;
    this.filterModel.userStatusCode = this.authService.userData.user_status;

    this.joinService.getMyArticleGolfList(this.filterModel).subscribe(
      data => {
        if(data.resultCode === 'ok'){
          this.regedGolfList = data.resultList;
        }else{
          this.functionService.showErrorAlert(data.error);
        }
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }, error => {
        console.log(error);
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    );
  }

  //날짜 선택
  showDatePicker(){
    console.log("showDatePicker()");
    const toDate = new Date();
    this.datePicker.show({
      date:  new Date()
      , minDate: new Date().getTime()
      , maxDate: new Date(toDate.setMonth(toDate.getMonth()+3)).getTime()
      , mode: 'date'
      , titleText: '티업 날짜를 선택하세요'
      , androidTheme: this.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT
      , okText: '선택'
      , cancelText: '취소'
    }).then(
      date => {
      console.log("date =>");
    if(date != null && typeof date !== 'undefined'){
      console.log("if(date != null && typeof date != 'undefined')");
          this.filterModel.searchTeeupDate = this.datepipe.transform(date, 'yyyy-MM-dd');          
    }
      }, err => {
        console.error("err =>");        
      }
    );
  }

  // 쇼로딩
  async showLoader(message) {
    // 모달
    try {
      const el = await this.loadingCtrl.getTop();
      if(el) {
        el.dismiss();        
      }
    } catch(error){}

    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  } 
}
