import {
  LoadingController, ModalController, NavController, Platform
} from "@ionic/angular";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { JoinService } from "../services/join-service";
import { CommonService } from "../services/common-service";
import { AuthService } from "../services/auth-service";
import { CommonGolfList } from "../services/model/CommonGolfList";

@Component({
  selector: 'page-join',
  templateUrl: 'golf_list_modal.html',
  providers: [JoinService], 
  styleUrls: ['join.scss']
})

export class GolfSelectModal {
  /*상단 지역 변수*/
  areaCodeList:any;
  golfList:CommonGolfList[];

  private loaderActive = false;

  constructor(public platform: Platform, public navCtrl: NavController, public loadingCtrl: LoadingController
            , public modalCtrl: ModalController, public joinServ: JoinService, public commServ: CommonService
            , public authServ: AuthService, public router:ActivatedRoute)
  {
    //상단 지역코드
    this.areaCodeList = this.router.snapshot.paramMap.get('areaCodeList');
    console.log(this.areaCodeList);
    this.commServ.golfListParam.searchState = '00';
    this.commServ.golfListParam.searchUserId = this.authServ.userData.user_id;
    this.getGolfList();
  }

  dismiss(golfId, golfName, state, stateName){
    this.modalCtrl.dismiss({golfId: golfId, golfName: golfName, state: state, stateName: stateName});
  }

  getGolfList(){
    

    this.showLoader('리스트 가져오는 중...');
    
    this.commServ.getGolfListCommon().subscribe(
      data => {
        console.log(data);
        this.golfList = data.list;
      }, error => {
        console.log(error);

        this.dismissLoader();
      }
      , () => {
        this.dismissLoader();
      }
    );
  }

  selectGolf(golfId, golfName, state, index){
    console.log(golfId);
    let stateName = '';
    switch(state){
      case '01':{
        stateName = '한강이남';
        break;
      }
      case '02':{
        stateName = '한강이북';
        break;
      }
      case '03':{
        stateName = '강원권';
        break;
      }
      case '04':{
        stateName = '충청전라';
        break;
      }
    }
    this.dismiss(golfId, golfName, state, stateName);
  }

  // 쇼로딩
  async showLoader(message) {
    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  }
}
