import { Injectable } from '@angular/core';
import {Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import {ApiConfig} from "./config";
import {CommonDataResultModel} from "./model/CommonDataResultModel";
import {MyTourReserveDataModel} from "./model/mypage/MyTourReserveDataModel";
import {AuthModel} from "./model/user/AuthModel";

/*
  Generated class for the MyPage provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class MyService {
  apiConfig:ApiConfig = new ApiConfig();

  getBookingReservListParam = {
    userId: ''
    , currentPage: 1
  };

  getTourReservListParam = {
    userId: ''
    , currentPage: 1
    , tmType: ''
  };

  getNotisListParam = {

  };

  constructor(public http: Http) {
    console.log('Hello MyPage Provider');
  }

  getMyBookingReservationList(listTabType){
    const param_data = this.apiConfig.makeParameter(this.getBookingReservListParam);
    let url = this.apiConfig.apiObject.mypage;
    if(listTabType === 'ing'){
      url += '/list.orderIng';
    }else{
      url += '/list.order';
    }

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  getMyTourReservationList(listTabType){
    const param_data = this.apiConfig.makeParameter(this.getTourReservListParam);
    let url = this.apiConfig.apiObject.mypage;
    if(listTabType === 'ing'){
      url += '/list.tourIng';
    }else{
      url += '/list.tour';
    }

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  updateMyArticleStatus(updType, param){
    const param_data = this.apiConfig.makeParameter(param);
    let url = this.apiConfig.apiObject.plaza;
    if(updType === 'del'){
      url += '/delete';
    }else{
      url += '/status';
    }

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  getMyBookingDetail(param){
    const param_data = this.apiConfig.makeParameter(param);
    const url = this.apiConfig.apiObject.mypage+'/detail.orderNew';

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  getMyTourDetail(param){
    const param_data = this.apiConfig.makeParameter(param);
    const url = this.apiConfig.apiObject.mypage+'/detail.tour';

    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  getNotificationList(param){
    const url = this.apiConfig.apiObject.mypage+'/getNotificationList';
    const response = this.http.post(url, param, this.apiConfig.optionsJson).pipe(timeout(5000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));
    return response;
  }

  getNotificationDetailAccessCheck(param){
    const url = this.apiConfig.apiObject.mypage+'/getNotificationDetailAccessCheck';
    const response = this.http.post(url, param, this.apiConfig.optionsJson).pipe(timeout(5000)).pipe(map((res: Response) => res.json() as CommonDataResultModel));

    return response;
  }

  // 투어매니저용 투어신청서 상세내용 저장
  saveTourApplDetail(param, userData) {
    const users:AuthModel = userData;

    const _params = {
      itsTourOrderId: param.itsTourOrderId
      , orderPrice: param.orderPrice
      , orderCostPrice: param.orderCostPrice
      , itsDepartureDate: param.itsDepartureDate
      , itsArrivalDate: param.itsArrivalDate
      , itsContractPerson: param.itsContractPerson
      , itsOrderStatus: param.itsOrderStatus      
      , regUserId: users.user_id
    };

    const url = this.apiConfig.apiObject.mypage+'/saveTourApplDetail';
    const response = this.http.post(url, _params, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  
  // 내글 전체 on off 상태변경
  updateMyArticleViewStatus(param) {
    const param_data = this.apiConfig.makeParameter(param);
    const url = this.apiConfig.apiObject.plaza+'/updateMyArticleViewStatus';    
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

}
