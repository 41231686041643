import { Injectable } from '@angular/core';
import { Http, Response} from '@angular/http';
import { timeout, map } from 'rxjs/operators';
import { ApiConfig } from './config';

/*
  Generated class for the HomeService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class HomeService {
  apiConfig:ApiConfig = new ApiConfig();

  headers:any;
  options:any;

  getMainTimeListParam = {
    searchTeeupDate: ''
    , searchTeeupDateEnd: ''
    , searchTimeRangeType: ''
    , searchStateCode: ''
    , searchGolfIdList: []
    , searchJoinType: '02'
    , screenWidth: ''
  };

  constructor(public http: Http) {

  }

  /* 메인페이지 타임테이블 가져오기 */
  getMainPageDatas() {
    const url = this.apiConfig.apiObject.home+'/mainPageDatas';
    const response = this.http.post(url, this.getMainTimeListParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  /* 메인페이지 투어리스트 가져오기 */
  getMainTourList(param) {
    this.getMainTimeListParam.screenWidth = param;

    const url = this.apiConfig.apiObject.home+'/mainTourList';
    const response = this.http.post(url, this.getMainTimeListParam, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  /* 이벤트 리스트 가져오기 */
  getEventListForHome() {
    const url = this.apiConfig.apiObject.home+'/getEventListForHome';
    const response = this.http.post(url, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res:Response) => res.json()));

    return response;
  }

  /* 메인 골프쇼핑 추천상품 가져오기(from 메이저월드) */
  getShopMainList() {
    const url = "https://itsgolf.killdeal.co.kr/its_recommend_good.php";
    const response = this.http.post(url, this.apiConfig.optionsJson).pipe(timeout(10000)).pipe(map((res:Response) => res.json()));
    return response;
  }
}
