import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import {Observable} from 'rxjs';
import {timeout, map} from 'rxjs/operators';
import { ApiConfig } from './config';

/*
  Generated class for the UserService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class UserService {
  apiConfig:ApiConfig = new ApiConfig();

  userData = {
    userId : ''
    , loginUserId : ''
    , loginStatus : false
  };

  loginNappDataParam ={
    loginId: ''
    , loginPwd: ''
    , uuid: ''
    , deviceModel: ''
    , deviceVersion: ''
    , deviceOsType: ''
    , deviceInfoYn: true
    , loginYn: false
    , appVersion: ''
    , referrer: ''
    , token: ''
    , socialLogin : false
    , socialId : ''
  };

  constructor(public http: Http) {
    console.log('Hello UserService Provider');
  }

  appInit() {
    //앱 실행 시 혹은 resume 시 앱에서 기본적으로 필요한 데이터와 로그인을 처리하는 API 호출
    const url = this.apiConfig.apiObject.login+'/getAppInitDataLogin';
    console.log(this.loginNappDataParam);
    const response = this.http.post(url, this.loginNappDataParam, this.apiConfig.optionsJson).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  userLogin() {
    //로그인 API 호출
  }

  getConfirmNo(params) {
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/addNumber';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  confirmNumber(params) {
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/confirmNumber';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  //addJoinNew
  addJoinNew(params) {
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/addJoinNew';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  findId(params) {
    /**
     * userName: user_name
     , handphone: handphone
     , email: email
     , findType: ('undefined' == typeof isPhone || isPhone ? 'handphone' : 'email')
     */
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/getMemberIdConfirm';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  resetPwdConfirm(params) {
    /**
     * loginUserId: login_id
     , userName: user_name
     , handphone: handphone
     , email: email
     //, findType: ('undefined' == typeof isPhone || isPhone ? 'handphone' : 'email')
     , findType: isPhone
     */
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/getMemberPasswdSend';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  updatePasswd(params) {
    /**
     * loginUserId: login_id
     , newPasswd: login_pwd
     */
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/editMemberPasswdReset';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  getTerm(params) {
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/getTerm';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  checkLoginUserId(params) {
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/getIdChk';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  deleteUser(params) {
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/deleteUser';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  updatePhoneNumber(params) {
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/updatePhoneNumber';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }

  // Check AppleIdSignIn token already saved or not
  checkAppleUserExist(params) {
    const param_data = this.apiConfig.makeParameter(params);
    const url = this.apiConfig.apiObject.user+'/checkAppleUserExist';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(5000)).pipe(map((res: Response) => res.json()));
    return response;
  }
}
