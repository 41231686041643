import {NavController, ModalController, NavParams, Platform} from "@ionic/angular";
import {Component} from "@angular/core";
import {Toast} from "@ionic-native/toast/ngx";
import {AuthModel} from "../services/model/user/AuthModel";
import {AuthService} from "../services/auth-service";
import {CommonService} from "../services/common-service";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import {UserService} from "../services/user-service";
// import {GoogleAnalyticsUtil} from "../utils/GoogleAnalytics";

/**
 * Created by bbang on 2017-04-06.
 */

@Component({
  selector: 'page-user',
  templateUrl: 'userNewComplete.html'
})
export class UserNewComplete {

  userData:AuthModel;

  option= { maximumAge: 3000, timeout: 5000, enableHighAccuracy: true };

  constructor(public navCtrl: NavController, public navParams: NavParams, public platform: Platform, public authService: AuthService
            , public commonService: CommonService, private geolocation: Geolocation, public userService: UserService
            , private modalCtrl: ModalController) {
    this.init();
    this.userData = this.authService.userData;
  }

  init(){
    //this.functionServ.androidGPSPermission();
    //device gps
    this.geolocation.getCurrentPosition(this.option).then((result) => {
      this.commonService.eventHistoryParam.deviceLatitude = result.coords.latitude+'';
      this.commonService.eventHistoryParam.deviceLongitude = result.coords.longitude+'';
      this.commonService.eventHistoryParam.deviceAccuracy = result.coords.accuracy+'';
    }).catch((error) => {
      const errorMsg = 'category:getCurrentPosition/id:'+ this.userService.loginNappDataParam.loginId+'/appVersion:'+this.userService.loginNappDataParam.appVersion
        +'/deviceModel:'+this.userService.loginNappDataParam.deviceModel+'/deviceOsType:'+this.userService.loginNappDataParam.deviceOsType+'/deviceVersion'+this.userService.loginNappDataParam.deviceVersion
        +'/errorType:'+error.code+'/errorMsg:'+error.message;
      // this.gaUtil.gaTrackExceptionError(errorMsg);
    });

    this.commonService.storeEventHistory('UAS', 'UJS', 0);
  }

  goToHome() {
    this.modalCtrl.dismiss();
  }
}
