import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
// import * as stacktrace from 'stacktrace-js';

/*
  Generated class for the Fabric provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class Fabric {
  // private fabricHandler:any;

  constructor(public platform: Platform) {
    /*platform.ready().then(
      () => this.setup()
    )*/
  }

  setup() {
    if (!(<any>window).fabric) {
      console.log('can not use fabric');
      return;
    }
    console.log('can user fabric');
    // this.fabricHandler = (<any>window).fabric;
  }

  sendNonFatalCrash(message: string, customTrace?: any) {
    /*stacktrace.get().then(
      trace => customTrace = trace
    );*/

    if (!(<any>window).fabric) {
      console.warn('fabric not available: sendNonFatalCrash', message, customTrace);
      return;
    }



    console.log('message : ' + message);
    console.log('stacktrace: ' + customTrace);
    (<any>window).fabric.Crashlytics.addLog(message);
    (<any>window).fabric.Crashlytics.sendNonFatalCrash(message, customTrace);
  }
}
