import { Component, ViewChild, ViewEncapsulation, Renderer, ApplicationRef, ElementRef } from '@angular/core';
import {
  NavController, Platform, AlertController, IonContent, IonSlides,
  LoadingController, IonSearchbar, MenuController, IonRefresher
} from '@ionic/angular';
import { Toast } from '@ionic-native/toast/ngx';
import { Market } from '@ionic-native/market/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';

//Util Group
import { CommonService } from '../services/common-service';
import { HomeService } from "../services/home-service";
import { AuthService } from "../services/auth-service";
import { Function } from "../services/function";
import { BookingServeice } from "../services/booking-serveice";
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { ApiConfig} from "../services/config";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics/ngx";

import {KeyValue} from '@angular/common';

declare var Kakao:any;
declare var naver_id_login:any;

@Component({
  selector: 'page-home'
  , templateUrl: 'home.html'
  , providers: [ HomeService, BookingServeice ]
  , styleUrls: ['home.scss']
  //, encapsulation: ViewEncapsulation.None
  // 특별히 동적 css를 할당할 일이 없는이상 사용하지 않는다
})
export class HomePage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('slideForEvent') slideForEvent: IonSlides;
  @ViewChild('SlideForDate') slideForDates: IonSlides;
  @ViewChild(IonSearchbar) searchbar:IonSearchbar;
  @ViewChild("refresherRef") refresherRef: IonRefresher;
  @ViewChild('underlineRef') underline: ElementRef;
  checkUpdateCall = true;

  tabsPages: Array<{title: string, component: any, menuId: string}>;
  toJoParam: {title: string, component: any, menuId: string};
  toEvParam: {title: string, component: any, menuId: string};

  /*상단 날짜 변수*/
  dateListInfo:any;
  dateListForSearch:any;//4주치 날짜 리스트
  perWeekList:any;//1주일치 날자 리스트
  currentSlideIdx = 0;//몇주차인지를 알기 위함. (slide용)
  currRollIdx = 0;
  selectDate:string;//현재 선택된 날짜
  pageIndex:any;

  /* 검색 조건 */
  timeRangeArray = [{type: 'all', name: '전체'}, {type: 'am', name: '오전'}, {type: 'pm', name: '오후&야간'}];
  mainTimeList:any = [];
  mainTimeListPerDate:any;

  /* 메인투어리스트 객체 */
  mainTourListDo:any = [];
  mainTourListOv:any = [];
  mainTourListDoPerState:any = [];
  mainTourListOvPerState:any = [];
  selectDoState = "do_03";
  selectOvState = "ov_01";

  /* 이벤트 리스트 */
  eventListForHome:any = [];
  eventListCount = 0;

  searchingValue = '';
  toggled = false;
  slideChangeEventPrevent = false;

  selectIndex = -1;

  callNaverLoginApi:any;

  stateCode:any;

  // loader
  searchbarSearchLoader:any;

  apiConfig:ApiConfig = new ApiConfig();

  // 슬라이드 옵션
  slideOpts = {
    initialSlide: this.currentSlideIdx
  };

  // 이벤트 롤링 슬라이드 옵션
  slideOptsForEvent = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 2000
    },
    height: 90, 
    loop: false,    
    zoom: false
  };

  // Initialize loader variable
  private loaderActive = false;

  // 메인 골프쇼핑 노출 리스트
  mainShopProdt: any;

  constructor(public platform: Platform, public navCtrl: NavController, public alertCtrl: AlertController, public menuCtrl: MenuController
              , public commServ: CommonService, public homeServ: HomeService, public loadingCtrl: LoadingController, public authService: AuthService
              , public functionService: Function, public bookingService: BookingServeice, private router: ActivatedRoute
              , private market: Market, private toast: Toast, public keyboard: Keyboard
              , public renderer: Renderer, private app: ApplicationRef, private firebaseAnalytics:FirebaseAnalytics) {
    this.tabsPages = [
      {title: '홈', component: '/home', menuId: 'main'}
      , { title: '부킹센터', component: '/booking', menuId: 'booking'}
      , { title: '제주골프', component: '/tour', menuId: 'tour3'}
      , { title: '국내투어', component: '/tour', menuId: 'tour1'}
      , { title: '해외골프', component: '/tour', menuId: 'tour2'}
      , { title: '쇼핑', component: '/shop/0', menuId: 'shop'}
      // , { title: '이벤트', component: '/event', menuId: 'event'}
    ];

    this.init();
    
  }

  orderbyValueAsc = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.value > b.value ? -1 : (a.value > b.value) ? 0 : 1  
  }

  // 탭메뉴 클릭 페이지이동
  openPage(page) {
    // this.gaUtil.gaTrackEvent('menu', 'bottonTab', page.title, 0);
    if(page.menuId === "tour1") {
      this.navCtrl.navigateRoot(page.component + '/do');      
    } else if(page.menuId === "tour3") {
      this.navCtrl.navigateRoot(page.component + '/do_08');      
    } else if(page.menuId === "tour2") {
      this.navCtrl.navigateRoot(page.component + '/ov');      
    } else if (page.menuId === "joinplaza") {
      this.navCtrl.navigateRoot(page.component + '/01');
    } else {
      this.navCtrl.navigateRoot(page.component);  
    }
    this.functionService.currentMenuId = page.menuId;
  }

  ionViewWillLoad() {
    console.log("ionViewWillLoad");    
  }

  ngAfterViewInit(){}
      
  ngOnInit() {
    // if(this.platform.is('ios') || this.platform.is('android')) {
    //   this.firebaseAnalytics.logEvent('home screen view', {});
    // }
    
    this.pageIndex = 0;
    console.log("[home.ts] ngOnInit()");
    //최초 진입시 로드가 된다.
    this.stateCode =  this.router.snapshot.paramMap.get('stateCode');
    this.selectDate = this.router.snapshot.paramMap.get('selectDate');
    if (this.stateCode === undefined || this.stateCode === null) {
      this.stateCode = "00";
    }
    if (this.selectDate !== undefined && this.selectDate !== null) {
      this.selectDate = this.selectDate;
    }

    this.refreshPage(this.stateCode, this.selectDate);          
  }

  ionViewWillLeave() {
    console.log("ionViewWillLeave");
    if(typeof this.slideForEvent !== "undefined")
       this.slideForEvent.stopAutoplay();    
  }

  ionViewWillEnter(){
    //접근할때마다 호출됨.     
    console.log("ionViewWillEnter");
    console.log("typeof this.slideForEvent ==> " + (typeof this.slideForEvent));
    if(typeof this.slideForEvent !== "undefined" && this.slideForEvent !== null)
       this.slideForEvent.startAutoplay();        
  }

  ionViewDidEnter(){
    console.log("ionViewDidEnter");
    this.getEventListForHome();   
    
  }

  ionViewDidLeave(){
    this.checkUpdateCall = false;
  }

  ionViewWillUnload(){ }

  /**
   * 이벤트 롤링 슬라이드 변경시마다 처리되는 function
   */
  slideChanged() {
    setTimeout(() => {
      this.slideForEvent.startAutoplay();  
    }, 300);    
  }

  /**
   * 업데이트 여부 체크
   */
  checkUpdate(){
    if(!this.authService.loginYn){
      this.toast.show("로그인이 되어 있지 않습니다.", 'short', 'bottom');
    }
  }

  init(){
    console.log("init()");
    this.platform.ready().then(() => {
      if(this.checkUpdateCall){
        this.checkUpdate();
      }
    });
  }

  setParameterObj(type, value){
    switch(type){
      case 'searchTimeRangeType': {
        this.homeServ.getMainTimeListParam.searchTimeRangeType = value;
        break;
      }
      case 'state': {
        this.homeServ.getMainTimeListParam.searchStateCode = value;
        break;
      }
      case 'searchJoinType': {
        this.homeServ.getMainTimeListParam.searchJoinType = value;
        break;
      }
    }
  
    this.homeServ.getMainTimeListParam.searchTeeupDate = this.dateListForSearch[0][0].formattedDate;
    this.mainTimeList = [];
    this.mainTimeListPerDate = [];

    this.getMainPageDatas();
    this.getMainTourList();
  }

  /**
   * 할인부킹 리스트 refreshing
   * getMainTimeList() 함수를 기다렸다가 complete한다.
   */
  async doRefresh() {
    console.log("doRefresh");
    await this.getMainPageDatas();
    this.refresherRef.complete();    
  }

  slideControlForRefresh(){
    this.slideForDates.isBeginning().then((a)=> {  
      if(a) {
        this.slideChangeEventPrevent = false;
      } else {
        this.currentSlideIdx = 0;
        this.slideChangeEventPrevent = true;
        this.slideForDates.slideTo(this.currentSlideIdx);
      } 
    });
    this.selectIndex = -1;
    this.homeServ.getMainTimeListParam.searchGolfIdList = [];
    this.refreshPage(this.stateCode, this.selectDate);
  }

  refreshPage(stateCode, selectDate){
    console.log("refreshPage");
    this.mainTimeList = [];
    this.mainTimeListPerDate = [];

    this.dateListInfo = this.commServ.makeDateArrayForSearch('booking');
    this.dateListForSearch = this.dateListInfo.dateArray;

    //초기 검색값 세팅
    this.currentSlideIdx = 0;
    this.perWeekList = this.dateListForSearch[0];
    this.homeServ.getMainTimeListParam.searchTeeupDate = this.perWeekList[0].formattedDate;
    this.homeServ.getMainTimeListParam.searchTeeupDateEnd = this.perWeekList[6].formattedDate;
    if (selectDate !== undefined && selectDate !== null) {
      this.selectDate = selectDate;  
    } else {
      this.selectDate = this.perWeekList[0].formattedDate;
    }
    
    this.homeServ.getMainTimeListParam.searchTimeRangeType = 'all';

    this.mainTimeList = [];
    this.mainTimeListPerDate = [];

    //상단 지역코드
    this.homeServ.getMainTimeListParam.searchStateCode = stateCode;  

    this.getMainPageDatas();
    this.getMainTourList();
    this.getEventListForHome();
    this.getShopMainList();
  }

  /**
   * 골프쇼핑 메인 추천 상품정보 가져오기
   */
  getShopMainList() {
    this.homeServ.getShopMainList().subscribe(
      data =>{
        this.mainShopProdt = data;                    

        // 디바이스 스크린사이즈에 비례한 글자수 제한수
        let cutSize = 64; 
        
        if(this.platform.width() <= 375) {
          if(this.platform.width() === 375) {
            cutSize = 62;
          } else {            
            cutSize = 62 - ((375 - this.platform.width()) / 2);
          }
        }

        var gs_id_list = "";

        for(let i=0; i<this.mainShopProdt.length; i++) {
          //상품명 문자열 자르기
          this.mainShopProdt[i].name = this.functionService.cutStrLen(this.mainShopProdt[i].name.replace(/\\/g, ''), cutSize);
          this.mainShopProdt[i].normal_price = parseInt(this.mainShopProdt[i].normal_price, 10);
          this.mainShopProdt[i].good_price = parseInt(this.mainShopProdt[i].good_price, 10);
          this.mainShopProdt[i].discount_rate = this.mainShopProdt[i].discount_rate.replace("%", "");     
          
          gs_id_list += this.mainShopProdt[i].gs_id + "/";          
        }

        console.log("메인쇼핑 gs_id :: ", gs_id_list);

        this.dismissLoader();
      }, error=> {
        console.log(error.toString());  
        this.dismissLoader();
      }
    );
  }

  /**
  * 이벤트 리스트 가져오기.
  */
  getEventListForHome() {
    console.log("getEventListForHome");
    this.homeServ.getEventListForHome().subscribe(
      data => {
        if(data.resultCode === 'EL'){
          // 에러가 발생하면 카운트를 0으로 처리하여 문구로 나오도록 처리한다. 즉, 에러 처리하지 않는다.
          this.eventListCount = 0;
        } else {
          this.eventListCount = data.eventListCount;
          if(this.eventListCount > 0) {
            this.eventListForHome.length = 0;

            Object.keys(data.resultList).forEach(key => {          
              this.eventListForHome.push(data.resultList[key]);              
            });
          }
        }
        // this.loadingCtrl.dismiss();
      }
      , error => {
        const errorMessageForGA = 'category:getEventListForHome'+'/errorType:exception'+'/message:'+error.toString();
        //this.gaUtil.gaTrackExceptionError(errorMessageForGA);
        setTimeout(() => {
          // this.loadingCtrl.dismiss();
        }, 100);
      }
    );
  }

  /* 메인 페이지 데이터 가져오기 */
  getMainPageDatas() {
    this.showLoader("데이터를 조회중입니다...");

    this.homeServ.getMainPageDatas().subscribe(
      data => {
        if(data.resultCode === 'EL'){
          this.functionService.showErrorAlert("리스트를 가져오는데 실패하였습니다. 좌측 하단 할인부킹 메뉴를 클릭해 주시기 바랍니다.");
        }
        console.log("data.resultList");
        console.log(data.resultList);
        Object.keys(data.resultList).forEach(key => {
          console.log(key);
          this.mainTimeList.push(data.resultList[key]);                    
        });
        console.log("mainTimeList");
        console.log(this.mainTimeList);

        console.log(this.dateListInfo.weekTimeListLoad);
        console.log(this.dateListInfo.weekTimeListLoad);
        this.dateListInfo.weekTimeListLoad[this.currentSlideIdx].condition = true;
        this.controllList(this.currentSlideIdx, this.selectDate);
        this.dismissLoader();
      }
      , error => {
        const errorMessageForGA = 'category:getMainTimeList'+'/errorType:exception'+'/message:'+error.toString();
        //this.gaUtil.gaTrackExceptionError(errorMessageForGA);
        this.dismissLoader();
      }
    );
  }

  /**
   * 메인 투어 리스트 가져오기
   */
  getMainTourList() {
    this.homeServ.getMainTourList(this.platform.width()).subscribe(
      data => {
        Object.keys(data.mainTourList).forEach(key => {
          if(key === "0")
            this.mainTourListDo = data.mainTourList[key];
            this.mainTourListDoPerState = this.mainTourListDo["do_03"];
          if(key === "1") 
            this.mainTourListOv = data.mainTourList[key];                   
            this.mainTourListOvPerState = this.mainTourListOv["ov_01"];
        });
      }, 
      error=>{

      }
    )
  }

  /**
   * 투어지역 선택시 노출 리스트 재정의
   * @param stan 
   */
  setMainTourByState(stan) {
    if(stan.indexOf("do") === 0) {
      this.mainTourListDoPerState = this.mainTourListDo[stan];
      this.selectDoState = stan;
    }
    if(stan.indexOf("ov") === 0) {
      this.mainTourListOvPerState = this.mainTourListOv[stan];
      this.selectOvState = stan;
    }    
  }

  /**
   * 투어상세화면으로 가기
   * @param tourId 
   */
  goToTourDetail(tourId, stan) {
    this.navCtrl.setDirection("forward");
    this.navCtrl.navigateForward('/tourdetail'+"/"+stan.substring(0,2)+"/"+tourId+"/"+ '');
  }

  /**
   * 공지사항으로 가기
   */
  gotoBoard() {
    this.navCtrl.navigateForward(['/board']);    
  }

  /**
   * 메인화면 롤링 이벤트 클릭시 상세화면으로 이동.
   */
  goToEventDetail(eventSeq) {
    console.log("goToEventDetail");
    this.navCtrl.navigateForward('/eventdetail/'+ eventSeq);    
  }

  /**
   * 투어페이지로 가기
   * @param stan 
   */
  goToTourPage(stan) {
    if(stan === "do") this.navCtrl.navigateRoot('tour/do_03');
    if(stan === "ov") this.navCtrl.navigateRoot('tour/ov_01');
  }

  /**
   * 골프쇼핑 페이지로 이동
   */
  goToShop(gs_id) {
    if(gs_id === '') {
      this.navCtrl.navigateRoot('shop/0');
    } else {
      this.navCtrl.navigateRoot(['/shop'+'/'+gs_id]);
    }
  }

  /**
   * 리스트 controll function
   * @param idx
   * @param currentDate
   */
  controllList(idx, currentDate){
    console.log("controllList in.");
    console.log(idx);
    console.log(currentDate);
    this.mainTimeListPerDate = [];
    const nowWeekTimeList = this.mainTimeList[idx];
    console.log(nowWeekTimeList);
    this.selectDate = currentDate;

    if(typeof nowWeekTimeList !== 'undefined') {
      Object.keys(nowWeekTimeList).forEach(key => {
        if (key === currentDate) {
          this.mainTimeListPerDate = nowWeekTimeList[key];
          console.log("크기 : " + this.mainTimeListPerDate.legnth);
          console.log(this.mainTimeListPerDate);
          return false;
        }
      });
    }else{
      //실패
    }
  }

  /**
   * 할인부킹 리스트 이동
   * @param golfId
   */
  goToBookingDetailList(selectDate, selectState, count){
    if(count === 0) {
      this.functionService.showNotiAlert("선택하신 날짜에는 예약가능한 타임이 없습니다.");
      return;  
    }
    this.selectDate = selectDate;
    // console.log("[goToBookingDetailList ] this.selectDate :" + this.selectDate);
    const NavigationExtras: NavigationExtras = {
      queryParams: {
        currentDate: this.selectDate
        , state: selectState
        , searchTimeRangeType: 'all'
        , searchJoinType: this.homeServ.getMainTimeListParam.searchJoinType 
        , type: 'select'     
        , currentSlideIdx : this.getSlideIdx(this.selectDate)
        , fromwhere : 'home'
      }
    };
    this.navCtrl.navigateForward(['/booking'], NavigationExtras);
  }

  /**
   * 부킹센터로 넘길 슬라이드 인덱스 계산
   * 메인화면 날짜 슬라이드는 5일씩 한페이지고 부킹센터는 7일씩 한페이지 때문에
   * 슬라이드 인덱스 계산이 필요함.
   * @param selectDate
   */
  getSlideIdx(selectDate) {
    const firstDate = new Date(this.dateListForSearch[0][0].formattedDate);
    const calDate = new Date(selectDate);
    const betweenDate = (calDate.getTime() - firstDate.getTime())/1000/60/60/24;

    let returnValue = Math.floor(betweenDate/7);
    if(returnValue < 0) returnValue = 0;

    return returnValue;
  }

  /**
   * 부킹센터에 넘길 선택데이터가 속하는 인덱스의 첫번째 날짜값을 계산
   * @param selectDate 
   */
  getSearchTeeupDate(selectDate) {
    const firstDate = new Date(this.dateListForSearch[0][0].formattedDate);
    const calDate = new Date(selectDate);
    const betweenDate = (calDate.getTime() - firstDate.getTime())/1000/60/60/24;
    const pageIndex = Math.floor(betweenDate/7);
    firstDate.setDate(firstDate.getDate() + (pageIndex * 7));    
    return firstDate.getFullYear() + "-" + firstDate.getMonth() + "-" + firstDate.getDate();
  }

  toggleSearchBar(){
    if(this.toggled){
      this.searchbarSearch();
      //만약에 검색값이 존재를 한다면 검색을 진행한다.
      //아무런 값도 없을 경우에는 바로 검색창을 제거한다.
    }else{
      this.toggled = true;
      setTimeout(() => {
        this.searchbar.setFocus();
      }, 100);
    }
  }

  /**
   * 검색 관련
   * @param event
   */
  cancelSearch(event){
    //검색창 닫기
    //console.log(event);
    this.toggled = false;
  }

  showKeyboard(event){
    //console.log('showKeyboard');
    this.keyboard.show();
  }

  searchbarSearch(){
    //지역명, 골프장 검색
    //console.log(this.searchingValue);
    if(this.searchingValue === ''){
      this.functionService.showNotiAlert("검색할 단어를 입력해주시기 바랍니다.");
      this.toggled = false;
    }else{
      this.searchbarSearchLoader = this.functionService.showLoading('검색중입니다...');
      this.searchbarSearchLoader.present();

      this.toggled = false;

      const param ={
        searchBarValue: this.searchingValue
        , searchType: 'all'
      };

      this.bookingService.getSearchGolfNSido(param).subscribe(
        data => {
          if(data.resultCode=== 'SU'){
            if(data.resultCount === 0){
              this.functionService.showNotiAlert("입력하신 "+this.searchingValue+"에 해당하는 골프장을 찾을 수 없습니다. 다른 지역명 혹은 골프장명을 입력해주시기 바랍니다.");
              this.searchingValue = '';

              setTimeout(() => {
                this.searchbarSearchLoader.dismiss();
              }, 200);
            }else{
              const golfIdListTmp = data.resultList;
              console.log(golfIdListTmp);
              this.functionService.showNotiAlert("입력하신 "+this.searchingValue+"에 해당하는 골프장이 검색되었습니다.").then((data)=>{
                this.searchingValue = '';
                this.homeServ.getMainTimeListParam.searchGolfIdList = golfIdListTmp;

                this.currentSlideIdx = 0;
                this.slideChangeEventPrevent = true;
                this.slideForDates.slideTo(this.currentSlideIdx);

                setTimeout(() => {
                  this.searchbarSearchLoader.dismiss();
                  this.refreshPage(this.stateCode, this.selectDate);
                  this.slideChangeEventPrevent = false;
                }, 200);
              });
            }
          }else{
            this.searchingValue = '';
          }
        }, error => {
          console.log(error.toString());
        }
      );
    }
  }

  /**
   * 회원가입 하러 가기
   */
  goToUserNewForm(){
    this.menuCtrl.close();
    this.navCtrl.navigateForward('/termcert');
  }

  /**
   * 로그인 하러 가기
   */
  goToLogin(){
    this.menuCtrl.close();
    this.navCtrl.navigateForward(['/login'], {queryParams: {menuId: 'main'}});  
  }

  /**
   * 마이페이지 가기
   */
  goMyPage(menuId){
    this.navCtrl.navigateForward(['/mypage'], {queryParams: {menuId: menuId}});
  }

  /**
   * 할인부킹 리스트 스크롤 상단으로 이동
   */
  listScrollUp(){
    this.content.scrollToTop();
  }

  underlineTransition(index) {
      
  }

  // 쇼로딩
  async showLoader(message) {
    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  } 
}
