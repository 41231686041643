import {
  IonContent, NavController, NavParams, Platform, ModalController, AlertController, LoadingController
} from "@ionic/angular";
import {Component, ViewChild, ApplicationRef} from "@angular/core";
import {MyService} from "../services/my-service";
import {CommonService} from "../services/common-service";
import {Function} from "../services/function";
import {ApiConfig} from "../services/config";
import {AuthService} from "../services/auth-service";
import {MyBookingReserveDataModel} from "../services/model/mypage/MyBookingReserveDataModel";
import {MyTourReserveDataModel} from "../services/model/mypage/MyTourReserveDataModel";
import {MyTourReservDetailMemoModel} from "../services/model/mypage/MyTourReservDetailMemoModel";
import {UserService} from "../services/user-service";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import {BookingServeice} from "../services/booking-serveice";
import {SocialSharing} from "@ionic-native/social-sharing/ngx";
import {DatePipe} from "@angular/common";
import {DatePicker} from "@ionic-native/date-picker/ngx";
import {ActionSheet, ActionSheetOptions} from "@ionic-native/action-sheet/ngx";
import { Device } from '@ionic-native/device/ngx';
// import {GoogleAnalyticsUtil} from "../utils/GoogleAnalytics";

@Component({
  selector: 'page-mypage',
  templateUrl: 'mypage_detail.html',
  providers: [ MyService, BookingServeice], 
  styleUrls: [ 'mypage.scss' ]
})
export class MypageDetail{
  @ViewChild(IonContent) content: IonContent;
  apiConfig:ApiConfig = new ApiConfig();

  modalTitleType:string;
  modalTitle:string;
  orderId:number;

  myBookingReservationDetail:MyBookingReserveDataModel;
  myTourReservationDetail:MyTourReserveDataModel;
  myTourReservDetailMemoList:MyTourReservDetailMemoModel;

  myTourING = true;

  option= { maximumAge: 3000, timeout: 5000, enableHighAccuracy: true };

  itsDepartureDate:any = new Date();  // 여행 출발일
  itsArrivalDate:any = new Date();    // 여행 완료일
  managerMemo:any;                    // 매니저메모 입력

  saveLoader:any;

  isPlatformMobile:any = '';
  // Initialize loader variable
  private loaderActive = false;

  constructor(public navCtrl: NavController, public navParams: NavParams, public commonService: CommonService
            , public platform: Platform, public userService: UserService, public functionService: Function
            , public myService: MyService, public authService: AuthService, private geolocation: Geolocation
            , public bookingService: BookingServeice, private socialSharing: SocialSharing
            , public datePicker: DatePicker, public datepipe: DatePipe, private actionSheet: ActionSheet
            , public alertCtrl: AlertController, private device: Device, public app: ApplicationRef
            , private modalCtrl: ModalController, private loadingCtrl: LoadingController)
  {

  }

  getUserPlatform() {
    this.isPlatformMobile = ( (this.platform.is('ios') || this.platform.is('android')) && this.device.platform !== "browser") ;
  }

  ngOnInit() {
    this.getUserPlatform();
  }

  ionViewWillLeave() {

  }

  ionViewWillEnter(){
    this.modalTitleType = this.navParams.get('menuType');
    this.orderId = this.navParams.get('orderId');

    switch (this.modalTitleType){
      case 'booking' : {
        this.myBookingReservationDetail = this.navParams.get('myBookingReservationDetail');
        this.modalTitle = this.myBookingReservationDetail.golf_name+' '+this.myBookingReservationDetail.sido_name;
        break;
      }
      case 'tour' : {
        this.myTourReservationDetail = this.navParams.get('myTourReservationDetail');
        

        const today = new Date();
        if(today.getTime() >= new Date(this.myTourReservationDetail.itsArrivalDate).getTime()){
          this.myTourING = false;
        }else{
          this.myTourING = true;
        }

        this.modalTitle = '골프투어 마이페이지';
        break;
      }

      case 'tourappl' : {
        this.myTourReservationDetail = this.navParams.get('myTourReservationDetail');
        this.myTourReservDetailMemoList = this.navParams.get('myTourReservDetailMemoList');

        const today = new Date();
        if(today.getTime() >= new Date(this.myTourReservationDetail.itsArrivalDate).getTime()){
          this.myTourING = false;
        }else{
          this.myTourING = true;
        }

        if (this.isPlatformMobile === false) {
          this.myTourReservationDetail.itsArrivalDate = this.myTourReservationDetail.itsArrivalDate.split(".").join("-");
          this.myTourReservationDetail.itsDepartureDate = this.myTourReservationDetail.itsDepartureDate.split(".").join("-");
        
        }

        this.modalTitle = '골프투어 마이페이지';
        break;
      }
    }
  }

  ionViewDidEnter(){
    this.app.tick();
    if (this.isPlatformMobile === false) {
      const itsContractPerson = (document.getElementById("itsContractPerson") as HTMLInputElement);
      if(typeof this.myTourReservationDetail.itsContractPerson !== 'undefined') {
        itsContractPerson.value = this.myTourReservationDetail.itsContractPerson.toString();
      }
      const itsOrderStatus = (document.getElementById("itsOrderStatus") as HTMLInputElement);
      if(typeof this.myTourReservationDetail.itsOrderStatus !== 'undefined') {
        itsOrderStatus.value = this.myTourReservationDetail.itsOrderStatus;
      }
    }
  }

  /* 할인부킹 마이페이지 */
  cancelMyBooking(){
    if(this.myBookingReservationDetail.cancel_yn === 'N'){
      this.functionService.showNotiAlert('해당 타임은 임박 타임이기 때문에 취소가 불가능합니다.');
    }else{
      this.functionService.showConfirmAlertReservation('예약 취소 전 꼭 확인해 주세요', '취소완료와 동시에<br>해당골프장 예약이 자동 취소됩니다.<br>예약을 취소하시겠습니까?')
      .then((data)=>{
        if(data) {
          if (data.res === 'yes') {
            this.showLoader('예약 취소중...');
  
            const params = {
              orderId: this.orderId
              , loginUserId: this.userService.loginNappDataParam.loginId
              , userStatusCode: this.authService.userData.user_status
              , userId: this.authService.userData.user_id
            };
  
            console.log(params);
  
            this.bookingService.cancelPayment(params).subscribe(
              data => {
                this.dismissLoader();
                if(typeof data.message !== 'undefined'){
                  this.authService.userData.bookingResrvCount = data.data;
                  this.functionService.showNotiAlert(data.message).then((data)=>{
                    if(data) {
                      if (data.res === 'yes') {
                        setTimeout(() => {
                          this.dismiss();
                        }, 300);
                      }
                    }
                    
                  });
                }
              }, error => {
                //this.gaUtil.gaTrackExceptionError('category:mypageDetail/function:cancelPayment/id:'+ this.userService.loginNappDataParam.loginId+'/appVersion:'+this.userService.loginNappDataParam.appVersion
                //  +'/deviceModel:'+this.userService.loginNappDataParam.deviceModel+'/deviceOsType:'+this.userService.loginNappDataParam.deviceOsType+'/deviceVersion'+this.userService.loginNappDataParam.deviceVersion
                //  +'/errorMsg:'+error.toString());
  
                const errorAlert = this.functionService.showErrorAlert2('오류가 발생하여 정상적으로 처리되지 못했습니다. 다시 시도 해 주시기 바랍니다.<br>(error type : server error)');              
              }
            );
          }
        }
        
      });
    }
  }

  /**
   * 공유하기
   * @param joinDetailModel
   */
  share(){
    let sharingMsg = '[이츠골프] ['+this.myBookingReservationDetail.state+' '+this.myBookingReservationDetail.sido_name+'] '+this.myBookingReservationDetail.golf_name
      +'\n날짜 : '+this.myBookingReservationDetail.teeup_date
      +'\n티타임 : '+this.myBookingReservationDetail.teeup_time;
    sharingMsg += '\n그린피 : '+this.myBookingReservationDetail.perAmount+'원 X '+this.myBookingReservationDetail.teeup_number+'인';
    sharingMsg += '\n\n<이츠골프 앱 다운>\n구글마켓 : http://me2.do/xmGPMJYh\n앱스토어(아이폰) : http://me2.do/FC1B28dv';

    if (this.platform.is('ios') || this.platform.is('android')) {
      this.socialSharing.share(sharingMsg, '', '', '').then(() => {
        this.shareMyBooking();
      }).catch(() => {
        console.log('social sharing error');
      });
    }else{
      this.shareMyBooking();
    }
  }

  shareMyBooking(){
    //this.functionServ.androidGPSPermission();
    //device gps
    this.geolocation.getCurrentPosition(this.option).then((result) => {
      this.commonService.eventHistoryParam.deviceLatitude = result.coords.latitude+'';
      this.commonService.eventHistoryParam.deviceLongitude = result.coords.longitude+'';
      this.commonService.eventHistoryParam.deviceAccuracy = result.coords.accuracy+'';
    }).catch((error) => {
      const errorMsg = 'category:shareReservation/id:'+ this.userService.loginNappDataParam.loginId+'/appVersion:'+this.userService.loginNappDataParam.appVersion
        +'/deviceModel:'+this.userService.loginNappDataParam.deviceModel+'/deviceOsType:'+this.userService.loginNappDataParam.deviceOsType+'/deviceVersion'+this.userService.loginNappDataParam.deviceVersion
        +'/errorType:'+error.code+'/errorMsg:'+error.message;
      //this.gaUtil.gaTrackExceptionError(errorMsg);
    });

    this.commonService.storeEventHistory('CSH', 'MDS', this.myBookingReservationDetail.order_Id);
  }

  /* 골프투어 마이페이지 */
  cancelMyTour(){

  }

  /* 공통 */
  dismiss(){
    this.modalCtrl.dismiss();
  }

  //날짜 선택
  showDatePicker(dateType){
    const toDate = new Date();
    this.datePicker.show({
      date: (dateType === 'departure')?this.itsDepartureDate:this.itsArrivalDate
      , minDate: new Date().getTime()
      , maxDate: new Date(toDate.setMonth(toDate.getMonth()+3)).getTime()
      , mode: 'date'
      , titleText: (dateType === 'departure')?'여행출발':'여행완료' + '일을 선택하세요'
      , androidTheme: this.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT
      , okText: '선택'
      , cancelText: '취소'
    }).then(
      date => {
    if(date != null && typeof date !== 'undefined'){
          if(dateType === 'departure') {
            this.myTourReservationDetail.itsDepartureDate = this.datepipe.transform(date, 'yyyy-MM-dd');
          } else {
            this.myTourReservationDetail.itsArrivalDate = this.datepipe.transform(date, 'yyyy-MM-dd');
          }
          //this.joinCreateModel.teeup_date_dp = this.datepipe.transform(date, 'yyyy년 MM월 dd일 (E)');
          //this.joinCreateModel.teeup_date_dp = this.dateFormatP.transform(date);
    }
      }, err => {
        const errorMsg = 'category:joinCreate/function:showDatePicker/id:'+ this.userService.loginNappDataParam.loginId+'/appVersion:'+this.userService.loginNappDataParam.appVersion
          +'/deviceModel:'+this.userService.loginNappDataParam.deviceModel+'/deviceOsType:'+this.userService.loginNappDataParam.deviceOsType+'/deviceVersion'+this.userService.loginNappDataParam.deviceVersion
          +'/errorType:datePicker/errorMsg:Error occurred while getting date, '+err.toString();
        //this.gaUtil.gaTrackExceptionError(errorMsg);
      }
    );
  }

  //인원 선택
  showPeopleNumber(){
    const buttonLabels = ['12명', '11명', '10명', '9명','8명', '7명', '6명', '5명','4명', '3명', '2명', '1명', '0명'];
    console.log(this.actionSheet.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT);

    const options: ActionSheetOptions = {
      title: '인원 수를 선택해주세요',
      buttonLabels: buttonLabels,
      addCancelButtonWithLabel: '취소',
      androidTheme: 5,
      destructiveButtonLast: true
    };

    this.actionSheet.show(options).then((buttonIndex: number) => {
      this.myTourReservationDetail.itsContractPerson = (buttonLabels.length - buttonIndex);              
      /* let notiAlert = this.functionService.showNotiAlert(this.myTourReservationDetail.itsContractPerson);
      notiAlert.present(); */
    });
  }

  // 투어 진행 상태 선택
  showOrderStatus() {
    const buttonLabels = ['접수완료', '견적완료', '취소', '국내 확정', '마감', '해외 확정', '결제완료'];

    console.log(this.actionSheet.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT);
    const options: ActionSheetOptions = {
      title: '투어신청 진행상태를 선택해주세요.',
      buttonLabels: buttonLabels,
      addCancelButtonWithLabel: '취소',
      androidTheme: 5,
      destructiveButtonLast: true
    };

    this.actionSheet.show(options).then((buttonIndex: number) => {
      if(buttonIndex === 1) {this.myTourReservationDetail.itsOrderStatus = '010'; this.myTourReservationDetail.orderStatusStr = '접수완료'; }
      if(buttonIndex === 2) {this.myTourReservationDetail.itsOrderStatus = '011'; this.myTourReservationDetail.orderStatusStr = '견적완료'; }
      if(buttonIndex === 3) {this.myTourReservationDetail.itsOrderStatus = '019'; this.myTourReservationDetail.orderStatusStr = '취소'; }
      if(buttonIndex === 4) {this.myTourReservationDetail.itsOrderStatus = '030'; this.myTourReservationDetail.orderStatusStr = '국내 확정'; }
      if(buttonIndex === 5) {this.myTourReservationDetail.itsOrderStatus = '039'; this.myTourReservationDetail.orderStatusStr = '마감'; }
      if(buttonIndex === 6) {this.myTourReservationDetail.itsOrderStatus = '040'; this.myTourReservationDetail.orderStatusStr = '해외 확정'; }
      if(buttonIndex === 7) {this.myTourReservationDetail.itsOrderStatus = '050'; this.myTourReservationDetail.orderStatusStr = '결제완료'; }
    });
  }

  // 1인신청비 입력
  async selectPricePerPerson(){
    const prcPerPersonAlert = await this.alertCtrl.create({
      header: '1인신청비 입력'
      , message: '1인신청비를 입력하세요.'
      , inputs: [
        {type: 'tel', placeholder: '금액을 입력하세요'}
      ]
      , buttons: [
        {text: '취 소'}, 
        {text: '확 인',
        handler: data => {
          this.myTourReservationDetail.orderPrice = parseInt(data[0], 10);
        }}
      ]
      , mode: 'md'
    });
  
    await prcPerPersonAlert.present();
  }

  // 총 신청비 입력
  async selectPriceTotal(){
    const prcTotalAlert = await this.alertCtrl.create({
      header: '총신청비 입력'
      , message: '총신청비를 입력하세요.'
      , inputs: [
        {type: 'tel', placeholder: '금액을 입력하세요'}
      ]
      , buttons: [
        { text: '취 소'}
        , 
        {
          text: '확 인',
          handler: data => {
            this.myTourReservationDetail.orderCostPrice = parseInt(data[0], 10);
          }
        }
      ]
      , mode: 'md'
    });
    
    await prcTotalAlert.present();
  }

  // 투어매니저용 투어신청서 상세데이터 저장
  saveTourApplDetail() {
    // 투어신청서를 저장하는 (액면그대로) 서비스를 만들자꾸나.
    this.showLoader('처리중...');
    
    if (this.isPlatformMobile === false) {
      this.myTourReservationDetail.itsArrivalDate = (document.getElementById('itsArrivalDate') as HTMLInputElement).value;
      this.myTourReservationDetail.itsArrivalDate = this.myTourReservationDetail.itsArrivalDate.split("-").join(".");

      this.myTourReservationDetail.itsDepartureDate = (document.getElementById('itsDepartureDate') as HTMLInputElement).value;
      this.myTourReservationDetail.itsDepartureDate = this.myTourReservationDetail.itsDepartureDate.split("-").join(".");

      this.myTourReservationDetail.itsContractPerson = Number.parseInt((document.getElementById('itsContractPerson') as HTMLInputElement).value, 10);

      this.myTourReservationDetail.itsOrderStatus = (document.getElementById('itsOrderStatus') as HTMLInputElement).value;
      if (this.myTourReservationDetail.itsOrderStatus === '010') {
        this.myTourReservationDetail.orderStatusStr = '접수완료';
      } else if (this.myTourReservationDetail.itsOrderStatus === '011') {
        this.myTourReservationDetail.orderStatusStr = '견적완료';
      } else if (this.myTourReservationDetail.itsOrderStatus === '019') {
        this.myTourReservationDetail.orderStatusStr = '취소';
      } else if (this.myTourReservationDetail.itsOrderStatus === '030') {
        this.myTourReservationDetail.orderStatusStr = '국내 확정';
      } else if (this.myTourReservationDetail.itsOrderStatus === '039') {
        this.myTourReservationDetail.orderStatusStr = '마감';
      } else if (this.myTourReservationDetail.itsOrderStatus === '040') {
        this.myTourReservationDetail.orderStatusStr = '해외 확정';
      } else if (this.myTourReservationDetail.itsOrderStatus === '050') {
        this.myTourReservationDetail.orderStatusStr = '결제완료';
      } else {
      }
    }
    console.log(JSON.stringify(this.myTourReservationDetail));
    
    this.myService.saveTourApplDetail(this.myTourReservationDetail, this.authService.userData).subscribe(
      data => {
        if(data.resultCode === 'ok'){
          const notiAlert = this.functionService.showNotiAlert(data.message);
        }else {
          switch(data.resultCode){
            case 'PE':{
              const notiAlert = this.functionService.showErrorAlert2('로그인 여부를 확인해주시기 바랍니다.');
              break;
            }                       
            case 'error': {
              const notiAlert = this.functionService.showErrorAlert2(data.message);
              break;
            }
          }
        }
      }, error => {
        const errorAlert = this.functionService.showErrorAlert2('정상적으로 처리되지 못했습니다. (error type : server error)');

        const errorMsg = 'category:joinCreate/function:updateArticle/id:'+ this.userService.loginNappDataParam.loginId+'/appVersion:'+this.userService.loginNappDataParam.appVersion
          +'/deviceModel:'+this.userService.loginNappDataParam.deviceModel+'/deviceOsType:'+this.userService.loginNappDataParam.deviceOsType+'/deviceVersion:'+this.userService.loginNappDataParam.deviceVersion
          +'/errorType:exception'+'/errorMsg:'+error.toString();

        // this.gaUtil.gaTrackExceptionError(errorMsg);
      }
    );

    setTimeout(() => {
      this.dismissLoader();
    }, 100);
  }

  /**
   * function
   */
  currencyFormmat(input){
    const result:string = input.toFixed(0).replace(/./g, function (c, i, a) {
      return i && c !== "." && ((a.length - i) % 3 === 0) ? ','+c : c;
    });

    return result;
  }

  // 쇼로딩
  async showLoader(message) {
    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  }
}
