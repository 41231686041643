import { Component, ViewChild, ApplicationRef, ViewEncapsulation} from '@angular/core';
import { 
  IonContent, IonInfiniteScroll, LoadingController, ModalController, NavController, Platform, IonToggle
} from '@ionic/angular';
import { MypageDetail} from "./mypage_detail";
import { AuthService} from "../services/auth-service";
import { Function} from "../services/function";
import { JoinService} from "../services/join-service";
import { UserService} from "../services/user-service";
import { MyService} from "../services/my-service";
import { MyArticleModel} from "../services/model/mypage/MyArticleModel";
import { MypageArticleFilterModal} from "./mypage_article_filter_modal";
import { CommonService} from "../services/common-service";
import { MyBookingReserveListModel} from "../services/model/mypage/MyBookingReserveListModel";
import { MyTourReserveListModel} from "../services/model/mypage/MyTourReserveListModel";
import { ApiConfig} from "../services/config";
import { MyTourReserveDataModel} from "../services/model/mypage/MyTourReserveDataModel";
import { MyBookingReserveDataModel} from "../services/model/mypage/MyBookingReserveDataModel";
import { MyNotificationListModel} from "../services/model/mypage/MyNotificationListModel";
import { MyTourReservDetailMemoModel} from "../services/model/mypage/MyTourReservDetailMemoModel";
import { DatePipe} from "@angular/common";
import { ActivatedRoute, NavigationExtras} from '@angular/router';
// import {GoogleAnalyticsUtil} from "../utils/GoogleAnalytics";

@Component({
  selector: 'page-mypage',
  templateUrl: 'mypage.html',
  providers: [JoinService, MyService]
  , styleUrls: ['mypage.scss']
  , encapsulation: ViewEncapsulation.None
})
export class Mypage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("infiniteScrollControlBooking") infiniteScrollControlBooking: IonInfiniteScroll;
  @ViewChild("infiniteScrollControlTour") infiniteScrollControlTour: IonInfiniteScroll;
  @ViewChild("infiniteScrollControlMyArticle") infiniteScrollControlMyArticle: IonInfiniteScroll;
  @ViewChild("onoffToggle") toggle: IonToggle;
  apiConfig:ApiConfig = new ApiConfig();
  
  subHeaderMenuList: Array<{title: string, menuId: string}>;
  currentSubMenu:string;
  testImgUrl:string;
  isIosPlatform = '';

  allCheck:boolean;

  myArticleList:MyArticleModel[];

  selectIndex:number;

  filterModel = {
    userId: ''
    , searchJoinType: ''
    , searchState: '00'
    , searchGolfIdList: []
    , searchTeeupDate: ''
  };

  getMyArticleListParam = {
    userId: ''
    , sortRegDate: false
    , sortTeeupDate: false
    , searchJoinType: ''
    , searchState: '00'
    , searchGolfIdList: []
    , userStatusCode: ''
    , searchTeeupDate: ''
    , currentPage: 1
    , perPage: 30
  };

  getMyJmInfoParam = {
    jmUserId: ''
    , managerGroup: ''
    , jmOriSeq: ''
  };

  listTabType:string;

  bookingReservList:MyBookingReserveListModel[];
  bookingReservTotalCount = 0;
  bookingReservationfinishLoad = true;

  tourReservList:MyTourReserveListModel[];
  tourReservTotalCount = 0;
  tourReservationnfinitLoad = true;
  currentPage = 1;

  myBookingReservationDetail:MyBookingReserveDataModel;
  myTourReservationDetail:MyTourReserveDataModel;
  myTourReservDetailMemoList:MyTourReservDetailMemoModel;
  myNotificationList:MyNotificationListModel[];
  selectIndexNoti = -1;

  myArticleReservationinfinitLoad = true;
  myArticleTotalCount = 0;

  notiLoader:any; 
  stringByteLength:any;
  getMyBookingLoader:any;
  getMyBookingDetailLoader:any;
  getMyTourReservationListLoader:any;
  getNotificationListLoader:any;
  updateArticleStatusLoader:any;
  getAvailableCreateJoinArticleLoader:any;
  getMyJmInfoLoader:any;
  getMyTourDetailLoader:any;
  getMyArticleListLoader:any;

  // Initialize loader variable
  private loader: any;
  private loaderActive = false;

  constructor(public navCtrl: NavController, public modalCtrl: ModalController, public authService: AuthService
            , public functionService: Function, public joinService: JoinService, public userService: UserService
            , public myService: MyService, public commonServie: CommonService, public platform: Platform
            , public datepipe: DatePipe, public app: ApplicationRef, public router: ActivatedRoute
            , public loadingCtrl: LoadingController)  {

    this.stringByteLength = ((s, b, i, c) => {
      for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
      return b;
    });

    // ios플랫폼 여부
    if(this.platform.is('ios')) this.isIosPlatform = 'ios';
    
    // params setting
    this.currentSubMenu = this.router.snapshot.paramMap.get("menuId");
    this.listTabType = 'ing';
    this.init();
  }

  init(){
    this.platform.ready().then(() => {
      // this.gaUtil.gaTrackView('MyPage.'+this.currentSubMenu);
    });
  }

  ngOnInit() {
    console.log("진입 유저스태이터스 코드  : ", this.authService.userData.user_status)

    if(this.authService.userData.tourAuthJoin === "Y" || this.authService.userData.tourAuthTran === "Y" || 
       this.authService.userData.tourAuthOversea === "Y") {
      this.subHeaderMenuList = [
        {title: '국내예약', menuId: 'booking'}
        , {title: '투어신청관리', menuId: 'tourappl'}
        , {title: '내글관리', menuId: 'article'}
        , {title: '알림', menuId: 'notification'}
      ];
    } else if(this.authService.userData.user_status === "101") {
      this.subHeaderMenuList = [
        {title: '국내예약', menuId: 'booking'}
        , {title: '투어신청', menuId: 'tour'}        
        , {title: '알림', menuId: 'notification'}
      ];
    } else {
      this.subHeaderMenuList = [
        {title: '국내예약', menuId: 'booking'}
        , {title: '투어신청', menuId: 'tour'}
        , {title: '내글관리', menuId: 'article'}
        , {title: '알림', menuId: 'notification'}
      ];
    }
  }

  ionViewWillLeave() {}

  ionViewWillEnter(){
    if(!this.authService.loginYn) {
      this.functionService.showAlert('비정상적인 접근입니다. 로그인 하신 후 다시 시도해주시기 바랍니다.');
      this.navCtrl.navigateRoot('/home');
    }

    this.allCheck = false;
    console.log('current 메뉴가 먼데 : ' + this.currentSubMenu);

    switch(this.currentSubMenu){
      case 'booking':{
        this.getMyBookingReservationList();
        break;
      }
      case 'tour':{
        this.getMyTourReservationList('tour');
        break;
      }
      case 'tourappl':{
        this.getMyTourReservationList('tourappl');
        break;
      }
      case 'article':{
        this.getMyArticleList();
        break;
      }
      case 'notification':{
        this.getNotificationList();
        break;
      }
    }
  }

  ionViewDidEnter(){

  }

  //실시간 예약과 투어 상세 페이지 (공용)
  setParameterObj(type, typeCode){
    this.listTabType = typeCode;
    this.currentPage = 1;

    if(this.currentSubMenu === 'booking'){
      this.bookingReservationfinishLoad = true;
      this.getMyBookingReservationList();
    }
    if(this.currentSubMenu === 'tour'){
      this.tourReservationnfinitLoad = true;
      this.getMyTourReservationList('tour');
    }
    if(this.currentSubMenu === 'tourappl'){
      this.tourReservationnfinitLoad = true;
      this.getMyTourReservationList('tourappl');
    }
  }

  /**
   * 실시간 예약
   */
  getMyBookingReservationList(){
    this.showLoader('잠시만 기다려주세요...');

    this.myService.getBookingReservListParam.userId = this.authService.userData.user_id;
    this.myService.getBookingReservListParam.currentPage = this.currentPage;

    this.myService.getMyBookingReservationList(this.listTabType).subscribe(
      data => {
        if(typeof data.error !== 'undefined'){
          this.functionService.showErrorAlert(data.error);          
        }else{
          this.bookingReservList = data.list;
          this.bookingReservTotalCount = data.count;
        }
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }, error => {
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    );
  }

  doInfiniteBooking(){
    if(this.bookingReservationfinishLoad) {
      this.myService.getBookingReservListParam.currentPage = this.currentPage + 1;
      this.myService.getMyBookingReservationList(this.listTabType).subscribe(
        data => {
          if (typeof data.error !== 'undefined') {
            setTimeout(() => {
              this.functionService.showErrorAlert2(data.error);
            });
          } else {
            this.bookingReservList = this.bookingReservList.concat(data.list);
          }
        }, error => {

        }, () => {
          console.log('infiniteScroll end');
          if (this.bookingReservTotalCount <= this.bookingReservList.length) {
            this.bookingReservationfinishLoad = false;
          } else {
            this.bookingReservationfinishLoad = true;
          }
          this.infiniteScrollControlBooking.complete();
        }
      );
    }else{
      setTimeout(() => {
        this.infiniteScrollControlBooking.complete();
      }, 300);
    }
  }

  getMyBookingDetail(menuType, orderId){
    this.showLoader('로딩중...');
    
    const param = {
      userId: this.authService.userData.user_id
      , orderId: orderId
    };

    this.myService.getMyBookingDetail(param).subscribe(
      data => {
        if(typeof data.error === 'undefined'){
          this.myBookingReservationDetail = data.data;
          this.showModalBookingReservDetail(this.myBookingReservationDetail, menuType, orderId).then((data)=>{
            
          });
        } else {
          this.functionService.showErrorAlert2(data.error);
        }
        setTimeout(() => {
          this.dismissLoader();
        }, 300);
      }, error => {
        setTimeout(() => {
          this.dismissLoader();
        }, 300);
      }
    );
  }

  //부킹예약 상세내역 모달 창
  async showModalBookingReservDetail(myBookingReservationDetail, menuType, orderId) {
    const modal = await this.modalCtrl.create({
      component: MypageDetail
      , componentProps : {
        menuType: menuType
        , orderId: orderId
        , myBookingReservationDetail: myBookingReservationDetail
        , myTourReservationDetail: ''
      }
    });

    modal.onDidDismiss().then(()=> {
      console.log("여기로 다시 돌아옴");
      this.content.scrollToTop(100);
      this.bookingReservationfinishLoad = true;
      this.currentPage = 1;        
      this.getMyBookingReservationList();
    });    
      
    return await modal.present();
  }

  //투어예약 상세내역 모달 창
  async showModalTourReservDetail(menuType, orderId) {
    const modal = await this.modalCtrl.create({
      component: MypageDetail
      , componentProps: 
        {menuType: menuType, orderId: orderId, myBookingReservationDetail: ''
        , myTourReservationDetail: this.myTourReservationDetail, 
        myTourReservDetailMemoList: this.myTourReservDetailMemoList}
    });

    modal.onDidDismiss().then(()=> {
      this.content.scrollToTop(100);
      //this.listTabType = 'ing';
      this.tourReservationnfinitLoad = true;
      this.currentPage = 1;
      this.getMyTourReservationList(menuType);
    });    
      
    return await modal.present();
  }


  
  

  /**
   * 투어
   */
  getMyTourReservationList(tmType){
    this.showLoader('잠시만 기다려주세요...');
    
    this.myService.getTourReservListParam.userId = this.authService.userData.user_id;
    this.myService.getTourReservListParam.currentPage = this.currentPage;
    this.myService.getTourReservListParam.tmType = tmType;

    this.myService.getMyTourReservationList(this.listTabType).subscribe(
      data => {
        if(typeof data.error !== 'undefined'){
          this.functionService.showErrorAlert(data.error);          
        }else{
          this.tourReservList = data.list;
          this.tourReservTotalCount = data.count;
        }

        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }, error => {
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    );
  }

  doInfiniteTour(){
    if(this.tourReservationnfinitLoad) {
      this.myService.getTourReservListParam.currentPage = this.currentPage + 1;
      this.myService.getMyTourReservationList(this.listTabType).subscribe(
        data => {
          if (typeof data.error !== 'undefined') {
            setTimeout(() => {
              this.functionService.showErrorAlert2(data.error);
            });
          } else {
            this.tourReservList = this.tourReservList.concat(data.list);
          }
        }, error => {

        }, () => {
          console.log('infiniteScroll end');
          if (this.tourReservTotalCount <= this.tourReservList.length) {
            this.tourReservationnfinitLoad = false;
          } else {
            this.tourReservationnfinitLoad = true;
          }
          this.infiniteScrollControlTour.complete();
        }
      );
    }else{
      setTimeout(() => {
        this.infiniteScrollControlTour.complete();
      }, 300);
    }
  }

  getMyTourDetail(menuType, orderId, pOrderUserId){
    this.showLoader('로딩중...');
    
    const param = {
      orderUserId: pOrderUserId
      , tourOrderIdStr: orderId
      , tmType: menuType
    };

    this.myService.getMyTourDetail(param).subscribe(
      data => {
        this.myTourReservationDetail = data.data;
        this.myTourReservDetailMemoList = data.memodata;
        this.myTourReservationDetail.itsTourOrderId = orderId;

        this.showModalTourReservDetail(menuType, orderId);

        setTimeout(() => {
          this.dismissLoader();
        }, 300);
      }, error => {
        setTimeout(() => {
          this.dismissLoader();
        }, 300);
      }
    );
  }

  /**
   *  내 글 관리
   */

  //내 글 관리 정렬
  setMyArticleParameterObj(type, value){
    switch (value){
      case 'all':{
        this.getMyArticleListParam.sortRegDate = false;
        this.getMyArticleListParam.sortTeeupDate = false;
        break;
      }
      case 'reg':{
        this.getMyArticleListParam.sortRegDate = true;
        this.getMyArticleListParam.sortTeeupDate = false;
        break;
      }
      case 'sort':{
        this.getMyArticleListParam.sortRegDate = false;
        this.getMyArticleListParam.sortTeeupDate = true;
        break;
      }
    }

    this.getMyArticleList();
  }

  async openFilterModal2() {
    const pushAlert = await this.modalCtrl.create(
      {
        component: MypageArticleFilterModal
        , componentProps: 
        {
          areaCodeList: this.commonServie.commonData.areaCodeList
          , filterModel: this.filterModel
        }
      }
    );

    pushAlert.present();
    const {data} = await pushAlert.onWillDismiss();
    return data;
  }

  async openFilterModal(){
    this.openFilterModal2().then((data)=> {
      if(data.resetYn !== 'close') {
        this.filterModel = data['filterModel'];
        this.getMyArticleListParam.searchJoinType = this.filterModel.searchJoinType;
        this.getMyArticleListParam.searchState = this.filterModel.searchState;
        this.getMyArticleListParam.searchGolfIdList = this.filterModel.searchGolfIdList;

        this.getMyArticleList();
      }      
    });
  }

  //내 글 목록, 건수 가져오기
  getMyArticleList(){
    // 가져오기전 기존 리스트는 비워두고 페이지 전환을 위해 조금 쉽니다.
    this.myArticleList = [];
    this.selectIndex = -1;
    this.showLoader('내글 목록을 가져오는 중입니다. 작성글이 많을경우 시간이 다소 소요될 수 있습니다.');
    
    this.getMyArticleListParam.userId = this.authService.userData.user_id;
    this.getMyArticleListParam.userStatusCode = this.authService.userData.user_status;
    this.getMyArticleListParam.currentPage = this.currentPage;
    this.getMyArticleListParam.searchTeeupDate = this.filterModel.searchTeeupDate;

    this.joinService.getMyArticleList(this.getMyArticleListParam).subscribe(
      data => {
        if(typeof data.error !== 'undefined'){
          this.functionService.showErrorAlert(data.error);
        }else{
          this.myArticleList = data.list;
          this.myArticleTotalCount = data.count;
        }

        this.dismissLoader();
        setTimeout(() => {
          //사용 및 가능건수 호출
          this.getMyJmInfo();
        }, 100);        
      }, error => {
          setTimeout(() => {
          this.dismissLoader();
        }, 100);
      } 
    );
  }

  doInfiniteMyArticleList(){
    console.log("My article infinite scroll in.");

    if(this.myArticleReservationinfinitLoad) {
      this.getMyArticleListParam.currentPage = this.currentPage + 1;
      this.joinService.getMyArticleList(this.getMyArticleListParam).subscribe(
        data => {
          if (typeof data.error !== 'undefined') {
            setTimeout(() => {
              this.functionService.showErrorAlert2(data.error);
            });
          } else {
            this.myArticleList = this.myArticleList.concat(data.list);
          }
        }, error => {

        }, () => {
          console.log('infiniteScroll end');
          console.log("myArticleTotalCount ==> " + this.myArticleTotalCount);
          console.log("this.myArticleList.length ==> " + this.myArticleList.length);

          if (this.myArticleTotalCount <= this.myArticleList.length) {
            this.myArticleReservationinfinitLoad = false;
          } else {
            this.myArticleReservationinfinitLoad = true;
          }
          this.infiniteScrollControlMyArticle.complete();
        }
      );
    }else{
      setTimeout(() => {
        this.infiniteScrollControlMyArticle.complete();
      }, 300);
    }
  }

  //사용건수/가능건수 가져오기 
  getMyJmInfo() {

    this.selectIndex = -1;
    this.showLoader('로딩중...');
    
    console.log("user_status : " + this.authService.userData.user_status);

    this.getMyJmInfoParam.jmUserId = this.authService.userData.user_id;
    this.getMyJmInfoParam.managerGroup = this.authService.userData.user_status;
    this.getMyJmInfoParam.jmOriSeq = this.authService.userData.jmOriSeq;

    this.joinService.getMyJmInfo(this.getMyJmInfoParam).subscribe(
      data => {
        if(typeof data.error !== 'undefined'){
          this.functionService.showErrorAlert(data.error);
        }else{
          this.authService.userData.jmRegCount = data.myJmInfo.jmRegCount;                  
          this.authService.userData.jmCwRegCount = data.myJmInfo.jmCwRegCount;                
          this.authService.userData.jmTranRegCount = data.myJmInfo.jmTranRegCount;
          this.authService.userData.jmTranCwRegCount = data.myJmInfo.jmTranCwRegCount;
          this.authService.userData.jmUpdRegCount = data.myJmInfo.jmUpdRegCount;
          this.authService.userData.jmUpdCwRegCount = data.myJmInfo.jmUpdCwRegCount;
          this.authService.userData.jmUpdTranRegCount = data.myJmInfo.jmUpdTranRegCount;
          this.authService.userData.jmUpdTranCwRegCount = data.myJmInfo.jmUpdTranCwRegCount;
          this.authService.userData.jmAddRegCount = data.myJmInfo.jmAddRegCount;
          this.authService.userData.jmAddTrRegCount = data.myJmInfo.jmAddTrRegCount;
          this.authService.userData.jmAddUpdRegCount = data.myJmInfo.jmAddUpdRegCount;
          this.authService.userData.jmAddUpdTrRegCount = data.myJmInfo.jmAddUpdTrRegCount;
        }

        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }, error => {

        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    );
  }


  /**
   * 글 수정하러 가기
   * @param seq
   * @param i
   */
  goMyArticleEdit(seq, i){
    this.selectIndex = i;
    
    this.navCtrl.navigateForward('/joindetail/edit/my/'+seq);
  }

  /**
   * 조인플라자 글 쓰기 가능 여부 체크
   */
  getAvailableCreateJoinArticle(writeType){

    
    this.showLoader('잠시만 기다려주세요...');
    this.joinService.getAvailableCreateJoinArticle(this.authService.userData, this.userService.loginNappDataParam, writeType).subscribe(
      data => {
        // 건수 재설정
        if(this.authService.userData.user_status === '104A' || this.authService.userData.user_status === '104B' || 
            this.authService.userData.user_status === '104C' || this.authService.userData.user_status === '104D'){
            this.authService.userData.jmRegCount = data.jmModel.jmRegCount;                  
            this.authService.userData.jmCwRegCount = data.jmModel.jmCwRegCount;                
            this.authService.userData.jmTranRegCount = data.jmModel.jmTranRegCount;
            this.authService.userData.jmTranCwRegCount = data.jmModel.jmTranCwRegCount;
            this.authService.userData.jmUpdRegCount = data.jmModel.jmUpdRegCount;
            this.authService.userData.jmUpdCwRegCount = data.jmModel.jmUpdCwRegCount;
            this.authService.userData.jmUpdTranRegCount = data.jmModel.jmUpdTranRegCount;
            this.authService.userData.jmUpdTranCwRegCount = data.jmModel.jmUpdTranCwRegCount;
            this.authService.userData.jmAddRegCount = data.jmModel.jmAddRegCount;
            this.authService.userData.jmAddTrRegCount = data.jmModel.jmAddTrRegCount;
            this.authService.userData.jmAddUpdRegCount = data.jmModel.jmAddUpdRegCount;
            this.authService.userData.jmAddUpdTrRegCount = data.jmModel.jmAddUpdTrRegCount;
        }
        if(data.resultCode === 'RegN'){
          this.functionService.showAlert(data.message);
        }
        if(data.resultCode === 'ok'){
          setTimeout(() => {
            this.navCtrl.navigateForward('/joincreate/'+writeType+'/new/my');
          }, 200);
        }

        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }, error => {
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    );
  }

  /**
   * 조인플라자 글 상태 변경
   */
  updateArticleStatus(updType){
    const checkedList = [];
    for(let i = 0; i < this.myArticleList.length ; i++){
      if(this.myArticleList[i].checked){
        checkedList.push(this.myArticleList[i].seq);
      }
    }

    const updTypeName = (updType === 'del' ? '삭제':'마감');
    if(checkedList.length === 0){
      
      setTimeout(() => {
        this.functionService.showNotiAlert(updTypeName+'할 항목을 체크해주세요');
      });
    }
    if(checkedList.length > 0){
      setTimeout(() =>{
        this.functionService.showConfirmAlert('총 '+checkedList.length+'건 '+updTypeName+'하시겠습니까?').then((data)=>{
          if(data) {
            if (data.res === 'yes') {
              this.showLoader(updTypeName+'중...');
              
              const params ={
                checkJoinList: checkedList
                , userId: this.authService.userData.user_id
                , type: (updType === 'del' ? 'delete':'close')
              };
    
              this.myService.updateMyArticleStatus(updType, params).subscribe(
                data => {
                  setTimeout(() => {
                    this.dismissLoader();
                  }, 100);
    
                  if(typeof data.error !== 'undefined'){
                    setTimeout(() => {
                      this.functionService.showErrorAlert2(data.error);
                    });
                  }else{
                    setTimeout(() => {
                      this.functionService.showNotiAlert(data.message).then((data)=>{
                        if(data) {
                          if (data.res === 'yes') {
                            setTimeout(() => {
                              this.getMyArticleList();
                            }, 100);
                          }
                        }                        
                      });
                    });
                  }
                }, error =>{
    
                  setTimeout(() => {
                    this.dismissLoader();
                  }, 100);
                }
              );
            }
            else if (data.res === 'no') {
    
            }
          }
          
        });
      });
    }
  }

  /**
   * 알림
   */
  getNotificationList(){
    this.selectIndexNoti = -1;
    this.showLoader('로딩중...');

    const param ={
      userId : this.authService.userData.user_id, 
      userStatusCode : this.authService.userData.user_status
    };

    this.myService.getNotificationList(param).subscribe(
      data => {
        if(data.resultCode === 'SU'){
          this.myNotificationList = data.resultList;
        } else if(data.resultCode === 'PE') {
          this.functionService.showNotiAlert(data.error);
        } else {
          this.functionService.showErrorAlert2(data.error);
        }

        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }, error => {
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    );
  }

  openPsuhPopup(pushModel, i){
    this.selectIndexNoti = i;
    const pushItemdata = pushModel as MyNotificationListModel;
    console.log(pushItemdata);
    let imageFullPath = '';

    if(pushItemdata.imagePath !== ''){
      imageFullPath = this.apiConfig.apiObject.pcWeb+pushItemdata.imagePath;
    }

    let notiAlert:any;

    //허위타임신고알림 수정건의 경우 신고시간 및 수정시간 알려주고 랜딩안함.
    if(pushItemdata.pushMessageType === 'clearaccuse') {
      notiAlert = this.functionService.showPushPopupNoLanding(pushItemdata.pushTitle, pushItemdata.pushContent, imageFullPath).then((data)=>{
        this.pushPopOnWillDismiss(data, pushItemdata);
      });
    } else if(pushItemdata.pushMessageType === 'join' || pushItemdata.pushMessageType === 'trans') {
      notiAlert = this.functionService.showPushPopupWithTime(pushItemdata.pushTitle, pushItemdata.pushContent, imageFullPath, pushItemdata.dtSend).then((data)=>{
        this.pushPopOnWillDismiss(data, pushItemdata);
      });
    } else {
      notiAlert = this.functionService.showPushPopup(pushItemdata.pushTitle, pushItemdata.pushContent, imageFullPath).then((data) => {
        this.pushPopOnWillDismiss(data, pushItemdata);
      });  
    }
  }

  pushPopOnWillDismiss(data, pushItemdata) {
    if (data.res === 'yes') {
      this.showLoader('잠시만 기다려주세요');

      if(pushItemdata.pushMessageType === 'landing') {
        //landing page
        this.landingPagePush(pushItemdata);
      }else if(pushItemdata.pushMessageType === 'trans' || pushItemdata.pushMessageType === 'join'){    // 체크
        //조인플라자 문자문의하기
        this.joinPush(pushItemdata);
      }else if(pushItemdata.pushMessageType === 'tour' || pushItemdata.pushMessageType === 'tourMain'){ 
        //투어 상세페이지
        this.tourPush(pushItemdata);
      }else if(pushItemdata.pushMessageType === 'accuse') {  
        //신고글
        this.myArticlePush(pushItemdata);
      }else{
        //기타 이벤트
        this.eventPush(pushItemdata);   // 체크
      }
    }else{

    }
  }

  landingPagePush(pushReceiveData){
    if('' !== pushReceiveData.landingPage && 'undefined' !== typeof pushReceiveData.landingPage) {
      const landingPageTmp = pushReceiveData.landingPage.split(', ');
      let target;
      let golfId;
      let state;
      let teeupDate;

      for (let i = 0; i < landingPageTmp.length; i++) {
        const landingPageTmp2 = landingPageTmp[i].split('=');

        if ('target' === landingPageTmp2[0]) {
          target = landingPageTmp2[1];
        }
        if ('golfId' === landingPageTmp2[0]) {
          golfId = landingPageTmp2[1];
        }
        if ('state' === landingPageTmp2[0]) {
          state = landingPageTmp2[1];
        }
        if ('teeupDate' === landingPageTmp2[0]) {
          teeupDate = landingPageTmp2[1];
        }
      }

      if(target === 'booking'){
        const dateListInfo = this.commonServie.makeDateArrayForSearch('booking');
        const dateListForSearch = dateListInfo.dateArray;

        let currentSlideIdx = 0;

        for(let i = 0 ; i < dateListForSearch.length ; i++){
          for(let j = 0 ; j < dateListForSearch[i].length ; j++){
            if(teeupDate === dateListForSearch[i][j].formattedDate){
              currentSlideIdx = i;
              break;
            }
          }
        }

        const tmpDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
        const curDate = new Date(tmpDate);

        if(curDate.getTime() >= new Date(teeupDate).getTime()){
          this.functionService.showNotiAlert('해당 게시글은 진행종료되어 상세페이지 확인이 불가능합니다.');          
        }else {
          this.navCtrl.navigateForward(['/booking', {
            golfId: golfId
            , golfName: ''
            , callMenu: 'main'
            , type: 'select'
            , currentDate: teeupDate
            , currentSlideIdx: currentSlideIdx
          }]);
        }

        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    }
  }

  joinPush(pushReceiveData){
    //해당 조인, 양도 마감 여부 체크
    const param = {
      userId: this.authService.userData.user_id
      , checkType: 'joinDetail'
      , checkSeq: pushReceiveData.pushTargetSeq
    };

    this.myService.getNotificationDetailAccessCheck(param).subscribe(
      data => {
        if(data.resultCode === 'SU'){
          this.navCtrl.navigateForward('/joindetail/detail/my/'+pushReceiveData.pushTargetSeq);
        }else if(data.resultCode === 'SN'){
          this.functionService.showNotiAlert(data.message);
        }else if(data.resultCode === 'PE'){
          this.functionService.showNotiAlert(data.error);
        }else{
          this.functionService.showErrorAlert2(data.error);
        }

        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }, error => {
        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    );
  }

  tourPush(pushReceiveData){
    //console.log(pushReceiveData);
    if(pushReceiveData.pushMessageType === 'tour'){
      //해당 상품 마감 여부 체크=
      const param = {
        checkType: 'tourDetail'
        , checkSeq: pushReceiveData.pushTargetSeq
      };

      this.myService.getNotificationDetailAccessCheck(param).subscribe(
        data => {
          if(data.resultCode === 'SU'){
            this.navCtrl.navigateForward(['/tourdetail', {parameter : pushReceiveData.pushTargetSeq}]);
          }else if(data.resultCode === 'SN'){
            this.functionService.showNotiAlert(data.message);
          }else if(data.resultCode === 'PE'){
            this.functionService.showNotiAlert(data.error);
          }else{
            this.functionService.showErrorAlert2(data.error);
          }

          setTimeout(() => {
            this.dismissLoader();
          }, 100);
        }, error => {
          setTimeout(() => {
            this.dismissLoader();
          }, 100);
        }
      );
    }

    if(pushReceiveData.pushMessageType === 'tourMain'){
      if('' !== pushReceiveData.landingPage && 'undefined' !== typeof pushReceiveData.landingPage) {
        const landingPageTmp = pushReceiveData.landingPage.split(', ');
        let target;
        let state;

        for (let i = 0; i < landingPageTmp.length; i++) {
          const landingPageTmp2 = landingPageTmp[i].split('=');

          if ('target' === landingPageTmp2[0]) {
            target = landingPageTmp2[1];
          }
          if ('state' === landingPageTmp2[0]) {
            state = landingPageTmp2[1];
          }
        }

        this.navCtrl.navigateForward(['/tour', {state: state}]);

        setTimeout(() => {
          this.dismissLoader();
        }, 100);
      }
    }
  }

  myArticlePush(pushReceiveData){
    const param = {
      userId: this.authService.userData.user_id
      , checkType: 'myArticleDetail'
      , checkSeq: pushReceiveData.pushTargetSeq
    };

    this.myService.getNotificationDetailAccessCheck(param).subscribe(
      data => {
        if(data.resultCode === 'SU'){
          const NavigationExtras: NavigationExtras = {
            queryParams: {
              accuse: 'true',
              pushSeq: pushReceiveData.pushSeq
            }
          };
          this.navCtrl.navigateForward(['/joindetail/edit/my/' + pushReceiveData.pushTargetSeq], NavigationExtras);
        } else if(data.resultCode === 'SN'){
          this.functionService.showNotiAlert(data.message);
        } else if(data.resultCode === 'PE'){
          this.functionService.showNotiAlert(data.error);
        } else{
          this.functionService.showErrorAlert2(data.error);
        }

        this.dismissLoader();        
      }, error => {
        this.dismissLoader();        
      }
    );
  }

  eventPush(pushReceiveData){
    this.navCtrl.navigateForward('eventdetail/'+pushReceiveData.pushTargetSeq);

    setTimeout(() => {
      this.dismissLoader();
    }, 100);
  }

  /*******************************************
   * FUNCTION
   *******************************************/
  /**
   * 상단 sub menu 변경
   * @param subMenuId
   */
  changSubHeader(subMenuId){
    this.content.scrollToTop(100);
    this.currentSubMenu = subMenuId;
    this.init();
    this.listTabType = 'ing';

    switch(this.currentSubMenu){
      case 'booking':{
        this.getMyBookingReservationList();
        break;
      }
      case 'tour':{
        this.getMyTourReservationList('tour');
        break;
      }
      case 'tourappl':{
        this.getMyTourReservationList('tourappl');
        break;
      }
      case 'article':{
        this.getMyArticleList();
        break;
      }
      case 'notification':{
        this.getNotificationList();
        break;
      }
    }

    // this.content.resize();
    this.app.tick();    
  }

  /**
   * 전체 체크 및 해제
   */
  changeAllCheck(){
    if(this.currentSubMenu === 'article'){
      for(let i = 0 ; i < this.myArticleList.length ; i++){
        if(this.myArticleList[i].status_code === '01'){
          this.myArticleList[i].checked = !this.allCheck;
        }
      }
    }
  }

  removeHTMLInfo(value)
  {  
      if (value)
      return value.replace(/<\/?[^>]+>/gi, "");
  }

  /**
   * 내글 ON/OFF 토글
   */
  updateArticleShowStatus() {
    let gubun = "";
    if(this.toggle.checked === true) gubun = "off";
    else gubun = "on";
    
    this.functionService.showConfirmAlert('내글 전체 ' + gubun + ' 하시겠습니까?').then((data)=>{
      if(data) {
        if (data.res === 'yes') {
          const params = {
            pJmArticleShowYn : (gubun === "on")?"0":"1"
            , userId: this.authService.userData.user_id
          };

          this.myService.updateMyArticleViewStatus(params).subscribe(
            data => {
              if(typeof data.error !== 'undefined'){
                this.functionService.showErrorAlert(data.error);          
              }else{
                //리스트재조회.
                if(data.resultCode === 'succ'){
                  this.functionService.showAlert(data.message);
                }

                if(gubun === "on") {
                  this.authService.userData.jmArticleShowYn = 1;
                  this.toggle.checked = true;
                } else {
                  this.authService.userData.jmArticleShowYn = 0;
                  this.toggle.checked = false;
                }
                
                this.getMyArticleList();                
              }
              setTimeout(() => {
                this.dismissLoader();
              }, 100);
            }, error => {
              setTimeout(() => {
                this.dismissLoader();
              }, 100);
            }
          );
        }
        else if (data.res === 'no') {
          if(gubun === "off") this.authService.userData.jmArticleShowYn = 1;
          else this.authService.userData.jmArticleShowYn = 0;          
        }
      }
    });
  }


  // 쇼로딩
  async showLoader(message) {
    // 모달
    try {
      const el = await this.loadingCtrl.getTop();
      if(el) {
        el.dismiss();        
      }
    } catch(error){}

    this.loaderActive = true;
    return await this.loadingCtrl.create({
      message: message, 
      spinner: 'crescent',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loaderActive) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  // 디스미스로딩
  async dismissLoader() {
    if(this.loaderActive) {
      this.loaderActive = false;
      return await this.loadingCtrl.dismiss().then(()=> console.log('dismissed')); 
    }
  } 
}
